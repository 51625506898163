import { React, serviceClient, cache, dtos, observer, shared, observable, ui, _, Link, Observer, moment, selectFilter, splitIntoWords, makeObservable } from "../common";
import ValueEditor from "../flows/value-editor";
import AccountHeader from "../account-header";
import AutoFocus from "../autofocus";
import { KeyboardEvent } from "react";
import PageTitle from "../page-title";
import NewCustomer from "./new";
import TagsEditor from "../tags-editor";
import ImportWindow from "../import-window";
import AutoComplete from "../autocomplete";

interface BulkUpdateCustomerRow {
    id: string;
    field: dtos.CustomerDataField,
    update: boolean
    value: dtos.Value
};

interface Props {
    parentCustomerId?: string;
}


@observer
export default class CustomersIndex extends React.Component<Props> {
    @observable customers: Array<dtos.CustomerInfo> = [];
    @observable pagination: any = { position: "both" };
    @observable filter: any = {};
    @observable sort: any = {};
    @observable customerFields: Array<dtos.CustomerDataField> = [];
    @observable loading = true;
    @observable pageSize = 20;
    @observable selectedCustomerIds: Array<string> = [];
    @observable tags: Array<dtos.Tag> = [];
    @observable allTags: Array<dtos.Tag> = [];
    @observable showNewCustomer = false;
    @observable assignParentId = "";
    @observable assignToTagId = "";
    @observable showImport = false;
    @observable showBulkUpdate = false;
    @observable bulkUpdateRows: Array<BulkUpdateCustomerRow> = [];

    constructor(props) {
        super(props);
        makeObservable(this);
        this.filter.name = localStorage.getItem("CustomerIndex.filter.name");
        this.filter.customerId = localStorage.getItem("CustomerIndex.filter.customerId");
        this.filter.shallowParent = localStorage.getItem("CustomerIndex.filter.shallowParent") == "true";
        this.pageSize = parseInt(localStorage.getItem("CustomerIndex.pagination.pageSize") || "20");
        var tagJson = localStorage.getItem("CustomerIndex.filter.tags");
        this.tags = tagJson ? JSON.parse(tagJson) : [];
    }

    async componentDidMount() {
        let systemSettings = await cache.getSystemSettings(); // serviceClient.get(new dtos.GetSystemSettings({ accountId: shared.accountId }));
        this.customerFields = systemSettings.customerFields;
        this.allTags = systemSettings.tags;
        this.refresh(1);
    }

    async refresh(page = this.pagination.page || 1, sort = this.sort) {
        this.loading = true;
        let request = new dtos.ListCustomers({
            accountIds: [shared.accountId],
            countPerPage: this.pageSize,
            page: page - 1,
            sortField: sort.field,
            sortOrder: sort.order,
            shallowParent: this.props.parentCustomerId || this.filter.shallowParent ? true : false,
            nameFilter: this.filter.name || null,
            tagIds: this.tags.length > 0 ? this.tags.map(t => t.id) : null,
            parentCustomerIds: this.props.parentCustomerId ? [this.props.parentCustomerId] : (this.filter.customerId ? [this.filter.customerId] : null),
            simplifiedPaging: true,
        });
        let response = await serviceClient.get(request);
        let currentlySelected = this.customers.filter(ep => this.selectedCustomerIds.indexOf(ep.id) >= 0);
        this.customers = [...currentlySelected, ...response.items.filter(c => this.selectedCustomerIds.indexOf(c.id) < 0)];
        let pager = { ...this.pagination };
        pager.current = page;
        pager.total = page * this.pageSize + (response.hasMorePages ? 1 : 0);
        pager.pageSize = this.pageSize;
        pager.showSizeChanger = true;
        pager.pageSizeOptions = ["10", "20", "50", "100", "250"];
        pager.onShowSizeChange = (c, v) => this.pageSize = v;

        this.pagination = pager;
        this.loading = false;

        localStorage.setItem("CustomerIndex.filter.name", this.filter.name || "");
        localStorage.setItem("CustomerIndex.filter.shallowParent", this.filter.shallowParent ? "true" : "false");
        localStorage.setItem("CustomerIndex.filter.customerId", this.filter.customerId || "");
        localStorage.setItem("CustomerIndex.pagination.pageSize", this.pageSize.toString());
        localStorage.setItem("CustomerIndex.filter.tags", JSON.stringify(this.tags));
    }

    getCustomerDataText(cust: dtos.CustomerInfo, f: dtos.CustomerDataField) {
        var val = cust.data[f.name] as dtos.Value;
        if (!val) return <span />;
        return <ValueEditor readOnly field={f} value={val} accountId={shared.accountId} valueType={f.type} />;
    }

    checkFilterSubmit(ev: KeyboardEvent) {
        if (ev.which == 13) {
            this.refresh();
        }
    }

    async assignParent() {
        this.loading = true;
        for (let id of this.selectedCustomerIds) {
            let updated = await serviceClient.patch(new dtos.PatchCustomer({
                customerId: id,
                parentCustomerId: this.assignParentId
            }));
            Object.assign(_.find(this.customers, ep => ep.id == id), updated);

        }
        this.refresh();
    }

    async assignToTag() {
        this.loading = true;
        for (let id of this.selectedCustomerIds) {
            let cust = _.find(this.customers, e => e.id == id);
            let tagIds = cust.tags.map(t => t.id);
            if (tagIds.indexOf(this.assignToTagId) < 0) {
                tagIds.push(this.assignToTagId);
            }
            let updated = await serviceClient.patch(new dtos.PatchCustomer({
                customerId: id,
                tagIds: tagIds
            }));
            Object.assign(_.find(this.customers, ep => ep.id == id), updated);

        }
        this.selectedCustomerIds = [];
        this.refresh();
    }

    async removeFromTag() {
        this.loading = true;
        for (let id of this.selectedCustomerIds) {
            let cust = _.find(this.customers, e => e.id == id);
            let tagIds = cust.tags.map(t => t.id);
            let idx = tagIds.indexOf(this.assignToTagId);
            if (idx >= 0) {
                tagIds.splice(idx, 1);
                let updated = await serviceClient.patch(new dtos.PatchCustomer({
                    customerId: id,
                    tagIds: tagIds
                }));
                Object.assign(_.find(this.customers, ep => ep.id == id), updated);
            }
        }
        this.selectedCustomerIds = [];
        this.refresh();
    }

    async deleteCustomer(cust: dtos.CustomerInfo) {
        await serviceClient.delete(new dtos.DeleteCustomer({
            customerId: cust.id
        }));
        let idx = this.customers.indexOf(cust);
        this.customers.splice(idx, 1);
        this.forceUpdate();
        ui.message.info(cust.name + " deleted");
    }

    async deleteCustomers() {
        this.loading = true;
        for (let custId of this.selectedCustomerIds) {
            await serviceClient.delete(new dtos.DeleteCustomer({ customerId: custId }));
        }
        this.selectedCustomerIds = [];
        this.refresh();
        this.loading = false;
    }

    onCustomerCreated(cust: dtos.CustomerInfo) {
        window.location.hash = `/customers/${cust.id}`;
        this.showNewCustomer = false;
    }

    async tryShowBulkUpdate() {
        let ss = await cache.getSystemSettings();
        for (let param of ss.customerFields) {
            this.bulkUpdateRows.push({
                id: param.id,
                field: param,
                update: false,
                value: new dtos.Value()
            });
        }

        if (this.bulkUpdateRows.length == 0) {
            ui.message.error("This flow does not have any settings that can be edited.");
            return;
        }

        this.showBulkUpdate = true;
    }

    async bulkUpdate() {
        try {
            this.loading = true;
            let data = new dtos.Struct();
            var didUpdate = false;
            for (let row of this.bulkUpdateRows) {
                if (row.update) {
                    data[row.field.name] = row.value;
                    didUpdate = true;
                }
            }

            if (!didUpdate) {
                ui.message.error("Must select at least one field to be updated");
                return;
            }

            for (let id of this.selectedCustomerIds) {
                await serviceClient.patch(new dtos.PatchCustomer({
                    customerId: id,
                    data: data
                }))
            }

            ui.message.success("Endpoints updated successfully");
            this.showBulkUpdate = false;
        } finally {
            this.loading = false;
        }
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.selectedCustomerIds,
            onChange: keys => this.selectedCustomerIds = keys
        };

        function getValue(rec: dtos.CustomerInfo, f: dtos.CustomerDataField) {
            rec.data[f.name] = rec.data[f.name] || new dtos.Value();
            return rec.data[f.name];
        }

        const embedded = this.props.parentCustomerId;

        return (
            <div>
                {!embedded && <>
                    <PageTitle title="Customers" />
                    <AccountHeader title="Customers" icon="fa fa-users" />
                    <ui.Button.Group style={{ marginBottom: 16 }}>
                        <ui.Button type="primary" onClick={() => this.showNewCustomer = true}>New Customer</ui.Button>
                        {this.selectedCustomerIds.length > 0 && <ui.Popconfirm title="Are you sure you want to delete the selected customers?" onConfirm={() => this.deleteCustomers()}><ui.Button danger>Delete Customers</ui.Button></ui.Popconfirm>}
                    </ui.Button.Group>
                    <ui.Button.Group style={{ marginLeft: 16 }}>
                        <ui.Button type="default" onClick={() => this.showImport = true}><i className="fa fa-upload" />&nbsp; Import</ui.Button>
                    </ui.Button.Group>
                </>}
                <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    {this.props.parentCustomerId && <ui.Form.Item>
                        <ui.Button type="primary" onClick={() => this.showNewCustomer = true}>New Customer</ui.Button>
                    </ui.Form.Item>}
                    <ui.Form.Item>
                        <ui.Input onKeyPress={ev => this.checkFilterSubmit(ev)} placeholder="Filter by name" value={this.filter.name || ""} onChange={ev => this.filter.name = ev.target.value} />
                    </ui.Form.Item>
                    {!this.props.parentCustomerId && <>
                        <ui.Form.Item>
                            <AutoComplete includeNotAssigned type={dtos.ValueTypes.Customer} value={this.filter.customerId} onChanged={v => { this.filter.customerId = v; this.refresh(); }} placeholder="Filter by parent customer" />
                        </ui.Form.Item>
                        {this.filter.customerId && this.filter.customerId != "_" && <ui.Form.Item style={{ marginLeft: -8 }}>
                            <ui.Tooltip title="Select this option to show only direct children of the specified customer"><ui.Button type={this.filter.shallowParent ? "primary" : "default"} onClick={() => { this.filter.shallowParent = !this.filter.shallowParent; this.refresh() }}><i className="fa fa-sitemap" /></ui.Button></ui.Tooltip>
                        </ui.Form.Item>}
                    </>}
                    <ui.Form.Item>
                        <TagsEditor placeholder="Filter by tag" tags={this.tags} />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="primary" onClick={() => this.refresh()}>Search</ui.Button>
                            <ui.Button type="default" onClick={() => { this.filter = {}; this.selectedCustomerIds = []; this.tags = []; this.refresh(); }}>Clear</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>
                </ui.Form>
                <ui.Form layout="inline">
                    {this.selectedCustomerIds.length > 0 &&
                        <React.Fragment>
                            <ui.Form.Item>
                                <ui.Input.Group compact>
                                    <AutoComplete type={dtos.ValueTypes.Customer} placeholder="Assign Parent" value={this.assignParentId} onChanged={v => this.assignParentId = v} />
                                    <ui.Popconfirm title="Assign this parent customer to all of the selected customers?" onConfirm={() => this.assignParent()}><ui.Button type="default" disabled={!this.assignParentId}><i className="fa fa-save" /></ui.Button></ui.Popconfirm>
                                </ui.Input.Group>
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Input.Group compact>
                                    <ui.Select showSearch filterOption={selectFilter} style={{ width: 175 }} dropdownMatchSelectWidth={false} placeholder="Assign/Remove Tag" value={this.assignToTagId || undefined} onChange={v => this.assignToTagId = v}>
                                        {this.allTags.map(c => <ui.Select.Option key={c.id} value={c.id}>{c.name}</ui.Select.Option>)}
                                    </ui.Select>
                                    <ui.Button.Group>
                                        <ui.Popconfirm title="Assign this tag to all of the selected customers?" onConfirm={() => this.assignToTag()}><ui.Button title="Assign to tag" type="default" disabled={!this.assignToTagId}><i className="fa fa-save" /></ui.Button></ui.Popconfirm>
                                        <ui.Popconfirm title="Remove this tag from all of the selected customers?" onConfirm={() => this.removeFromTag()}><ui.Button title="Remove from tag" type="default" disabled={!this.assignToTagId}><i className="fa fa-times" /></ui.Button></ui.Popconfirm>
                                    </ui.Button.Group>
                                </ui.Input.Group>
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Button onClick={() => this.tryShowBulkUpdate()}>Bulk Update...</ui.Button>
                            </ui.Form.Item>
                        </React.Fragment>}
                </ui.Form>
                <ui.Table className="hide-pagination-buttons" rowSelection={rowSelection} loading={this.loading} dataSource={this.customers} rowKey="id" pagination={this.pagination} onChange={(p, f, s) => this.refresh(p.current, s)}>
                    <ui.Table.Column dataIndex="name" title="Name" render={(text, rec: dtos.CustomerInfo) => <>
                        <Link to={`/customers/${rec.id}`}>{rec.name}</Link> {rec.isStaging && <ui.Tag color="red">Staging</ui.Tag>}
                    </>} />
                    {!this.props.parentCustomerId &&
                        <ui.Table.Column dataIndex="breadcrumb" title="Parent" render={(text, rec: dtos.CustomerInfo) =>
                            <ui.Breadcrumb>
                                {rec.breadcrumb.map((bc, i) => i < rec.breadcrumb.length - 1 && <ui.Breadcrumb.Item key={bc.id}><Link to={`/customers/${bc.id}`}>{bc.name}</Link></ui.Breadcrumb.Item>)}
                            </ui.Breadcrumb>
                        } />
                    }
                    <ui.Table.Column dataIndex="referenceId" title="Reference ID" />

                    <ui.Table.Column title="Tags" render={(text, rec: dtos.CustomerInfo) =>
                        <div>
                            {rec.tags.map(t => <ui.Tag key={t.id} color={t.color.toLowerCase()}>{t.name}</ui.Tag>)}
                        </div>} />
                    {this.customerFields.filter(f => f.showInSearch).map(f => <ui.Table.Column key={f.name} dataIndex={f.name} title={f.name} render={(text, rec: dtos.CustomerInfo) =>
                        <span>{this.getCustomerDataText(rec, f)}</span>} />)}
                    <ui.Table.Column title="Last Modified" render={(text, rec: dtos.CustomerInfo) => <span>{moment(rec.dateLastModified).format("LLLL")} by {rec.lastModifiedBy}</span>} />
                </ui.Table>

                <ui.Modal width={800} title="Bulk Update Customer" cancelButtonProps={{ disabled: this.loading }} okButtonProps={{ disabled: this.loading }} visible={this.showBulkUpdate} onCancel={() => this.showBulkUpdate = false} okText="Update" onOk={() => this.bulkUpdate()}>
                    <div style={{ maxHeight: 600, overflowY: "auto" }}>
                        <ui.Table dataSource={this.bulkUpdateRows} rowKey="id" showHeader={false} pagination={false}>
                            <ui.Table.Column dataIndex="id" render={(text, rec: BulkUpdateCustomerRow) => <ui.Checkbox checked={rec.update} onChange={ev => { rec.update = ev.target.checked; this.forceUpdate() }} />} />
                            <ui.Table.Column render={(text, rec: BulkUpdateCustomerRow) => <span>{splitIntoWords(rec.field.name)}</span>} />
                            <ui.Table.Column render={(text, rec: BulkUpdateCustomerRow) => <ValueEditor accountId={shared.accountId} value={rec.value} onFieldChanged={() => this.forceUpdate()} key={rec.id} field={rec.field} valueType={rec.field.type} />} />
                        </ui.Table>
                    </div>
                </ui.Modal>

                <NewCustomer defaultParentCustomerId={this.props.parentCustomerId || this.filter.customerId} visible={this.showNewCustomer} onClosed={() => this.showNewCustomer = false} onCreated={cust => this.onCustomerCreated(cust)} />
                <ImportWindow onImported={() => this.refresh()} visible={this.showImport} onClosed={() => this.showImport = false} accountId={shared.accountId} />
            </div>
        );
    }
}