import { React, serviceClient, dtos, observer, observable, ui, _, Link, Observer, moment, splitIntoWords, selectFilter, makeObservable } from "../common";

interface Props {
    accountId: string;
}

@observer
export default class DialingPermissions extends React.Component<Props> {
    @observable permissions: dtos.DialingPermissionInfo[] = [];
    @observable loading = false;
    @observable enabledFilter = false;
    @observable nameFilter = "";

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    async componentDidMount() {
        this.loading = true;
        let response = await serviceClient.get(new dtos.GetDialingPermissions({
            accountId: this.props.accountId
        }));
        this.permissions = response.permissions;
        this.loading = false;
    }

    clearSearch() {
        this.enabledFilter = false;
        this.nameFilter = "";
        this.forceUpdate();
    }

    filterPermission(p: dtos.DialingPermissionInfo) {
        if (this.nameFilter) {
            if (p.name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) < 0) return false;
        }

        if (this.enabledFilter) {
            if (!p.lowRiskNumbersEnabled) return false;
        }

        return true;
    }

    async setEnabled(rec: dtos.DialingPermissionInfo, lowRisk: boolean, highRisk: boolean) {
        await serviceClient.patch(new dtos.PatchDialingPermissions({
            accountId: this.props.accountId,
            isoCode: rec.isoCode,
            lowRiskNumbersEnabled: lowRisk,
            highRiskSpecialNumbersEnabled: highRisk
        }));
        rec.lowRiskNumbersEnabled = lowRisk;
        rec.highRiskSpecialNumbersEnabled = highRisk;
        this.forceUpdate();
    }

    render() {
        return (
            <div>
                <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    <ui.Form.Item>
                        <ui.Input value={this.nameFilter} onChange={ev => this.nameFilter = ev.target.value} placeholder="Filter by name" />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Checkbox checked={this.enabledFilter} onChange={ev => this.enabledFilter = ev.target.checked}><span>Show Only Enabled</span></ui.Checkbox>
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="default" onClick={() => this.clearSearch()}>Clear</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>
                </ui.Form>
                <ui.Table loading={this.loading} dataSource={this.permissions.filter(v => this.filterPermission(v))} rowKey="isoCode" pagination={{ pageSize: 100000 }}>
                    <ui.Table.Column width={300} dataIndex="name" title="Name" render={(text, rec: dtos.DialingPermissionInfo) =>
                        <span style={{ fontWeight: rec.lowRiskNumbersEnabled || rec.highRiskSpecialNumbersEnabled ? "bold" : "normal" }}>{rec.name}</span>} />
                    <ui.Table.Column width={250} dataIndex="lowRiskNumbersEnabled" title="Low Risk Enabled" render={(text, rec: dtos.DialingPermissionInfo) =>
                        <span><ui.Checkbox checked={rec.lowRiskNumbersEnabled} /></span>} />
                    <ui.Table.Column width={250} dataIndex="highRiskSpecialNumbersEnabled" title="High Risk Enabled" render={(text, rec: dtos.DialingPermissionInfo) =>
                        <span><ui.Checkbox checked={rec.highRiskSpecialNumbersEnabled} /></span>} />
                    
                    <ui.Table.Column title="" render={(text, rec: dtos.DialingPermissionInfo) =>
                        <span>
                            {(rec.lowRiskNumbersEnabled || rec.highRiskSpecialNumbersEnabled) && <ui.Popconfirm title={"Are you sure you want to disable " + rec.name} onConfirm={() => this.setEnabled(rec, false, false)}><ui.Button danger style={{ marginRight: 4 }}>Disable</ui.Button></ui.Popconfirm>}
                            {!rec.lowRiskNumbersEnabled && <ui.Popconfirm title={"Are you sure you want to enable " + rec.name} onConfirm={() => this.setEnabled(rec, true, rec.highRiskSpecialNumbersEnabled)}><ui.Button type="primary" style={{ marginRight: 4 }}>Enable Low Risk</ui.Button></ui.Popconfirm>}
                            {!rec.highRiskSpecialNumbersEnabled && <ui.Popconfirm title={"Are you sure you want to enable " + rec.name + " for high risk?"} onConfirm={() => this.setEnabled(rec, rec.lowRiskNumbersEnabled, true)}><ui.Button danger>Enable High Risk</ui.Button></ui.Popconfirm>}

                        </span>} />
                </ui.Table>
            </div>
        );
    }
}