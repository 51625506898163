import { React, dtos, serviceClient, ui, observer, observable, moment, _, Link, uuid, selectFilter, splitIntoWords, shared, makeObservable, cache } from "../common"
import AutoComplete from "../autocomplete";

interface Props {
    accountId: string;
    customerId?: string;
}

@observer
export default class Notifications extends React.Component<Props> {
    @observable notifications: Array<dtos.NotificationInfo> = [];
    @observable accountId: string = "";
    @observable pagination: any = {};
    @observable filter: any = {};
    @observable loading = false;
    @observable pageSize = 10;
    @observable startDate = moment().startOf("day");
    @observable endDate = moment().endOf("day");
    @observable sort: any = {};
    @observable settings: dtos.SystemSettingsInfo;
    @observable showNotification = false;
    @observable notification: dtos.NotificationInfo = null;

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    componentDidMount() {
        this.accountId = this.props.accountId;
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.accountId != prevProps.accountId) {
            this.accountId = this.props.accountId;
            this.init();
        }
    }

    async init() {
        this.filter.customerId = this.props.customerId || localStorage.getItem("NotificationIndex.filter.customerId") || "";
        this.filter.subject = localStorage.getItem("NotificationIndex.filter.subject") || "";
        this.filter.from = localStorage.getItem("NotificationIndex.filter.from") || "";
        this.filter.to = localStorage.getItem("NotificationIndex.filter.to") || "";
        this.filter.type = localStorage.getItem("NotificationIndex.filter.type") || "";
        this.pageSize = parseInt(localStorage.getItem("NotificationIndex.pagination.pageSize") || "20");

        var startDate = localStorage.getItem("NotificationIndex.filter.startDate");
        if (startDate) {
            this.startDate = moment(startDate);
        }

        var endDate = localStorage.getItem("NotificationIndex.filter.endDate");
        if (endDate) {
            this.endDate = moment(endDate);
        }

        this.settings = await cache.getSystemSettings();
        this.refresh();
    }

    clearSearch() {
        this.startDate = moment().startOf("day");
        this.endDate = moment().endOf("day");
        this.filter = {customerId: this.props.customerId};
        this.refresh();
    }

    async refresh(page = this.pagination.page || 1, sort = this.sort) {
        this.loading = true;
        let request = new dtos.ListNotifications({
            accountIds: [this.accountId],
            countPerPage: this.pageSize,
            page: page - 1,
            customerIds: this.filter.customerId ? [this.filter.customerId] : null,
            fromFilter: this.filter.from,
            toFilter: this.filter.to,
            subjectFilter: this.filter.subject,
            sortField: sort.field,
            sortOrder: sort.order,
            startDate: this.startDate.toISOString(),
            endDate: this.endDate.toISOString(),
            type: this.filter.type,
            simplifiedPaging: true
        });

        let response = await serviceClient.get(request);
        this.notifications = response.items;

        let pager = { ...this.pagination };
        pager.current = page;
        pager.total = page * this.pageSize + (response.hasMorePages ? 1 : 0);
        pager.pageSize = request.countPerPage;
        pager.showSizeChanger = true;
        pager.pageSizeOptions = ["10", "20", "50", "100", "250"];
        pager.onShowSizeChange = (c, v) => this.pageSize = v;
        this.pagination = pager;
        this.loading = false;

        localStorage.setItem("NotificationIndex.filter.customerId", this.filter.customerId || "");
        localStorage.setItem("NotificationIndex.filter.endpointId", this.filter.endpointId || "");
        localStorage.setItem("NotificationIndex.filter.from", this.filter.from || "");
        localStorage.setItem("NotificationIndex.filter.to", this.filter.to || "");
        localStorage.setItem("NotificationIndex.filter.subject", this.filter.subject || "");
        localStorage.setItem("NotificationIndex.filter.type", this.filter.type || "");
        localStorage.setItem("NotificationIndex.filter.startDate", this.startDate.toISOString());
        localStorage.setItem("NotificationIndex.filter.endDate", this.endDate.toISOString());
        localStorage.setItem("NotificationIndex.pagination.pageSize", this.pageSize.toString());
    }

    setDateRange(range) {
        this.startDate = range[0];
        this.endDate = range[1];
    }

    checkFilterSubmit(ev: React.KeyboardEvent) {
        if (ev.which == 13) {
            this.refresh();
        }
    }

    showTheNotification(n: dtos.NotificationInfo) {
        this.notification = n;
        this.showNotification = true;
    }

    async resend(n: dtos.NotificationInfo) {
        let updated = await serviceClient.post(new dtos.ResendNotification({notificationId: n.id}));
        ui.message.success("Resent successfully");
        n.error = updated.error;
        n.errorMessage = updated.errorMessage;
        this.forceUpdate();
    }

    render() {
        const noFileAccess = shared.roles.indexOf("NoVoicemailFileAccess") >= 0;

        return (
            <div>
                <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    <ui.Form.Item>
                        <ui.DatePicker.RangePicker value={[this.startDate, this.endDate]} onChange={ev => this.setDateRange(ev)} />
                    </ui.Form.Item>

                    {!this.props.customerId && <ui.Form.Item>
                        <AutoComplete type={dtos.ValueTypes.Customer} value={this.filter.customerId} includeNotAssigned onSearch={() => this.refresh()} onChanged={v => this.filter.customerId = v} placeholder="Filter by customer" />
                    </ui.Form.Item>}

                    <ui.Form.Item>
                        <ui.Input placeholder="Filter by From" onKeyPress={ev => this.checkFilterSubmit(ev)} value={this.filter.from} onChange={ev => this.filter.from = ev.target.value} />
                    </ui.Form.Item>

                    <ui.Form.Item>
                        <ui.Input placeholder="Filter by To" onKeyPress={ev => this.checkFilterSubmit(ev)} value={this.filter.to} onChange={ev => this.filter.to = ev.target.value} />
                    </ui.Form.Item>

                    <ui.Form.Item>
                        <ui.Input placeholder="Filter by Subject" onKeyPress={ev => this.checkFilterSubmit(ev)} value={this.filter.subject} onChange={ev => this.filter.subject = ev.target.value} />
                    </ui.Form.Item>

                    <ui.Form.Item>
                        <ui.Select onInputKeyDown={ev => this.checkFilterSubmit(ev)} value={this.filter.type || ""} onChange={v => this.filter.type = v} dropdownMatchSelectWidth={false}>
                            <ui.Select.Option value="">(Filter by type)</ui.Select.Option>
                            {_.sortBy(Object.keys(dtos.NotificationTypes)).map(t => <ui.Select.Option key={t} value={t}>{splitIntoWords(t)}</ui.Select.Option>)}
                        </ui.Select>
                    </ui.Form.Item>

                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="primary" onClick={() => this.refresh()}>Search</ui.Button>
                            <ui.Button type="default" onClick={() => this.clearSearch()}>Clear</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>

                </ui.Form>
                {this.filter.searchArchive == true && <p><small>You are searching the <strong>Archive</strong>. To turn this off uncheck the box at the bottom</small></p>}
                <ui.Table className="hide-pagination-buttons" loading={this.loading} dataSource={this.notifications} rowKey="id" pagination={this.pagination} onChange={(p, f, s) => this.refresh(p.current, s)}>
                    <ui.Table.Column dataIndex="dateCreated" title="Date" render={(text, rec: dtos.NotificationInfo) => <span><a onClick={() => this.showTheNotification(rec)}>{moment(rec.dateCreated).format("LLLL")}</a></span>} />
                    {shared.canViewCustomers() && <ui.Table.Column title="Customer" dataIndex="customerName" render={(text, rec: dtos.NotificationInfo) => rec.customerId && <ui.Breadcrumb>
                        {rec.customerBreadcrumb.map((bc, i) => <ui.Breadcrumb.Item key={bc.id}><Link to={`/customers/${bc.id}`}>{bc.name}</Link></ui.Breadcrumb.Item>)}
                    </ui.Breadcrumb>} />}
                    <ui.Table.Column title="Type" dataIndex="type" />
                    <ui.Table.Column title="From" dataIndex="from" render={(text, rec: dtos.NotificationInfo) => rec.from} />
                    <ui.Table.Column title="To" dataIndex="to" render={(text, rec: dtos.NotificationInfo) => rec.to} />
                    <ui.Table.Column title="Recipients" dataIndex="recipients" render={(text, rec: dtos.NotificationInfo) => rec.recipients.map(r => r.name ? r.name + " (" + r.address + ")" : r.address).join(", ")} />
                    <ui.Table.Column title="Subject" dataIndex="subject" />
                    <ui.Table.Column title="Error" dataIndex="error" render={(text, rec: dtos.NotificationInfo) => rec.error && rec.errorMessage && <ui.Tooltip title={rec.errorMessage}><span style={{ color: "red", maxWidth: 150, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", display: "inline-block"}}>{rec.errorMessage}</span></ui.Tooltip>} />
                    <ui.Table.Column title="Resend" render={(text, rec: dtos.NotificationInfo) => <ui.Popconfirm title="Resend this notification?" onConfirm={() => this.resend(rec)}><ui.Button size="small"><i className="fa fa-envelope" /></ui.Button></ui.Popconfirm>} />
                </ui.Table>
                {this.settings && <p><small>Recordings will be deleted after {this.settings.recordingRetentionDays} days</small></p>}
                {this.showNotification && <ui.Modal width="75%" title={this.notification.subject} okButtonProps={{ hidden: true }} cancelText="Close" onCancel={() => this.showNotification = false} visible={this.showNotification}>
                    <pre>
                        {this.notification.body}
                    </pre>
                    {!noFileAccess && this.notification.attachments.length > 0 && <p>
                        {this.notification.attachments.map(a => <a key={a.id} href={a.uri} target="_blank" style={{ marginRight: 15 }}>{a.fileName}</a>)}
                    </p>}
                    {this.settings && <p><small>NOTE: old recording links will not work - recordings are deleted after {this.settings.recordingRetentionDays} days</small></p>}
                </ui.Modal>}
                
            </div>
        );
    }
}