import { React, dtos, serviceClient, ui, observer, observable, moment, _, Link, uuid, selectFilter, shared, makeObservable } from "../common"
import AutoComplete from "../autocomplete";

interface Props {
    accountId: string;
}

@observer
export default class SystemLog extends React.Component<Props> {
    @observable entries: Array<dtos.LogEntryInfo> = [];
    @observable accountId: string = "";
    @observable pagination: any = { };
    @observable filter: any = {};
    @observable loading = false;
    @observable pageSize = 10;
    @observable startDate = moment().startOf("day");
    @observable endDate = moment().endOf("day");
    @observable sort: any = {};

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        this.accountId = this.props.accountId;
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.accountId != prevProps.accountId) {
            this.accountId = this.props.accountId;
            this.init();
        }
    }

    async init() {
        this.filter.customerId = localStorage.getItem("SystemLog.filter.customerId") || "";
        this.filter.description = localStorage.getItem("SystemLog.filter.description") || "";
        this.pageSize = parseInt(localStorage.getItem("SystemLog.pagination.pageSize") || "20");

        var startDate = localStorage.getItem("SystemLog.filter.startDate");
        if (startDate) {
            this.startDate = moment(startDate);
        }

        var endDate = localStorage.getItem("SystemLog.filter.endDate");
        if (endDate) {
            this.endDate = moment(endDate);
        }
        this.refresh();
    }

    clearSearch() {
        this.startDate = moment().startOf("day");
        this.endDate = moment().endOf("day");
        this.filter = {};
        this.refresh();
    }

    async refresh(page = this.pagination.page || 1, sort = this.sort) {
        this.loading = true;
        let request = new dtos.ListLogEntries({ 
            accountIds: [this.accountId], 
            countPerPage: this.pageSize, 
            page: page - 1, 
            customerIds: this.filter.customerId ? [this.filter.customerId] : null,
            description: this.filter.description,
            startDate: this.startDate.toISOString(),
            endDate: this.endDate.toISOString(),
            simplifiedPaging: true
        });

        let response = await serviceClient.get(request);
        this.entries = response.items;

        let pager = { ...this.pagination };
        pager.current = page;
        pager.total = page * this.pageSize + (response.hasMorePages ? 1 : 0);
        pager.pageSize = request.countPerPage;
        pager.showSizeChanger = true;
        pager.pageSizeOptions = ["10", "20", "50", "100", "250"];
        pager.onShowSizeChange = (c, v) => this.pageSize = v;
        this.pagination = pager;
        this.loading = false;

        localStorage.setItem("SystemLog.filter.customerId", this.filter.customerId || "");
        localStorage.setItem("SystemLog.filter.description", this.filter.description || "");
        localStorage.setItem("SystemLog.filter.startDate", this.startDate.toISOString());
        localStorage.setItem("SystemLog.filter.endDate", this.endDate.toISOString());
        localStorage.setItem("SystemLog.pagination.pageSize", this.pageSize.toString());
    }
    
    setDateRange(range) {
        this.startDate = range[0];
        this.endDate = range[1];
    }

    checkFilterSubmit(ev: React.KeyboardEvent) {
        if (ev.which == 13) {
            this.refresh();
        }
    }

    render() {
        return (
            <div>
                <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    <ui.Form.Item>
                        <ui.DatePicker.RangePicker value={[this.startDate, this.endDate]} onChange={ev => this.setDateRange(ev)} />
                    </ui.Form.Item>
                    
                    <ui.Form.Item>
                        <AutoComplete type={dtos.ValueTypes.Customer} includeNotAssigned={!shared.isCustomer()} value={this.filter.customerId} onSearch={() => this.refresh()} onChanged={v => this.filter.customerId = v} placeholder="Filter by customer" />
                    </ui.Form.Item>
                    
                    <ui.Form.Item>
                        <ui.Input placeholder="Filter by Description" onKeyPress={ev => this.checkFilterSubmit(ev)} value={this.filter.description} onChange={ev => this.filter.description = ev.target.value} />
                    </ui.Form.Item>

                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="primary" onClick={() => this.refresh()}>Search</ui.Button>
                            <ui.Button type="default" onClick={() => this.clearSearch()}>Clear</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>

                </ui.Form>
                {this.filter.searchArchive == true && <p><small>You are searching the <strong>Archive</strong>. To turn this off uncheck the box at the bottom</small></p>}
                <ui.Table className="hide-pagination-buttons" loading={this.loading} dataSource={this.entries} rowKey="id" pagination={this.pagination} onChange={(p, f, s) => this.refresh(p.current, s)}>
                    <ui.Table.Column dataIndex="dateCreated" title="Date" render={(text, rec: dtos.SessionInfo) => <span>{moment(rec.dateCreated).format("LLLL")}</span>} />
                    <ui.Table.Column title="Customer" dataIndex="customerName" render={(text, rec: dtos.SessionInfo) => rec.customerId && <ui.Breadcrumb>
                        {rec.customerBreadcrumb.map((bc, i) => <ui.Breadcrumb.Item key={bc.id}><Link to={`/customers/${bc.id}`}>{bc.name}</Link></ui.Breadcrumb.Item>)}
                    </ui.Breadcrumb>} />
                    <ui.Table.Column title="User" dataIndex="userName" />
                    <ui.Table.Column title="Description" dataIndex="description" render={(text, rec:dtos.LogEntryInfo) => <div>
                        <div dangerouslySetInnerHTML={{__html: (rec.description as any).replaceAll("\r", "").replaceAll("\n", "<br />").replaceAll("\t", "&nbsp;&nbsp;&nbsp;&nbsp;")}} />
                    </div>} />
                </ui.Table>
            </div>
        );
    }
}