import { React, dtos, ui, serviceClient, shared, observer, observable, uuid, _, splitIntoWords, moment, selectFilter, makeObservable, cache } from "../common";
import ValueEditor from "../flows/value-editor";
import AccountHeader from "../account-header";
import FilesIndex from "./files";
import UsersIndex from "./users";
import Sessions from "./sessions";
import Notifications from "./notifications";
import AutoFocus from "../autofocus";
import PageTitle from "../page-title";
import DialingPermissions from "./dialing-permissions"
import SystemLog from "./log";
import Reports from "../dashboard/reports";
import TrustIndex from "./trust";
import UserSettings from "./user-settings";
import DataTypeEditor from "../flows/data-type-editor";
import { TwilioSipRegions } from "../dtos";

@observer
export default class SystemSettings extends React.Component {
    @observable systemFields: Array<dtos.SystemSettingsField> = [];
    @observable customerFields: Array<dtos.CustomerDataField> = [];
    @observable endpointFields: Array<dtos.EndpointDataField> = [];

    @observable activeTab = shared.getDefaultSettingsTab();
    @observable showNewSystemField = false;
    @observable newSystemFieldName = "";
    @observable newSystemFieldType = dtos.ValueTypes.String;

    @observable showNewCustomerField = false;
    @observable newCustomerFieldName = "";
    @observable newCustomerFieldType = dtos.ValueTypes.String;

    @observable showNewEndpointField = false;
    @observable newEndpointFieldName = "";
    @observable newEndpointFieldType = dtos.ValueTypes.String;
    @observable newEndpointFieldEndpointType = "";

    @observable defaultSipRegion: TwilioSipRegions = null;

    @observable showEditValues = false;
    @observable editValuesField: dtos.DataField = null;
    @observable editValues: Array<string> = [];
    @observable editOverrideSystemTime = false;
    @observable editOverrideSystemDateTime = "";
    @observable editOverrideSystemTimeZoneId = "";
    @observable timezones: Array<dtos.SystemTimeZoneInfo> = [];
    @observable emailAccounts: Array<dtos.EmailAccount> = [];
    @observable testEmailAddress = "";
    @observable tags: Array<dtos.Tag> = [];
    @observable showDuplicateChecker = false;
    @observable duplicates: dtos.FindDuplicateEntry[] = [];
    @observable loadingDuplicates = false;
    @observable systemInfo: dtos.SystemInfo = null;
    @observable globalSettings: dtos.GlobalSettingsInfo = null;
    @observable editRecordingRetentionDays = 0;
    @observable editEnableSipRefer = false;
    @observable editAutoAgentLogoffSeconds = 0;
    @observable addresses: Array<dtos.AddressInfo> = [];

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    setTab(tab: string) {
        this.activeTab = tab;
        localStorage.setItem("Settings.activeTab", this.activeTab);
    }

    componentDidMount() {
        var tab = localStorage.getItem("Settings.activeTab");
        if (tab) {
            this.activeTab = tab;
        }
        this.refreshSystemSettings();
    }

    async refreshSystemSettings() {
        let systemSettings = await serviceClient.get(new dtos.GetSystemSettings({ accountId: shared.accountId }));
        this.timezones = await serviceClient.get(new dtos.ListSystemTimeZones());
        this.systemInfo = await serviceClient.get(new dtos.GetSystemInfo());
        this.systemFields = systemSettings.systemFields;
        this.customerFields = systemSettings.customerFields;
        this.endpointFields = systemSettings.endpointFields;
        this.editOverrideSystemTime = systemSettings.overrideSystemTime;
        this.editOverrideSystemDateTime = systemSettings.overrideSystemDateTime;
        this.editOverrideSystemTimeZoneId = systemSettings.overrideSystemTimeZoneId;
        this.emailAccounts = systemSettings.emailAccounts;
        this.editRecordingRetentionDays = systemSettings.recordingRetentionDays;
        this.editEnableSipRefer = systemSettings.enableSipRefer;
        this.editAutoAgentLogoffSeconds = systemSettings.autoAgentLogoffSeconds;
        this.tags = systemSettings.tags;
        this.defaultSipRegion = systemSettings.defaultSipRegion;
        if (shared.roles.indexOf("SystemAdministrator") >= 0) {
            this.globalSettings = await serviceClient.get(new dtos.GetGlobalSettings());
        }
        this.addresses = (await serviceClient.get(new dtos.ListAddresses({ accountId: shared.accountId }))).addresses;
    }

    async updateSystemSettings() {
        let ss = await serviceClient.patch(new dtos.PatchSystemSettings({
            accountId: shared.accountId,
            systemFields: this.systemFields,
            customerFields: this.customerFields,
            endpointFields: this.endpointFields,
            overrideSystemTime: this.editOverrideSystemTime,
            overrideSystemDateTime: this.editOverrideSystemDateTime,
            overrideSystemTimeZoneId: this.editOverrideSystemTimeZoneId,
            recordingRetentionDays: this.editRecordingRetentionDays,
            emailAccounts: this.emailAccounts,
            enableSipRefer: this.editEnableSipRefer,
            autoAgentLogoffSeconds: this.editAutoAgentLogoffSeconds,
            defaultSipRegion: this.defaultSipRegion,
            tags: this.tags
        }));

        cache.updateSystemSettings(ss);
        ui.message.success("System settings updated successfully");
    }

    addSystemField() {
        if (_.find(this.systemFields, f => f.name == this.newSystemFieldName)) {
            ui.message.error("A field with this name already exists");
            return;
        }

        let field = new dtos.SystemSettingsField({
            id: uuid(),
            name: this.newSystemFieldName,
            type: this.newSystemFieldType,
            value: new dtos.Value()
        });
        this.systemFields.push(field);
        this.newSystemFieldName = "";
        this.showNewSystemField = false;
        this.forceUpdate();
        this.triggerResize();

    }

    removeSystemField(f: dtos.SystemSettingsField) {
        let idx = this.systemFields.indexOf(f);
        if (idx >= 0) {
            this.systemFields.splice(idx, 1);
            this.forceUpdate();
        }
    }

    addCustomerField() {
        if (_.find(this.customerFields, f => f.name == this.newCustomerFieldName)) {
            ui.message.error("A field with this name already exists");
            return;
        }

        let field = new dtos.CustomerDataField({
            id: uuid(),
            name: this.newCustomerFieldName,
            type: this.newCustomerFieldType,
            defaultValue: new dtos.Value(),
            userMode: dtos.UserDataFieldModes.Hidden,
            uniqueness: dtos.DataFieldUniqueness.NotUnique
        });
        this.customerFields.push(field);
        this.newCustomerFieldName = "";
        this.showNewCustomerField = false;
        this.forceUpdate();
        this.triggerResize();
    }

    removeCustomerField(f: dtos.CustomerDataField) {
        let idx = this.customerFields.indexOf(f);
        if (idx >= 0) {
            this.customerFields.splice(idx, 1);
            this.forceUpdate();
        }
    }

    addEndpointField() {
        if (_.find(this.endpointFields, f => f.name == this.newEndpointFieldName)) {
            ui.message.error("A field with this name already exists");
            return;
        }

        let field = new dtos.EndpointDataField({
            id: uuid(),
            name: this.newEndpointFieldName,
            type: this.newEndpointFieldType,
            endpointType: this.newEndpointFieldEndpointType as dtos.EndpointTypes,
            defaultValue: new dtos.Value(),
            userMode: dtos.UserDataFieldModes.Hidden,
            uniqueness: dtos.DataFieldUniqueness.NotUnique
        });
        this.endpointFields.push(field);
        this.newEndpointFieldName = "";
        this.showNewEndpointField = false;
        this.forceUpdate();
        this.triggerResize();
    }

    removeEndpointField(f: dtos.EndpointDataField) {
        let idx = this.endpointFields.indexOf(f);
        if (idx >= 0) {
            this.endpointFields.splice(idx, 1);
            this.forceUpdate();
        }
    }

    setShowInSearchCust(rec: dtos.CustomerDataField, show: boolean) {
        rec.showInSearch = show;
        this.forceUpdate();
    }

    setShowInListEndpoint(rec: dtos.EndpointDataField, show: boolean) {
        rec.showInLists = show;
        this.forceUpdate();
    }

    setShowInSearchEndpoint(rec: dtos.EndpointDataField, show: boolean) {
        rec.showInSearch = show;
        this.forceUpdate();
    }

    setEndpointType(rec: dtos.EndpointDataField, type: dtos.EndpointTypes | null) {
        rec.endpointType = type;
        this.forceUpdate();
    }

    setUserModeEndpoint(rec: dtos.EndpointDataField, mode: dtos.UserDataFieldModes) {
        rec.userMode = mode;
        this.forceUpdate();
    }

    setUserModeCustomer(rec: dtos.CustomerDataField, mode: dtos.UserDataFieldModes) {
        rec.userMode = mode;
        this.forceUpdate();
    }

    setUniqueness(rec: dtos.DataField, un: dtos.DataFieldUniqueness) {
        rec.uniqueness = un;
        this.forceUpdate();
    }

    editFieldValues(field: dtos.DataField) {
        this.editValuesField = field;
        this.editValues = [...(field.possibleValues || [])];
        this.showEditValues = true;
    }

    saveFieldValues() {
        this.editValuesField.possibleValues = this.editValues;
        this.showEditValues = false;
    }

    removeEmailAccount(acc: dtos.EmailAccount) {
        let idx = this.emailAccounts.indexOf(acc);
        if (idx < 0) return;
        this.emailAccounts.splice(idx, 1);
        this.forceUpdate();
    }

    newEmailAccount() {
        this.emailAccounts = [...this.emailAccounts, new dtos.EmailAccount({
            id: uuid(),
            emailAddress: "",
            server: "",
            userName: "",
            port: 587,
            password: "",
        })];
    }

    async sendTestEmail(acc: dtos.EmailAccount) {
        await serviceClient.post(new dtos.SendTestEmail({
            account: acc,
            emailAddress: this.testEmailAddress
        }));
        ui.message.info("Email message sent. Check your inbox");
    }

    removeTag(tag: dtos.Tag) {
        let idx = this.tags.indexOf(tag);
        if (idx < 0) return;
        this.tags.splice(idx, 1);
        this.forceUpdate();
    }

    newTag() {
        this.tags = [...this.tags, new dtos.Tag({
            id: uuid(),
            name: "My Tag",
            color: dtos.TagColors.Blue
        })];
    }

    move<T>(list: Array<T>, rec: T, dir: number) {
        let idx = list.indexOf(rec);
        let newIdx = idx + dir;
        if (newIdx < 0 || newIdx >= list.length) return;
        list.splice(idx, 1);
        list.splice(newIdx, 0, rec);
        this.forceUpdate();
    }

    async showDuplicate(rec: dtos.EndpointDataField) {
        this.showDuplicateChecker = true;
        this.loadingDuplicates = true;
        let response = await serviceClient.get(new dtos.FindDuplicates({ accountId: shared.accountId, endpointFieldName: rec.name }));
        this.loadingDuplicates = false;
        this.duplicates = response.duplicates;
    }

    async saveAddress(rec: dtos.AddressInfo) {
        if (rec.id) {
            var updated = await serviceClient.patch(new dtos.PatchAddress({
                accountId: shared.accountId,
                addressId: rec.id,
                city: rec.city,
                customerName: rec.customerName,
                emergencyEnabled: rec.emergencyEnabled,
                friendlyName: rec.friendlyName,
                isoCountry: rec.isoCountry,
                postalCode: rec.postalCode,
                region: rec.region,
                street: rec.street
            }));
            Object.assign(rec, updated);
        } else {
            var updated = await serviceClient.post(new dtos.NewAddress({
                accountId: shared.accountId,
                city: rec.city,
                customerName: rec.customerName,
                emergencyEnabled: rec.emergencyEnabled,
                friendlyName: rec.friendlyName,
                isoCountry: rec.isoCountry,
                postalCode: rec.postalCode,
                region: rec.region,
                street: rec.street
            }));
            Object.assign(rec, updated);
        }
        ui.message.success("Address updated successfully");
        cache.updateAddresses();
        this.forceUpdate();
    }

    async deleteAddress(rec: dtos.AddressInfo) {
        if (rec.id) {
            await serviceClient.delete(new dtos.DeleteAddress({ accountId: shared.accountId, addressId: rec.id }));
        }

        let idx = this.addresses.indexOf(rec);
        if (idx >= 0) {
            this.addresses.splice(idx, 1);
        }
        cache.updateAddresses();
        this.forceUpdate();
    }

    newAddress() {
        this.addresses.push(new dtos.AddressInfo({
            id: "",
            city: "",
            customerName: "",
            emergencyEnabled: false,
            friendlyName: "",
            isoCountry: "US",
            postalCode: "",
            region: "",
            street: ""
        }));
        this.forceUpdate();
    }

    async syncTwilioAccounts() {
        await serviceClient.post(new dtos.SyncTwilioAccounts());
        ui.message.success("Sync successful");
    }

    triggerResize() {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
    }

    render() {

        return (
            <div>
                <PageTitle title="System Settings" />
                <AccountHeader title="System" icon="fa fa-cogs" />
                <ui.Tabs animated={false} activeKey={this.activeTab} onChange={t => this.setTab(t)}>
                    {shared.canViewSettingsTab() && <ui.Tabs.TabPane key="settings" tab="Settings">
                        <ui.Form layout="vertical">
                            <ui.Collapse defaultActiveKey={[]} accordion onChange={() => this.triggerResize()}>
                                <ui.Collapse.Panel key="system" header="System Fields">
                                    <ui.Table dataSource={[...this.systemFields]} rowKey="id" pagination={false}>
                                        <ui.Table.Column fixed dataIndex="name" title="Name" width={200} />
                                        <ui.Table.Column dataIndex="type" title="Type" width={100} render={(text, rec: dtos.SystemSettingsField) => rec.type} />
                                        <ui.Table.Column dataIndex="value" title="Value" render={(text, rec: dtos.SystemSettingsField) =>
                                            <ValueEditor valueType={rec.type} value={rec.value} field={rec} accountId={shared.accountId} />} />
                                        <ui.Table.Column title="Actions" render={(text, rec: dtos.SystemSettingsField, idx: number) =>
                                            <React.Fragment>
                                                <ui.Button.Group size="small">
                                                    {rec.type == dtos.ValueTypes.String && <ui.Button onClick={() => this.editFieldValues(rec)}><i className="fa fa-list" /></ui.Button>}
                                                </ui.Button.Group><span>&nbsp;</span>
                                                <ui.Button.Group size="small">
                                                    <ui.Button disabled={idx == 0} onClick={() => this.move(this.systemFields, rec, -1)}><i className="fa fa-arrow-alt-up" /></ui.Button>
                                                    <ui.Button disabled={idx == this.systemFields.length - 1} onClick={() => this.move(this.systemFields, rec, 1)}><i className="fa fa-arrow-alt-down" /></ui.Button>
                                                </ui.Button.Group>
                                                <ui.Button.Group size="small">
                                                    <ui.Popconfirm title="Remove this system field?" onConfirm={() => this.removeSystemField(rec)}><ui.Button><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>
                                                </ui.Button.Group>
                                            </React.Fragment>} />
                                    </ui.Table>
                                    <p><small>* Please note that you cannot modify field names or type once created</small></p>
                                    <ui.Modal title="New Field" okText="Add Field" onOk={() => this.addSystemField()} visible={this.showNewSystemField} onCancel={() => this.showNewSystemField = false}>
                                        <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.addSystemField()}>
                                            <ui.Form.Item label="Name">
                                                <AutoFocus visible={this.showNewSystemField}>
                                                    <ui.Input value={this.newSystemFieldName} onChange={ev => this.newSystemFieldName = ev.target.value} />
                                                </AutoFocus>
                                            </ui.Form.Item>
                                            <ui.Form.Item label="Type">
                                                <ui.Select showSearch filterOption={selectFilter} value={this.newSystemFieldType} onChange={v => this.newSystemFieldType = v} dropdownMatchSelectWidth={false}>
                                                    {Object.keys(dtos.ValueTypes).sort((a, b) => a.localeCompare(b)).filter(t => t != dtos.ValueTypes.Custom && t != dtos.ValueTypes.NotSpecified && t != dtos.ValueTypes.Transition && t != dtos.ValueTypes.Struct && t != dtos.ValueTypes.List).map(t => <ui.Select.Option key={t} value={t}>{t}</ui.Select.Option>)}
                                                </ui.Select>
                                            </ui.Form.Item>
                                            <ui.Alert type="info" message="Note: you cannot change whiteboard field names after creation" />
                                        </ui.Form>
                                    </ui.Modal>
                                    <ui.Form.Item style={{ marginTop: 24 }}>
                                        <ui.Button type="primary" onClick={() => this.updateSystemSettings()}>Save</ui.Button>
                                        <ui.Button style={{ marginLeft: 8 }} onClick={() => this.showNewSystemField = true} type="default">Add Field</ui.Button>
                                    </ui.Form.Item>
                                </ui.Collapse.Panel>
                                <ui.Collapse.Panel key="customer" header="Customer Fields">
                                    <ui.Table dataSource={[...this.customerFields]} rowKey="id" pagination={false} scroll={{ x: 1500 }}>
                                        <ui.Table.Column fixed dataIndex="name" title="Name" width={200} />
                                        <ui.Table.Column dataIndex="type" title="Type" width={60} render={(text, rec: dtos.CustomerDataField) => rec.type} />
                                        <ui.Table.Column dataIndex="value" title="Default Value" width={100} render={(text, rec: dtos.CustomerDataField) =>
                                            <ValueEditor valueType={rec.type} value={rec.defaultValue} field={rec} accountId={shared.accountId} />} />
                                        <ui.Table.Column dataIndex="userMode" title="User Mode" width={100} render={(text, rec: dtos.CustomerDataField) =>
                                            <ui.Select value={rec.userMode} onChange={v => this.setUserModeCustomer(rec, v)} dropdownMatchSelectWidth={false}>
                                                {Object.keys(dtos.UserDataFieldModes).map(v => <ui.Select.Option key={v} value={v}>{splitIntoWords(v)}</ui.Select.Option>)}
                                            </ui.Select>} />
                                        <ui.Table.Column dataIndex="uniqueness" title="Unique" width={100} render={(text, rec: dtos.CustomerDataField) =>
                                            <ui.Select value={rec.uniqueness} onChange={v => this.setUniqueness(rec, v)} dropdownMatchSelectWidth={false}>
                                                {Object.keys(dtos.DataFieldUniqueness).filter(u => u != dtos.DataFieldUniqueness.UniqueToCustomer).map(v => <ui.Select.Option key={v} value={v}>{splitIntoWords(v)}</ui.Select.Option>)}
                                            </ui.Select>} />
                                        <ui.Table.Column dataIndex="uiTab" title="UI Tab" width={200} render={(text, rec: dtos.CustomerDataField) =>
                                            <ui.Input value={rec.uiTab} onChange={ev => { rec.uiTab = ev.target.value; this.forceUpdate() }} />} />
                                        <ui.Table.Column dataIndex="description" title="Description" width={200} render={(text, rec: dtos.CustomerDataField) =>
                                            <ui.Input value={rec.description} onChange={ev => { rec.description = ev.target.value; this.forceUpdate() }} />} />
                                        <ui.Table.Column dataIndex="showInSearch" title="Show in List" width={100} render={(text, rec: dtos.CustomerDataField) => <ui.Checkbox checked={rec.showInSearch} onChange={ev => this.setShowInSearchCust(rec, ev.target.checked)} />} />


                                        <ui.Table.Column title="Actions" width={250} render={(text, rec: dtos.CustomerDataField, idx: number) =>
                                            <React.Fragment>
                                                <ui.Button.Group size="small">
                                                    {rec.type == dtos.ValueTypes.String && <ui.Button onClick={() => this.editFieldValues(rec)}><i className="fa fa-list" /></ui.Button>}
                                                </ui.Button.Group><span>&nbsp;</span>
                                                <ui.Button.Group size="small">
                                                    <ui.Button disabled={idx == 0} onClick={() => this.move(this.customerFields, rec, -1)}><i className="fa fa-arrow-alt-up" /></ui.Button>
                                                    <ui.Button disabled={idx == this.systemFields.length - 1} onClick={() => this.move(this.customerFields, rec, 1)}><i className="fa fa-arrow-alt-down" /></ui.Button>
                                                </ui.Button.Group>
                                                <ui.Button.Group size="small" style={{ marginLeft: 8 }}>
                                                    <ui.Popconfirm title="Remove this customer field?" onConfirm={() => this.removeCustomerField(rec)}><ui.Button><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>
                                                </ui.Button.Group>
                                            </React.Fragment>} />
                                    </ui.Table>
                                    <p><small>* Please note that you cannot modify field names or type once created</small></p>

                                    <ui.Modal title="New Field" okText="Add Field" onOk={() => this.addCustomerField()} visible={this.showNewCustomerField} onCancel={() => this.showNewCustomerField = false}>
                                        <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.addCustomerField()}>
                                            <ui.Form.Item label="Name">
                                                <AutoFocus visible={this.showNewCustomerField}>
                                                    <ui.Input value={this.newCustomerFieldName} onChange={ev => this.newCustomerFieldName = ev.target.value} />
                                                </AutoFocus>
                                            </ui.Form.Item>
                                            <ui.Form.Item label="Type">
                                                <ui.Select showSearch filterOption={selectFilter} value={this.newCustomerFieldType} onChange={v => this.newCustomerFieldType = v} dropdownMatchSelectWidth={false}>
                                                    {Object.keys(dtos.ValueTypes).sort((a, b) => a.localeCompare(b)).filter(t => t != dtos.ValueTypes.Custom && t != dtos.ValueTypes.NotSpecified && t != dtos.ValueTypes.Transition && t != dtos.ValueTypes.Struct && t != dtos.ValueTypes.List).map(t => <ui.Select.Option key={t} value={t}>{t}</ui.Select.Option>)}
                                                </ui.Select>
                                            </ui.Form.Item>
                                            <ui.Alert type="info" message="Note: you cannot change customer field names after creation" />
                                        </ui.Form>
                                    </ui.Modal>
                                    <ui.Form layout="vertical">
                                        <ui.Form.Item style={{ marginTop: 24 }}>
                                            <ui.Button type="primary" onClick={() => this.updateSystemSettings()}>Save</ui.Button>
                                            <ui.Button style={{ marginLeft: 8 }} onClick={() => this.showNewCustomerField = true} type="default">Add Field</ui.Button>
                                        </ui.Form.Item>
                                    </ui.Form>
                                </ui.Collapse.Panel>
                                <ui.Collapse.Panel key="endpointFields" header="Endpoint Fields">
                                    <ui.Table dataSource={[...this.endpointFields]} rowKey="id" pagination={false} scroll={{ x: 2500, y: 400 }}>
                                        <ui.Table.Column fixed dataIndex="name" title="Name" width={200} />
                                        <ui.Table.Column dataIndex="type" title="Type" width={150} render={(text, rec: dtos.EndpointDataField) => rec.type} />
                                        <ui.Table.Column dataIndex="endpointType" title="Endpoint Type" width={200} render={(text, rec: dtos.EndpointDataField) =>
                                            <ui.Select value={rec.endpointType || ""} onChange={v => this.setEndpointType(rec, v as dtos.EndpointTypes)} dropdownMatchSelectWidth={false}>
                                                <ui.Select.Option value="">(Any)</ui.Select.Option>
                                                {Object.keys(dtos.EndpointTypes).sort((a, b) => a.localeCompare(b)).map(t => <ui.Select.Option key={t} value={t}>{t}</ui.Select.Option>)}
                                            </ui.Select>} />
                                        <ui.Table.Column dataIndex="defaultValue" title="Default Value" width={250} render={(text, rec: dtos.EndpointDataField) =>
                                            <ValueEditor valueType={rec.type} value={rec.defaultValue} field={rec} accountId={shared.accountId} />} />
                                        <ui.Table.Column dataIndex="userMode" title="User Mode" width={200} render={(text, rec: dtos.EndpointDataField) =>
                                            <ui.Select value={rec.userMode} onChange={v => this.setUserModeEndpoint(rec, v)} dropdownMatchSelectWidth={false}>
                                                {Object.keys(dtos.UserDataFieldModes).map(v => <ui.Select.Option key={v} value={v}>{splitIntoWords(v)}</ui.Select.Option>)}
                                            </ui.Select>} />
                                        <ui.Table.Column dataIndex="uniqueness" title="Unique" width={200} render={(text, rec: dtos.EndpointDataField) =>
                                            <ui.Select value={rec.uniqueness} style={{ width: 175 }} onChange={v => this.setUniqueness(rec, v)} dropdownMatchSelectWidth={false}>
                                                {Object.keys(dtos.DataFieldUniqueness).map(v => <ui.Select.Option key={v} value={v}>{splitIntoWords(v)}</ui.Select.Option>)}
                                            </ui.Select>} />
                                        <ui.Table.Column dataIndex="description" title="Description" width={200} render={(text, rec: dtos.EndpointDataField) =>
                                            <ui.Input value={rec.description} onChange={ev => { rec.description = ev.target.value; this.forceUpdate() }} />} />

                                        <ui.Table.Column dataIndex="showInSearch" title="Show in List" width={100} render={(text, rec: dtos.EndpointDataField) => <ui.Checkbox checked={rec.showInSearch} onChange={ev => this.setShowInSearchEndpoint(rec, ev.target.checked)} />} />
                                        <ui.Table.Column dataIndex="showInLists" title="Show in Dropdowns" width={100} render={(text, rec: dtos.EndpointDataField) => <ui.Checkbox checked={rec.showInLists} onChange={ev => this.setShowInListEndpoint(rec, ev.target.checked)} />} />
                                        <ui.Table.Column dataIndex="isCallerId" title="Caller ID" width={100} render={(text, rec: dtos.EndpointDataField) =>
                                            <ui.Checkbox checked={rec.isCallerId} onChange={ev => { rec.isCallerId = ev.target.checked; this.forceUpdate() }} />} />
                                        <ui.Table.Column dataIndex="isKnob" title="Knob" width={100} render={(text, rec: dtos.EndpointDataField) =>
                                            <ui.Checkbox checked={rec.isKnob} onChange={ev => { rec.isKnob = ev.target.checked; this.forceUpdate() }} />} />
                                        <ui.Table.Column title="Actions" width={250} render={(text, rec: dtos.EndpointDataField, idx: number) =>
                                            <React.Fragment>
                                                <ui.Button.Group size="small">
                                                    {rec.type == dtos.ValueTypes.String && <ui.Button onClick={() => this.editFieldValues(rec)}><i className="fa fa-list" /></ui.Button>}
                                                </ui.Button.Group><span>&nbsp;</span>
                                                <ui.Button.Group size="small">
                                                    <ui.Button disabled={idx == 0} onClick={() => this.move(this.endpointFields, rec, -1)}><i className="fa fa-arrow-alt-up" /></ui.Button>
                                                    <ui.Button disabled={idx == this.endpointFields.length - 1} onClick={() => this.move(this.endpointFields, rec, 1)}><i className="fa fa-arrow-alt-down" /></ui.Button>
                                                </ui.Button.Group><span>&nbsp;</span>
                                                <ui.Button.Group size="small">
                                                    <ui.Button title="Check for Duplicates" onClick={() => this.showDuplicate(rec)}><i className="fa fa-clone" /></ui.Button>
                                                </ui.Button.Group>
                                                <ui.Button.Group size="small">
                                                    <ui.Popconfirm title="Remove this endpoint field?" onConfirm={() => this.removeEndpointField(rec)}><ui.Button><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>
                                                </ui.Button.Group>
                                            </React.Fragment>} />
                                    </ui.Table>
                                    <p><small>* Please note that you cannot modify field names or type once created</small></p>

                                    <ui.Modal title="New Field" okText="Add Field" onOk={() => this.addEndpointField()} visible={this.showNewEndpointField} onCancel={() => this.showNewEndpointField = false}>
                                        <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.addEndpointField()}>
                                            <ui.Form.Item label="Name">
                                                <AutoFocus visible={this.showNewEndpointField}>
                                                    <ui.Input value={this.newEndpointFieldName} onChange={ev => this.newEndpointFieldName = ev.target.value} />
                                                </AutoFocus>
                                            </ui.Form.Item>
                                            <ui.Form.Item label="Endpoint Type">
                                                <ui.Select showSearch filterOption={selectFilter} value={this.newEndpointFieldEndpointType || ""} onChange={v => this.newEndpointFieldEndpointType = v as dtos.EndpointTypes} dropdownMatchSelectWidth={false}>
                                                    <ui.Select.Option value="">(Any)</ui.Select.Option>
                                                    {Object.keys(dtos.EndpointTypes).map(t => <ui.Select.Option key={t} value={t}>{t}</ui.Select.Option>)}
                                                </ui.Select>
                                            </ui.Form.Item>
                                            <ui.Form.Item label="Type">
                                                <ui.Select showSearch filterOption={selectFilter} value={this.newEndpointFieldType} onChange={v => this.newEndpointFieldType = v} dropdownMatchSelectWidth={false}>
                                                    {Object.keys(dtos.ValueTypes).filter(t => t != dtos.ValueTypes.Custom && t != dtos.ValueTypes.NotSpecified && t != dtos.ValueTypes.Transition && t != dtos.ValueTypes.Struct && t != dtos.ValueTypes.List).map(t => <ui.Select.Option key={t} value={t}>{t}</ui.Select.Option>)}
                                                </ui.Select>
                                            </ui.Form.Item>
                                            <ui.Alert type="info" message="Note: you cannot change endpoint field names after creation" />
                                        </ui.Form>
                                    </ui.Modal>
                                    <ui.Form layout="vertical">
                                        <ui.Form.Item style={{ marginTop: 24 }}>
                                            <ui.Button type="primary" onClick={() => this.updateSystemSettings()}>Save</ui.Button>
                                            <ui.Button style={{ marginLeft: 8 }} onClick={() => this.showNewEndpointField = true} type="default">Add Field</ui.Button>
                                        </ui.Form.Item>
                                    </ui.Form>
                                </ui.Collapse.Panel>
                                <ui.Collapse.Panel header="System Date/Time" key="sysdt">
                                    <ui.Row>
                                        <ui.Col span={8}>
                                            <ui.Form.Item label={<ui.Checkbox checked={this.editOverrideSystemTime} onChange={ev => this.editOverrideSystemTime = ev.target.checked}>Override System Time</ui.Checkbox>}>
                                                <ui.DatePicker disabled={!this.editOverrideSystemTime} style={{ width: "100%" }} showTime={{ use12Hours: true, format: "HH:mm", minuteStep: 15 }} format="LLLL" showToday placeholder="Enter the specified date/time" value={this.editOverrideSystemDateTime ? moment(this.editOverrideSystemDateTime) : moment()} onChange={dt => this.editOverrideSystemDateTime = dt.format("LLLL")} />
                                                <ui.Select showSearch filterOption={selectFilter} disabled={!this.editOverrideSystemTime} placeholder="Specify your time zone" value={this.editOverrideSystemTimeZoneId} onChange={v => this.editOverrideSystemTimeZoneId = v} dropdownMatchSelectWidth={false}>
                                                    {this.timezones.map(tz => <ui.Select.Option key={tz.id} value={tz.id}>{tz.displayName}</ui.Select.Option>)}
                                                </ui.Select>
                                                <p><small style={{ color: "red" }}>Please note that this will affect any date related nodes including Business Hours, Time Of Day, etc.</small></p>
                                            </ui.Form.Item>
                                        </ui.Col>
                                    </ui.Row>
                                    <ui.Form.Item label="Default SIP Region">
                                    <ui.Select value={this.defaultSipRegion} onChange={v => this.defaultSipRegion = v}>
                                            {Object.keys(dtos.TwilioSipRegions).map(r => <ui.Select.Option key={r} value={r}>{splitIntoWords(r)}</ui.Select.Option>)}
                                        </ui.Select>
                                    </ui.Form.Item>
                                </ui.Collapse.Panel>
                                <ui.Collapse.Panel header="Email Accounts" key="email">
                                    <ui.Table dataSource={[...this.emailAccounts]} rowKey="id" pagination={false}>
                                        <ui.Table.Column dataIndex="emailAddress" title="Email Address" render={(text, rec: dtos.EmailAccount) =>
                                            <ui.Input placeholder="Email Address" value={rec.emailAddress} onChange={(ev) => { rec.emailAddress = ev.target.value; this.forceUpdate() }} />} />
                                        <ui.Table.Column dataIndex="displayName" title="Display Name" render={(text, rec: dtos.EmailAccount) =>
                                            <ui.Input placeholder="Display Name" value={rec.displayName} onChange={(ev) => { rec.displayName = ev.target.value; this.forceUpdate() }} />} />
                                        <ui.Table.Column dataIndex="server" title="SMTP Server" render={(text, rec: dtos.EmailAccount) =>
                                            <ui.Input placeholder="SMTP Server" value={rec.server} onChange={(ev) => { rec.server = ev.target.value; this.forceUpdate() }} />} />
                                        <ui.Table.Column dataIndex="port" title="Port" render={(text, rec: dtos.EmailAccount) =>
                                            <ui.InputNumber placeholder="Port" value={rec.port} onChange={(ev) => { rec.port = ev; this.forceUpdate() }} />} />
                                        <ui.Table.Column dataIndex="userName" title="User Name" render={(text, rec: dtos.EmailAccount) =>
                                            <ui.Input placeholder="User Name" value={rec.userName} onChange={(ev) => { rec.userName = ev.target.value; this.forceUpdate() }} />} />
                                        <ui.Table.Column dataIndex="password" title="Password" render={(text, rec: dtos.EmailAccount) =>
                                            <ui.Input.Password autoComplete="new-password" placeholder="Password" value={rec.password} onChange={(ev) => { rec.password = ev.target.value; this.forceUpdate() }} />} />
                                        <ui.Table.Column dataIndex="id" render={(text, rec: dtos.EmailAccount) =>
                                            <ui.Button.Group>
                                                <ui.Popconfirm title="Are you sure that you want to remove this email account?" onConfirm={() => this.removeEmailAccount(rec)}><ui.Button danger><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>
                                                <ui.Popover content={<div><ui.Input.Search enterButton={<ui.Button><i className="fa fa-envelope" /></ui.Button>} onSearch={() => this.sendTestEmail(rec)} size="small" placeholder="name@me.com" value={this.testEmailAddress} onChange={ev => { this.testEmailAddress = ev.target.value; this.forceUpdate() }} /></div>}><ui.Button type="primary" title="Send test email"><i className="fa fa-envelope" /></ui.Button></ui.Popover>
                                            </ui.Button.Group>} />
                                    </ui.Table>
                                    <ui.Button.Group style={{ marginTop: 8 }}>
                                        <ui.Button type="primary" onClick={() => this.newEmailAccount()}>New Account</ui.Button>
                                    </ui.Button.Group>
                                </ui.Collapse.Panel>
                                <ui.Collapse.Panel header="Tags" key="tags">
                                    <ui.Table dataSource={[...this.tags]} rowKey="id" pagination={false}>
                                        <ui.Table.Column dataIndex="name" title="Name" render={(text, rec: dtos.Tag) =>
                                            <ui.Input placeholder="Name" value={rec.name} onChange={(ev) => { rec.name = ev.target.value; this.forceUpdate() }} />} />
                                        <ui.Table.Column dataIndex="color" title="Color" render={(text, rec: dtos.Tag) =>
                                            <ui.Select value={rec.color} onChange={v => { rec.color = v; this.forceUpdate() }} dropdownMatchSelectWidth={false}>
                                                {Object.keys(dtos.TagColors).map(tc => <ui.Select.Option key={tc} value={tc}>{splitIntoWords(tc)}</ui.Select.Option>)}
                                            </ui.Select>} />
                                        <ui.Table.Column dataIndex="id" render={(text, rec: dtos.Tag) =>
                                            <ui.Button.Group>
                                                <ui.Popconfirm title="Are you sure that you want to remove this tag?" onConfirm={() => this.removeTag(rec)}><ui.Button danger><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>
                                            </ui.Button.Group>} />
                                    </ui.Table>
                                    <ui.Button.Group style={{ marginTop: 8 }}>
                                        <ui.Button type="primary" onClick={() => this.newTag()}>New Tag</ui.Button>
                                    </ui.Button.Group>
                                </ui.Collapse.Panel>
                                <ui.Collapse.Panel header="Addresses" key="addresses">
                                    <ui.Table dataSource={[...this.addresses]} rowKey="id" pagination={false}>
                                        <ui.Table.Column dataIndex="friendlyName" title="Friendly Name" render={(text, rec: dtos.AddressInfo) => <ui.Input value={rec.friendlyName} onChange={ev => { rec.friendlyName = ev.target.value; this.forceUpdate(); }} />} />
                                        <ui.Table.Column dataIndex="customerName" title="Customer/Home Owner" render={(text, rec: dtos.AddressInfo) => <ui.Input value={rec.customerName} onChange={ev => { rec.customerName = ev.target.value; this.forceUpdate(); }} />} />
                                        <ui.Table.Column dataIndex="street" title="Street" render={(text, rec: dtos.AddressInfo) => <ui.Input value={rec.street} onChange={ev => { rec.street = ev.target.value; this.forceUpdate(); }} />} />
                                        <ui.Table.Column dataIndex="city" title="City" render={(text, rec: dtos.AddressInfo) => <ui.Input value={rec.city} onChange={ev => { rec.city = ev.target.value; this.forceUpdate(); }} />} />
                                        <ui.Table.Column dataIndex="region" title="State" render={(text, rec: dtos.AddressInfo) => <ui.Input value={rec.region} onChange={ev => { rec.region = ev.target.value; this.forceUpdate(); }} />} />
                                        <ui.Table.Column dataIndex="postalCode" title="Postal Code" render={(text, rec: dtos.AddressInfo) => <ui.Input value={rec.postalCode} onChange={ev => { rec.postalCode = ev.target.value; this.forceUpdate(); }} />} />
                                        <ui.Table.Column dataIndex="isoCountry" title="Country" render={(text, rec: dtos.AddressInfo) => <ui.Input value={rec.isoCountry} onChange={ev => { rec.isoCountry = ev.target.value; this.forceUpdate(); }} />} />
                                        <ui.Table.Column dataIndex="emergencyEnabled" title="Emergency" render={(text, rec: dtos.AddressInfo) => <ui.Checkbox checked={rec.emergencyEnabled} onChange={ev => { rec.emergencyEnabled = ev.target.checked; this.forceUpdate(); }} />} />
                                        <ui.Table.Column render={(text, rec: dtos.AddressInfo) =>
                                            <ui.Button.Group>
                                                <ui.Button type="primary" onClick={() => this.saveAddress(rec)}><i className="fa fa-save" /></ui.Button>
                                                <ui.Popconfirm title="Delete this address?" onConfirm={() => this.deleteAddress(rec)}><ui.Button danger><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>
                                            </ui.Button.Group>
                                        } />
                                    </ui.Table>
                                    <ui.Button.Group style={{ marginTop: 8 }}>
                                        <ui.Button type="primary" onClick={() => this.newAddress()}>New Address</ui.Button>
                                    </ui.Button.Group>
                                </ui.Collapse.Panel>
                                <ui.Collapse.Panel header="Call Center" key="callcenter">
                                    <ui.Form.Item label="Auto Agent Logoff (secs)">
                                        <ui.InputNumber value={this.editAutoAgentLogoffSeconds} onChange={v => this.editAutoAgentLogoffSeconds = v} />
                                    </ui.Form.Item>
                                </ui.Collapse.Panel>
                                <ui.Collapse.Panel header="Recordings" key="recordings">
                                    <ui.Form.Item label="Retention # Days">
                                        <ui.InputNumber value={this.editRecordingRetentionDays} onChange={v => this.editRecordingRetentionDays = v} />
                                    </ui.Form.Item>
                                </ui.Collapse.Panel>
                                <ui.Collapse.Panel header="SIP Refer" key="sip">
                                    <ui.Form.Item>
                                        <ui.Checkbox checked={this.editEnableSipRefer} onChange={ev => this.editEnableSipRefer = ev.target.checked}>Enable SIP Refer Beta</ui.Checkbox>
                                    </ui.Form.Item>
                                </ui.Collapse.Panel>

                                {this.systemInfo && <ui.Collapse.Panel header="System Info" key="System">
                                    <ui.Table dataSource={[this.systemInfo]}>
                                        <ui.Table.Column dataIndex="version" title="Version" />
                                        <ui.Table.Column dataIndex="twilioCallbackUrl" title="TwilioCallbackUrl" />
                                        <ui.Table.Column dataIndex="twilioCallbackBaseUrl" title="TwilioCallbackBaseUrl" />
                                        <ui.Table.Column dataIndex="twilioFallbackBaseUrl" title="TwilioFallbackBaseUrl" />
                                        <ui.Table.Column dataIndex="sipOriginationUrl" title="SipOriginationUrl" />
                                        <ui.Table.Column dataIndex="isDevEnvironment" render={(text, rec:dtos.SystemInfo) => rec.isDevEnvironment ? "Yes" : "No"} title="Is Dev Env" />
                                    </ui.Table>
                                    {shared.roles.indexOf("SystemAdministrator") >= 0 && <ui.Button.Group style={{ marginTop: 16 }}>
                                        <ui.Popconfirm title="Sync Twilio accounts?" onConfirm={() => this.syncTwilioAccounts()}><ui.Button type="primary">Sync Twilio Accounts</ui.Button></ui.Popconfirm>
                                    </ui.Button.Group>}
                                </ui.Collapse.Panel>}

                            </ui.Collapse>
                            <ui.Form.Item style={{ marginTop: 24 }}>
                                <ui.Button type="primary" onClick={() => this.updateSystemSettings()}>Save</ui.Button>
                            </ui.Form.Item>
                        </ui.Form>
                    </ui.Tabs.TabPane>}
                    {shared.canViewSettingsTab() && <ui.Tabs.TabPane key="dialing" tab="International">
                        {shared.accountId.length > 0 && shared.roles.indexOf("SystemAdministrator") >= 0 && <DialingPermissions accountId={shared.accountId} />}
                    </ui.Tabs.TabPane>}
                    {shared.canViewFilesTab() && <ui.Tabs.TabPane key="files" tab="Files">
                        {shared.accountId.length > 0 && <FilesIndex accountId={shared.accountId} />}
                    </ui.Tabs.TabPane>}
                    {shared.canViewSettingsTab() && <ui.Tabs.TabPane key="users" tab="Users">
                        <UsersIndex />
                    </ui.Tabs.TabPane>}
                    {shared.canViewSettingsTab() && <ui.Tabs.TabPane key="log" tab="Log">
                        <SystemLog accountId={shared.accountId} />
                    </ui.Tabs.TabPane>}
                    {shared.canViewSettingsTab() && <ui.Tabs.TabPane key="trust" tab="Trust">
                        <TrustIndex accountId={shared.accountId} />
                    </ui.Tabs.TabPane>}
                    {shared.isCustomer() && shared.canViewReportsTab() && <ui.Tabs.TabPane key="reports" tab="Reports">
                        <Reports accountId={shared.accountId} />
                    </ui.Tabs.TabPane>}
                    {shared.canViewNotificationsTab() && <ui.Tabs.TabPane key="notifications" tab="Notifications">
                        {shared.accountId.length > 0 && <Notifications accountId={shared.accountId} />}
                    </ui.Tabs.TabPane>}
                    {shared.canViewSessionsTab() && <ui.Tabs.TabPane key="sessions" tab="Sessions">
                        {shared.accountId.length > 0 && <Sessions accountId={shared.accountId} />}
                    </ui.Tabs.TabPane>}
                    {shared.isUser() && <ui.Tabs.TabPane key="user-settings" tab="Settings">
                        {shared.accountId.length > 0 && <UserSettings accountId={shared.accountId} />}
                    </ui.Tabs.TabPane>}
                </ui.Tabs>
                <ui.Modal title="Edit Possible Values" visible={this.showEditValues} onOk={() => this.saveFieldValues()} onCancel={() => this.showEditValues = false}>
                    <table>
                        <thead className="ant-table-thead">
                            <tr>
                                <th>Value</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody className="ant-table-tbody">
                            {this.editValues.map((v, i) =>
                                <tr key={"row-" + i} className="ant-table-row ant-table-row-level-0">
                                    <td>
                                        <ui.Input value={v} onChange={ev => this.editValues[i] = ev.target.value} />
                                    </td>
                                    <td>
                                        <a onClick={() => this.editValues.splice(i, 1)}>Remove</a>
                                    </td>
                                </tr>)}
                        </tbody>
                    </table>
                    <br />
                    <ui.Button type="primary" onClick={() => this.editValues.push("")}>Add Item</ui.Button>
                </ui.Modal>
                {this.showDuplicateChecker && <ui.Modal title="Duplicate Checker" cancelButtonProps={{ hidden: true }} onOk={() => this.showDuplicateChecker = false} visible={this.showDuplicateChecker} okText="Done" cancelText="Close" onCancel={() => this.showDuplicateChecker = false}>
                    {this.loadingDuplicates && <div style={{ paddingTop: 25, textAlign: "center" }}><ui.Spin /></div>}
                    {!this.loadingDuplicates && this.duplicates.length == 0 && <div>
                        <p>No duplicates found</p>
                    </div>}
                    {!this.loadingDuplicates && this.duplicates.length > 0 && <div style={{ maxHeight: 300, overflowY: "auto" }}>
                        {this.duplicates.map(d =>
                            <div key={d.fieldValue}>
                                <p style={{ margin: 0, padding: 0 }}><strong>{d.fieldValue}:</strong></p>
                                <ul>
                                    {d.endpoints.map((ep, i) => <li key={"dup-" + i}><span dangerouslySetInnerHTML={{ __html: ep }} /></li>)}
                                </ul>
                                <ui.Divider />
                            </div>)}
                    </div>}
                </ui.Modal>}
            </div >
        );
    }
}