import { React, Route, Routes, serviceClient, ui, observable, observer, makeObservable } from "./common";
import RecoverAccount from "./recover-password";

function NotFound(props) {
    return <div>Invalid Path</div>;
}

@observer
export default class Public extends React.Component {
    @observable loaded = false;

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    componentDidMount() {
        fetch("/config.json").then(res => {
            res.json().then(cfg => {
                serviceClient.constructor(cfg.apiUrl);
                serviceClient.baseUrl = cfg.apiUrl;
                this.loaded = true;
            });
        });
    }

    render() {
        if (!this.loaded) return <div style={{ textAlign: "center", marginTop: 200 }}><ui.Spin size="large" /></div>;
        return (
            <Routes>
                <Route path="/public/recover" element={<RecoverAccount />} />
                <Route element={<NotFound />} />
            </Routes>
        );
    }
}