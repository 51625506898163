import { React, serviceClient, dtos, observer, observable, ui, _, Link, Observer, selectFilter, makeObservable } from "../common";
import AutoFocus from "../autofocus";

@observer
export default class UsersIndex extends React.Component {
    @observable users: Array<dtos.UserInfo> = [];
    @observable pagination: any = { position: "both" };
    @observable sort: any = {};
    @observable accounts: Array<dtos.AccountInfo> = [];
    @observable filter: any = {};
    @observable pageSize = 20;

    @observable showNewUser = false;
    @observable newUserAccountId = "";
    @observable newUserEmail = "";
    @observable newUserPass = "";
    @observable newUserFirstName = "";
    @observable newUserLastName = "";

    @observable showEditUser = false;
    @observable editUserInfo: dtos.UserInfo = null;
    @observable editPass = "";
    @observable editId = "";
    @observable editFirstName = "";
    @observable editLastName = "";
    @observable editAccountIds: Array<string> = [];
    @observable editRoles: Array<string> = [];
    @observable availableRoles: Array<string> = [];
    @observable isSystemAdmin = false;

    constructor(props) {
        super(props);
        makeObservable(this);
        this.filter.email = localStorage.getItem("UsersIndex.filter.email");
        this.filter.accountId = localStorage.getItem("UsersIndex.filter.accountId");
        this.pageSize = parseInt(localStorage.getItem("UsersIndex.pagination.pageSize") || "20");
    }

    async componentDidMount() {
        let accountsResponse = await serviceClient.get(new dtos.ListAccounts({ all: true }));
        this.accounts = accountsResponse.items;
        this.newUserAccountId = this.accounts.length > 0 ? this.accounts[0].id : "";
        let userInfo = await serviceClient.get(new dtos.GetAuthStatus());
        this.availableRoles = [];
        if (userInfo.roles.indexOf("SystemAdministrator") >= 0) {
            this.availableRoles.push("SystemAdministrator");
        }
        this.availableRoles.push("Manager");
        this.availableRoles.push("ReadOnly");
        this.availableRoles.push("NoVoicemailFileAccess");

        this.isSystemAdmin = userInfo.roles.indexOf("SystemAdministrator") >= 0;
        this.refresh(1, {});
    }

    async refresh(page = this.pagination.page || 1, sort = this.sort) {
        let request = new dtos.ListUsers({
            countPerPage: this.pageSize,
            page: page - 1,
            sortField: sort.field,
            sortOrder: sort.order,
            accountIds: this.filter.accountId ? [this.filter.accountId] : null,
            emailFilter: this.filter.email,
            simplifiedPaging: true
        });
        let response = await serviceClient.get(request);
        this.users = response.items;

        let pager = { ...this.pagination };
        pager.current = page;
        pager.total = page * this.pageSize + (response.hasMorePages ? 1 : 0);
        pager.pageSize = this.pageSize;
        pager.showSizeChanger = true;
        pager.pageSizeOptions = ["10", "20", "50", "100", "250"];
        pager.onShowSizeChange = (c, v) => this.pageSize = v;
        this.pagination = pager;
        this.sort = sort;

        localStorage.setItem("UsersIndex.filter.email", this.filter.email || "");
        localStorage.setItem("UsersIndex.filter.accountId", this.filter.accountId || "");
        localStorage.setItem("UsersIndex.pagination.pageSize", this.pageSize.toString());
    }

    async saveNewUser() {
        let request = new dtos.NewUser({ accountId: this.newUserAccountId, emailAddress: this.newUserEmail, password: this.newUserPass, firstName: this.newUserFirstName, lastName: this.newUserLastName });
        let newUser = await serviceClient.post(request);
        window.location.hash = `/users/${newUser.id}`;
    }

    editUser(user: dtos.UserInfo) {
        this.editUserInfo = user;
        this.editId = user.id;
        this.editRoles = user.roles;
        this.editAccountIds = user.accountIds;
        this.editPass = "";
        this.editFirstName = user.firstName;
        this.editLastName = user.lastName;
        this.showEditUser = true;
    }

    async updateUser() {
        await serviceClient.patch(new dtos.PatchUser({
            userId: this.editId,
            newPassword: this.editPass || null,
            accountIds: this.editAccountIds,
            roles: this.isSystemAdmin ? this.editRoles : null,
            firstName: this.editFirstName,
            lastName: this.editLastName
        }));
        ui.message.success("User updated");
        this.refresh();
        this.showEditUser = false;
    }

    async deleteUser() {
        if (!this.editId) return;
        await serviceClient.delete(new dtos.DeleteUser({ userId: this.editId }));
        this.refresh();
        this.showEditUser = false;
    }

    checkFilterSubmit(ev: React.KeyboardEvent) {
        if (ev.which == 13) {
            this.refresh();
        }
    }

    render() {
        return (
            <div>
                <ui.Button.Group style={{ marginBottom: 16 }}>
                    <ui.Button type="primary" onClick={() => this.showNewUser = true}>New User</ui.Button>
                </ui.Button.Group>
                <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    <ui.Form.Item>
                        <ui.Input onKeyPress={ev => this.checkFilterSubmit(ev)} placeholder="Search by email" value={this.filter.email || ""} onChange={ev => this.filter.email = ev.target.value} />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Select onInputKeyDown={ev => this.checkFilterSubmit(ev)} showSearch filterOption={selectFilter} style={{ width: 175 }} dropdownMatchSelectWidth={false} placeholder="Filter by account" value={this.filter.accountId || undefined} onChange={v => this.filter.accountId = v}>
                            <ui.Select.Option value="_">(Not Assigned)</ui.Select.Option>
                            {this.accounts.map(c => <ui.Select.Option key={c.id} value={c.id}>{c.name}</ui.Select.Option>)}
                        </ui.Select>
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="primary" onClick={() => this.refresh()}>Search</ui.Button>
                            <ui.Button type="default" onClick={() => { this.filter = {}; this.refresh(); }}>Clear</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>
                </ui.Form>
                <ui.Table className="hide-pagination-buttons" dataSource={this.users} rowKey="id" pagination={this.pagination} onChange={(p, f, s) => this.refresh(p.current, s)}>
                    <ui.Table.Column dataIndex="emailAddress" title="Email Address" render={(text, rec: dtos.UserInfo) => <a onClick={() => this.editUser(rec)}>{rec.emailAddress}</a>} />
                    <ui.Table.Column dataIndex="firstName" title="First Name" />
                    <ui.Table.Column dataIndex="lastName" title="Last Name" />
                    <ui.Table.Column dataIndex="accountNames" title="Accounts" render={(text, rec: dtos.UserInfo) => rec.accountNames.join(", ")} />
                    <ui.Table.Column dataIndex="roles" title="Roles" render={(text, rec: dtos.UserInfo) => <span>{rec.roles.join(", ")}</span>} />
                </ui.Table>
                <ui.Modal title="New User" visible={this.showNewUser} okText="Save" onCancel={() => this.showNewUser = false} onOk={() => this.saveNewUser()}>
                    <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.saveNewUser()}>
                        <ui.Form.Item label="Account">
                            <ui.Select showSearch filterOption={selectFilter} value={this.newUserAccountId} onChange={v => this.newUserAccountId = v} dropdownMatchSelectWidth={false}>
                                {this.accounts.map(acc => <ui.Select.Option key={acc.id} value={acc.id}>{acc.name}</ui.Select.Option>)}
                            </ui.Select>
                        </ui.Form.Item>
                        <ui.Form.Item label="Email Address">
                            <AutoFocus visible={this.showNewUser}>
                                <ui.Input value={this.newUserEmail} onChange={ev => this.newUserEmail = ev.target.value} />
                            </AutoFocus>
                        </ui.Form.Item>
                        <ui.Row gutter={6}>
                            <ui.Col span={12}>
                                <ui.Form.Item label="First Name">
                                    <ui.Input value={this.newUserFirstName} onChange={ev => this.newUserFirstName = ev.target.value} />
                                </ui.Form.Item>
                            </ui.Col>
                            <ui.Col span={12}>
                                <ui.Form.Item label="Last Name">
                                    <ui.Input value={this.newUserLastName} onChange={ev => this.newUserLastName = ev.target.value} />
                                </ui.Form.Item>
                            </ui.Col>
                        </ui.Row>
                        <ui.Form.Item label="Password">
                            <ui.Input.Password value={this.newUserPass} autoComplete="new-password" onChange={ev => this.newUserPass = ev.target.value} />
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>
                <ui.Modal title="Edit User" visible={this.showEditUser} onCancel={() => this.showEditUser = false} onOk={() => this.updateUser()}>
                    <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.updateUser()}>
                        <ui.Row gutter={6}>
                            <ui.Col span={12}>
                                <ui.Form.Item label="First Name">
                                    <ui.Input value={this.editFirstName} onChange={ev => this.editFirstName = ev.target.value} />
                                </ui.Form.Item>
                            </ui.Col>
                            <ui.Col span={12}>
                                <ui.Form.Item label="Last Name">
                                    <ui.Input value={this.editLastName} onChange={ev => this.editLastName = ev.target.value} />
                                </ui.Form.Item>
                            </ui.Col>
                        </ui.Row>
                        <ui.Form.Item label="New Password">
                            <ui.Input.Password placeholder="Leave blank to keep the same" autoComplete="new-password" value={this.editPass} onChange={ev => this.editPass = ev.target.value} />
                        </ui.Form.Item>
                        <ui.Form.Item label="Accounts">
                            <ui.Select mode="multiple" value={this.editAccountIds} onChange={vs => this.editAccountIds = vs} dropdownMatchSelectWidth={false}>
                                {this.accounts.map(r => <ui.Select.Option key={r.id} value={r.id}>{r.name}</ui.Select.Option>)}
                            </ui.Select>
                        </ui.Form.Item>
                        {this.isSystemAdmin && <ui.Form.Item label="Roles">
                            <ui.Select mode="multiple" value={this.editRoles} onChange={vs => this.editRoles = vs} dropdownMatchSelectWidth={false}>
                                {this.availableRoles.map(r => <ui.Select.Option key={r} value={r}>{r}</ui.Select.Option>)}
                            </ui.Select>
                        </ui.Form.Item>}
                        <ui.Popconfirm title="Are you sure you want to delete this user?" onConfirm={() => this.deleteUser()}><a style={{ color: "red", position: "absolute", bottom: 16, left: 16 }}>Delete User</a></ui.Popconfirm>
                    </ui.Form>
                </ui.Modal>
            </div>
        );
    }
}