import { useEffect, useState } from "react";
import { React, serviceClient, dtos, observer, observable, ui, Link, _, splitIntoWords, uuid, selectFilter, shared, moment, moveArray, removeArray, makeObservable, withRouter } from "../common";
import { ThirdPartyDemoSettings, ThirdPartyPhoneSystemSettings, ThirdPartySipAccountSettings, ThirdPartySipSettings } from "../dtos";

interface Props {
    settings: dtos.ThirdPartyPhoneSystemSettings;
    onChanged: (settings: dtos.ThirdPartyPhoneSystemSettings) => void;
}

export default function ThirdParty(props: Props) {
    const [settings, setSettings] = useState(props.settings);

    useEffect(function () {
        const updated = props.settings;
        let changed = false;
        if (updated.type === dtos.ThirdPartyPhoneSystemTypes.Sip && !updated.sipSettings) {
            updated.sipSettings = new dtos.ThirdPartySipSettings({
                accounts: []
            });
            changed = true;
        } else if (updated.type === dtos.ThirdPartyPhoneSystemTypes.Demo && !updated.demoSettings) {
            updated.demoSettings = new dtos.ThirdPartyDemoSettings({
                extension: "4000"
            });
        }

        if (changed) {
            props.onChanged(new dtos.ThirdPartyPhoneSystemSettings(updated));
        } else {
            setSettings(updated);
        }
    }, [props.settings]);

    function updateSettings(update: Partial<ThirdPartyPhoneSystemSettings>) {
        props.onChanged(new dtos.ThirdPartyPhoneSystemSettings({
            ...settings,
            ...update
        }));
    }

    function updateSipSettings(update: Partial<ThirdPartySipSettings>) {
        updateSettings({
            sipSettings: new dtos.ThirdPartySipSettings({
                ...settings.sipSettings,
                ...update
            })
        });
    }
    function updateSipAccount(which: number, update: Partial<ThirdPartySipAccountSettings>) {
        let accounts = settings.sipSettings.accounts;
        accounts[which] = Object.assign(accounts[which], update);
        updateSipSettings({
            accounts: [...accounts]
        });
    }

    function addSipAccount() {
        updateSipSettings({
            accounts: [...settings.sipSettings.accounts, new dtos.ThirdPartySipAccountSettings({

            })]
        })
    }

    function removeSipAccount(i: number) {
        let accs = [...settings.sipSettings.accounts];
        accs.splice(i, 1);
        updateSipSettings({
            accounts: accs
        });
    }

    const validCodecs = [
        dtos.AudioCodecTypes.PCMU,
        dtos.AudioCodecTypes.PCMA,
        dtos.AudioCodecTypes.GSM,
        dtos.AudioCodecTypes.G722,
        dtos.AudioCodecTypes.G729,
        dtos.AudioCodecTypes.ILBC,
        dtos.AudioCodecTypes.SPEEX,
        dtos.AudioCodecTypes.DTMF,
        dtos.AudioCodecTypes.OPUS,
        dtos.AudioCodecTypes.G7221
    ]

    return <ui.Form layout="vertical" style={{ width: "60%" }}>
        <ui.Form.Item label="Type">
            <ui.Select value={settings.type} onChange={v => updateSettings({ type: v })}>
                {Object.keys(dtos.ThirdPartyPhoneSystemTypes).map(k => <ui.Select.Option key={k} value={k}>{splitIntoWords(k)}</ui.Select.Option>)}
            </ui.Select>
        </ui.Form.Item>
        {settings.type === dtos.ThirdPartyPhoneSystemTypes.Demo && settings.demoSettings && <ui.Form.Item label="Extension">
            <ui.Input placeholder="Extension of phone" value={settings.demoSettings.extension} onChange={ev => updateSettings({
                demoSettings: new ThirdPartyDemoSettings({extension: ev.target.value})
            })} />
        </ui.Form.Item>}
        {settings.type === dtos.ThirdPartyPhoneSystemTypes.Sip && settings.sipSettings && <ui.Form.Item>
            <ui.Collapse accordion>
                {settings.sipSettings.accounts.map((acc, i) => <ui.Collapse.Panel key={`key-${i}`} header={`SIP Account ${i + 1}: ${acc.number || ""}<${acc.displayName || ""}>`}>
                    <ui.Row gutter={8}>
                        <ui.Col span={5}>
                            <ui.Form.Item label="Number">
                                <ui.Input value={acc.number} onChange={ev => updateSipAccount(i, { number: ev.target.value })} />
                            </ui.Form.Item>
                        </ui.Col>
                        <ui.Col span={5}>
                            <ui.Form.Item label="Display Name">
                                <ui.Input value={acc.displayName} onChange={ev => updateSipAccount(i, { displayName: ev.target.value })} />
                            </ui.Form.Item>
                        </ui.Col>
                        <ui.Col span={5}>
                            <ui.Form.Item label="Auth Name">
                                <ui.Input value={acc.authName} onChange={ev => updateSipAccount(i, { authName: ev.target.value })} />
                            </ui.Form.Item>
                        </ui.Col>
                        <ui.Col span={5}>
                            <ui.Form.Item label="Auth Password">
                                <ui.Input.Password value={acc.password} onChange={ev => updateSipAccount(i, { password: ev.target.value })} />
                            </ui.Form.Item>
                        </ui.Col>
                        <ui.Col span={4}>
                            <ui.Form.Item label="Domain">
                                <ui.Input value={acc.userDomain} onChange={ev => updateSipAccount(i, { userDomain: ev.target.value })} />
                            </ui.Form.Item>
                        </ui.Col>
                    </ui.Row>
                    <ui.Row gutter={8}>
                        <ui.Col span={12}>
                            <ui.Form.Item label="SIP Server">
                                <ui.Input value={acc.sipServer} onChange={ev => updateSipAccount(i, { sipServer: ev.target.value })} />
                            </ui.Form.Item>
                        </ui.Col>
                        <ui.Col span={12}>
                            <ui.Form.Item label="SIP Port">
                                <ui.InputNumber value={acc.sipServerPort || 5060} onChange={ev => updateSipAccount(i, { sipServerPort: parseInt(ev.toString()) })} />
                            </ui.Form.Item>
                        </ui.Col>
                    </ui.Row>

                    <ui.Row gutter={8}>
                        <ui.Col span={12}>
                            <ui.Form.Item label="Outbound Server">
                                <ui.Input value={acc.outboundServer} onChange={ev => updateSipAccount(i, { outboundServer: ev.target.value })} />
                            </ui.Form.Item>
                        </ui.Col>
                        <ui.Col span={12}>
                            <ui.Form.Item label="Outbound Port">
                                <ui.InputNumber value={acc.outboundServerPort || 5060} onChange={ev => updateSipAccount(i, { outboundServerPort: parseInt(ev.toString()) })} />
                            </ui.Form.Item>
                        </ui.Col>
                    </ui.Row>

                    <ui.Row gutter={8}>
                        <ui.Col span={12}>

                            <ui.Form.Item label="STUN Server">
                                <ui.Input value={acc.stunServer} onChange={ev => updateSipAccount(i, { stunServer: ev.target.value })} />
                            </ui.Form.Item>
                        </ui.Col>
                        <ui.Col span={12}>
                            <ui.Form.Item label="STUN Port">
                                <ui.InputNumber value={acc.stunPort || 3478} onChange={ev => updateSipAccount(i, { stunPort: parseInt(ev.toString()) })} />
                            </ui.Form.Item>
                        </ui.Col>
                    </ui.Row>

                    <ui.Row gutter={8}>
                        <ui.Col span={8}>
                            <ui.Form.Item label="Transport Type">
                                <ui.Select value={acc.transportType} onChange={v => updateSipAccount(i, { transportType: v })}>
                                    {Object.keys(dtos.TransportTypes).map(tt => <ui.Select.Option key={tt} value={tt}>{splitIntoWords(tt)}</ui.Select.Option>)}
                                </ui.Select>
                            </ui.Form.Item>
                        </ui.Col>

                        <ui.Col span={8}>
                            <ui.Form.Item label="DTMF Method">
                                <ui.Select value={acc.dtmfMethod} onChange={v => updateSipAccount(i, { dtmfMethod: v })}>
                                    {Object.keys(dtos.DtmfMethods).map(tt => <ui.Select.Option key={tt} value={tt}>{splitIntoWords(tt)}</ui.Select.Option>)}
                                </ui.Select>
                            </ui.Form.Item>
                        </ui.Col>

                        <ui.Col span={8}>
                            <ui.Form.Item label="Codecs">
                                <ui.Select mode="tags" value={acc.audioCodecs} style={{ width: 300 }} placeholder="Select Codecs" onChange={(vs: dtos.AudioCodecTypes[]) => updateSipAccount(i, { audioCodecs: vs })}>
                                    {validCodecs.map(ct => <ui.Select.Option key={dtos.AudioCodecTypes[ct]} value={dtos.AudioCodecTypes[ct]}>{dtos.AudioCodecTypes[ct]}</ui.Select.Option>)}
                                </ui.Select>

                            </ui.Form.Item>
                        </ui.Col>
                    </ui.Row>

                    <ui.Form.Item>
                        <ui.Popconfirm title="Remove this SIP account?" onConfirm={() => removeSipAccount(i)}><ui.Button danger>Remove Account</ui.Button></ui.Popconfirm>
                    </ui.Form.Item>
                </ui.Collapse.Panel>)}
            </ui.Collapse>
            <ui.Space style={{ marginTop: 8 }}>
                <ui.Button type="default" onClick={addSipAccount}>New Account</ui.Button>
            </ui.Space>
        </ui.Form.Item>}
    </ui.Form>


}