import { React, ui } from "../common";

interface Props { children: any }

export default class OverflowPanel extends React.Component<Props> {
    resizeTimer: any = null;
    div = React.createRef<HTMLDivElement>();

    componentDidMount() {
        this.resizeTimer = setInterval(() => {
            this.resize();
        }, 250);
    }

    componentWillUnmount() {
        clearInterval(this.resizeTimer);
    }

    resize() {
        const padding = 8;
        if (this.div.current == null) return;
        let height = document.body.clientHeight - this.div.current.getBoundingClientRect().top - padding;
        this.div.current.style.height = height + "px";
    }

    render() {
        return (
            <div ref={this.div} style={{ overflowY: "auto", padding: 8 }}>
                {this.props.children}
            </div>
        );
    }
}