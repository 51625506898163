import { React, ui, dtos, observable, observer, serviceClient, moment, selectFilter, makeObservable } from "./common";
import Recorder from 'recorder-js';
import AutoComplete from "./autocomplete";

interface Props {
    visible: boolean;
    accountId: string;
    customerId?:string;
    onClosed: () => void;
    onSaved: (file: dtos.FileInfo) => void;
}

@observer
export default class AudioRecorder extends React.Component<Props> {
    static audioContext: any = null;

    @observable isRecordingVoice = false;
    @observable recordingUrl = "";
    @observable recordingCustomerId = "";
    @observable recordingName = "";
    @observable saving = false;
    @observable isCustomerReadOnly = false;

    recorder: Recorder = null;
    recorderInitialized = false;
    recordingBlob: any;
    recordStream: MediaStream;
    audioPlayer = React.createRef<HTMLAudioElement>();

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    async componentDidMount() {
        await this.init();
        this.recordingCustomerId = this.props.customerId || "";
        this.isCustomerReadOnly = this.recordingCustomerId.length > 0;
    }

    async componentDidUpdate(prevProps: Props) {
        if (!prevProps.visible && this.props.visible) {
            await this.init();
        }
    }

    async init() {
        this.recordingName = moment().format("YYYY-MM-DD-HH-mm-ss") + ".wav";
    }

    async toggleRecordVoice() {
        try {
            if (!this.isRecordingVoice) {
                this.recordingUrl = "";
                if (!this.recorderInitialized) {
                    if (AudioRecorder.audioContext == null) {
                        AudioRecorder.audioContext = new ((window as any).AudioContext || (window as any).webkitAudioContext)();
                    }
                    this.recorder = new Recorder(AudioRecorder.audioContext);
                    let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                    this.recordStream = stream;
                    this.recorder.init(stream);
                    this.recorderInitialized = true;
                    await this.recorder.start();
                    this.isRecordingVoice = true;
                } else {
                    await this.recorder.start();
                    this.isRecordingVoice = false;
                }
            } else {
                let response = await this.recorder.stop();
                console.log("recording stopped", response);
                this.isRecordingVoice = false;
                this.recordingBlob = response.blob;
                this.recordingUrl = URL.createObjectURL(response.blob);
                this.audioPlayer.current.src = this.recordingUrl;
                this.recorderInitialized = false;
                for (let track of this.recordStream.getTracks()) {
                    track.stop();
                }
                this.recordStream = null;
            }
        } catch (err) {
            console.log("error during toggle record voice", err);
            ui.message.error("Error during microphone access");
        }
    }

    saveRecording() {
        this.saving = true;
        let fileName = this.recordingName + (this.recordingName.endsWith(".wav") ? "" : ".wav");
        let form = new FormData();
        form.append(fileName, this.recordingBlob, fileName);
        form.append("accountId", this.props.accountId);
        form.append("customerId", this.recordingCustomerId);
        let url = serviceClient.baseUrl + "/files";
        fetch(url, {
            method: "POST",
            body: form,
            credentials: "include",
            headers: {
                "Accept": "application/json"
            }
        }).then(response => {
            response.json().then(file => {
                this.props.onSaved(file);
            });
            this.saving = false;
        }).catch(error => {
            ui.message.error("There was an error uploading this file.");
            this.saving = false;
        });
    }

    render() {
        let { visible, accountId, onClosed } = this.props;
        return (
            <ui.Modal title="Voice Recorder" visible={visible} okText="Save" okButtonProps={{ disabled: this.saving || this.recordingUrl.length == 0 }} onOk={() => this.saveRecording()} onCancel={() => onClosed()}>
                <ui.Form layout="vertical">
                    <ui.Form.Item label="Customer">
                        <AutoComplete placeholder="Select a customer" type={dtos.ValueTypes.Customer} value={this.recordingCustomerId} onChanged={v => this.recordingCustomerId = v} />
                    </ui.Form.Item>
                    <ui.Form.Item label="File Name">
                        <ui.Input value={this.recordingName} onChange={ev => this.recordingName = ev.target.value} placeholder="E.g. myfile.wav" />
                    </ui.Form.Item>
                    <ol>
                        <li>Press the Record button to start recording</li>
                        <li>Press it again to stop recording</li>
                        <li>You can then use the scrubber to preview your audio</li>
                        <li>If you are happy with your results press the Save button</li>
                    </ol>
                </ui.Form>
                <audio ref={this.audioPlayer} controls />
                <ui.Button style={{ position: "absolute", bottom: 12, left: 16 }} onClick={() => this.toggleRecordVoice()} danger={this.isRecordingVoice} type="primary"><i className="fa fa-microphone" /></ui.Button>
            </ui.Modal>
        );
    }
}