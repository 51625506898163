import { React, serviceClient, dtos, observer, observable, ui, Link, makeObservable, withRouter, shared } from "../common";
import PageTitle from "../page-title";
import BillingSettings from "./billing";


@observer
class AccountDetails extends React.Component {
    @observable name = "";
    @observable id = "";
    @observable editName = "";
    @observable editMaxNumbers = 0;
    @observable sid = "";
    @observable logoId = "";
    @observable logoUri = "";
    @observable editSettings: dtos.BillingSettings = null;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        this.refresh((this.props as any).params.accountId);
    }

    async refresh(id) {
        let account = (await serviceClient.get(new dtos.ListAccounts({ specificIds: [id] }))).items[0];
        this.id = account.id;
        this.name = account.name;
        this.editName = account.name;
        this.editMaxNumbers = account.maxPhoneNumbers;
        this.editSettings = account.billingSettings;
        this.sid = account.twilioAccountSid;
        this.logoId = account.logoId;
        this.logoUri = account.logoUri;
    }

    async patchAccount() {
        await serviceClient.patch(new dtos.PatchAccount({
            accountId: this.id,
            name: this.editName,
            maxPhoneNumbers: this.editMaxNumbers,
            billingSettings: this.editSettings
        }));
        this.name = this.editName;
        ui.message.success("Account updated");
    }

    async deleteLogo() {
        await serviceClient.patch(new dtos.PatchAccount({ accountId: this.id, logoId: "" }));
        this.logoId = "";
        this.logoUri = "";
    }

    async onUploadAudioFile(info) {
        if (info.file.status == "done") {
            let f = info.file.response as dtos.FileInfo;
            this.logoId = f.id;
            this.logoUri = f.uri;
            await serviceClient.patch(new dtos.PatchAccount({ logoId: this.logoId, accountId: this.id }))
        }
    }

    async regenerateTokens() {
        await serviceClient.post(new dtos.RegenerateAccountTokens({ accountId: this.id }));
        ui.message.success("The authentication tokens for this account have been regenerated. You will need to reconnect any HostedSuite phone systems and re-open any apps associated with the account.", 3)
    }

    render() {
        return (
            <div>
                <PageTitle title={this.name} />
                <ui.Breadcrumb>
                    <ui.Breadcrumb.Item><Link to="/accounts">Accounts</Link></ui.Breadcrumb.Item>
                    <ui.Breadcrumb.Item>{this.name}</ui.Breadcrumb.Item>
                </ui.Breadcrumb>
                <ui.Typography.Title><i className="fa fa-cabinet-filing" /> {this.name}</ui.Typography.Title>
                <ui.Form layout="vertical" onSubmitCapture={() => this.patchAccount()}>
                    <ui.Tabs animated={false}>
                        <ui.Tabs.TabPane key="general" tab="General">
                            <ui.Form.Item label="Name">
                                <ui.Input value={this.editName} onChange={v => this.editName = v.target.value} />
                            </ui.Form.Item>
                            <ui.Form.Item label="Logo">
                                {this.logoUri && <img src={this.logoUri} />}
                                <br />
                                <ui.Upload accept=".png,.jpg,.jpeg,.bmp" showUploadList={false} name="file" data={{ accountId: this.id }} withCredentials={true} headers={{ "X-Requested-With": null }} action={serviceClient.createUrlFromDto("POST", new dtos.NewFile())} onChange={info => this.onUploadAudioFile(info)}>
                                    <ui.Button type="primary">
                                        <i className="fa fa-upload" /> Upload File
                                    </ui.Button>
                                </ui.Upload>
                                {this.logoId && <ui.Popconfirm title="Delete logo?" onConfirm={() => this.deleteLogo()}><ui.Button style={{ marginLeft: 4 }} danger>Delete</ui.Button></ui.Popconfirm>}
                            </ui.Form.Item>
                            <ui.Form.Item label="Twilio Account Sid">
                                <ui.Input disabled value={this.sid} />
                            </ui.Form.Item>
                            <ui.Form.Item label="Max Phone Numbers">
                                <ui.InputNumber min={0} value={this.editMaxNumbers} onChange={v => this.editMaxNumbers = v} />
                            </ui.Form.Item>
                        </ui.Tabs.TabPane>
                        <ui.Tabs.TabPane key="billing" tab="Billing">
                            <BillingSettings settings={this.editSettings} onChanged={s => this.editSettings = s} />
                            <p><small>Billing totals are calculated by adding the base cost for each billable to the unit price caculation.</small></p>
                            <p><small>The unit price is calculated by taking the raw Twilio cost for each item (e.g. call) * Raw Multiplier and adding that to the Unit Cost.</small></p>
                            <p><small>Allowance allows for a specific number of units to be free before adding the Unit Cost to the total (Allowance does NOT affect the Raw Multiplier).</small></p>

                        </ui.Tabs.TabPane>
                    </ui.Tabs>
                    <ui.Form.Item style={{ marginTop: 8 }}>
                        <ui.Button htmlType="submit" type="primary">Save</ui.Button>
                        <ui.Popconfirm title="Are you sure that you want to regenerate the tokens for this account?" onConfirm={() => this.regenerateTokens()}><ui.Button danger style={{ marginLeft: 20 }}>Regenerate Tokens</ui.Button></ui.Popconfirm>
                    </ui.Form.Item>
                </ui.Form>
            </div>
        );
    }
}

export default withRouter(AccountDetails);