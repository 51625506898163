import AutoComplete from "../autocomplete";
import { React, ui, dtos, serviceClient, observer, observable, shared, _, selectFilter, uuid, splitIntoWords, makeObservable } from "../common";
import FlowValueEditor from "./value-editor";

interface Props {
    value: dtos.Value;
    field: dtos.DataField;
    customerId: string;
    accountId: string;
    onChanged: (newValue: string) => void;
}

@observer
export default class FlowReferenceValue extends React.Component<Props> {
    @observable flowId = "";
    @observable flowParams: dtos.FlowParameter[] = [];
    @observable flowParameterValues: dtos.Struct = {};

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        this.updateValue(this.props.value);
    }

    updateValue(value: dtos.Value) {
        if (value && value.stringValue) {
            try {
                let data = JSON.parse(value.stringValue) || {};
                this.flowId = data.FlowId || "";
                this.flowParameterValues = data.FlowParameters || {};
            } catch { }
        } else {
            this.flowId = "";
            this.flowParameterValues = {};
        }

        this.loadFlow()
    }

    async loadFlow() {
        if (this.flowId) {
            let flows = await serviceClient.get(new dtos.ListFlows({ accountIds: [shared.accountId], specificIds: [this.flowId] }));
            if (flows.items.length === 1) {
                let flow = flows.items[0];
                this.flowParams = flow.parameters.filter(p => p.isPublic);
            }
        } else {
            this.flowParams = [];
        }
    }

    onFlowChanged(id: string) {
        this.flowId = id;
        this.flowParameterValues = {};
        this.flowParams = [];
        this.loadFlow();
        this.props.onChanged(JSON.stringify({ FlowId: this.flowId, FlowParameters: this.flowParameterValues }))
    }

    onValueChanged() {
        this.props.onChanged(JSON.stringify({ FlowId: this.flowId, FlowParameters: this.flowParameterValues }))
    }

    getParamValue(name: string) {
        if (this.flowParameterValues[name]) return this.flowParameterValues[name];
        this.flowParameterValues[name] = new dtos.Value();
        return this.flowParameterValues[name];
    }

    render() {

        let hasTabs = (params: dtos.FlowParameter[]) => _.find(params, p => p.uiTab != null && p.uiTab.length > 0) != null;

        let flowParamEditor = (params: dtos.FlowParameter[], values: dtos.Struct, possibleTabs = true) => {
            if (hasTabs(params) && possibleTabs) {
                let tabNames = [];
                for (let param of params) {
                    if (param.uiTab != null && param.uiTab.length > 0 && tabNames.indexOf(param.uiTab) < 0) {
                        tabNames.push(param.uiTab);
                    }
                }
                return <ui.Tabs tabPosition="left">
                    <ui.Tabs.TabPane key="other" tab="General">
                        {flowParamEditor(params.filter(p => p.uiTab == null || p.uiTab.length == 0), values, false)}
                    </ui.Tabs.TabPane>
                    {tabNames.map((n, i) => <ui.Tabs.TabPane key={"tab-" + i} tab={n}>
                        {flowParamEditor(params.filter(p => p.uiTab == n), values, false)}
                    </ui.Tabs.TabPane>)}
                </ui.Tabs>;
            } else {
                return <div>{params.map(f => <ui.Form.Item key={f.id} label={splitIntoWords(f.name)}>
                    <FlowValueEditor onFieldChanged={() => this.onValueChanged()} customerId={this.props.customerId} accountId={this.props.accountId} value={this.getParamValue(f.name)} field={f} valueType={f.type} />
                </ui.Form.Item>)}</div>;
            }
        };

        return (<div style={{ marginTop: 4, padding: 8, border: "1px solid rgb(217, 217, 217)", borderRadius: "4px" }}>
            <AutoComplete type={dtos.ValueTypes.Flow} typeSpecificFilter="Reference" static={true} value={this.flowId} placeholder={"Select a Flow"} onChanged={v => this.onFlowChanged(v)} />
            {this.flowParams.length > 0 && <div style={{ paddingLeft: 8 }}>
                {flowParamEditor(this.flowParams, this.flowParameterValues, true)}
            </div>}
        </div>)
    }
}