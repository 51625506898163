import { React, ui, dtos, observable, observer, selectFilter, serviceClient, shared, splitIntoWords, makeObservable } from "../common";
import AutoFocus from "../autofocus";
import ValueEditor from "../flows/value-editor";
import AutoComplete from "../autocomplete";

interface Props {
    visible: boolean;
    defaultCustomerId?: string;
    onClosed: () => void;
    onCreated: (cust: dtos.IntegrationInfo) => void;
}

@observer
export default class NewIntegration extends React.Component<Props> {
    @observable name = "";
    @observable type: dtos.IntegrationTypes = dtos.IntegrationTypes.HostedSuite;
    @observable customerId;
    @observable referenceId = "";

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    async componentDidMount() {
        this.customerId = this.props.defaultCustomerId;
    }

    async save(ev: React.FormEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        let request = new dtos.NewIntegration({
            accountId: shared.accountId,
            name: this.name,
            customerId: this.customerId,
            type: this.type
        });
        let newIntegration = await serviceClient.post(request);
        this.props.onCreated(newIntegration);
        this.props.onClosed();
    }

    render() {
        let { visible, onClosed } = this.props;
        return (
            <ui.Modal visible={visible} title="New Customer" onCancel={onClosed} onOk={(ev) => this.save(ev)}>
                <ui.Form layout="vertical" onSubmitCapture={(ev) => this.save(ev)}>
                    <div>
                        <button type="submit" style={{ position: "absolute", top: -9999, left: -9999 }}>Submit</button>
                        <ui.Form.Item label="Name">
                            <AutoFocus visible={visible}>
                                <ui.Input value={this.name} onChange={ev => this.name = ev.target.value} />
                            </AutoFocus>
                        </ui.Form.Item>
                        <ui.Form.Item label="Type">
                            <ui.Select value={this.type} onChange={v => this.type = v} style={{width:300}}>
                                {Object.keys(dtos.IntegrationTypes).map(k => <ui.Select.Option value={k} key={k}>{splitIntoWords(k)}</ui.Select.Option>)}
                            </ui.Select>
                        </ui.Form.Item>
                        <ui.Form.Item label="Customer">
                            <AutoComplete type={dtos.ValueTypes.Customer} value={this.customerId} onChanged={v => this.customerId = v} placeholder="Search for a parent customer" />
                        </ui.Form.Item>
                    </div>
                </ui.Form>
            </ui.Modal>
        );
    }
}