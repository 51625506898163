import { React, serviceClient, dtos, observer, observable, ui, _, Link, Observer, shared, moment, selectFilter, splitIntoWords, makeObservable, cache, getFlowRoles } from "../common";
import AccountHeader from "../account-header";
import AutoFocus from "../autofocus";
import PageTitle from "../page-title";
import NewCustomer from "../customers/new";
import TagsEditor from "../tags-editor";
import AutoComplete from "../autocomplete";
import ValueEditor from "./value-editor";


@observer
export default class FlowIndex extends React.Component {
    @observable flows: Array<dtos.FlowInfo> = [];
    @observable pagination: any = { position: "both" };
    @observable sort: any = {};
    @observable filter: any = {};
    @observable timeZones: Array<dtos.SystemTimeZoneInfo> = [];
    @observable showNewFlow = false;
    @observable newFlowName = "";
    @observable newFlowRoles: dtos.FlowRoles[] = [];
    @observable newFlowCustomerId = "";
    @observable selectedFlowIds: Array<string> = [];
    @observable loading = true;
    @observable pageSize = 20;
    @observable showNewCustomer = false;
    @observable assignToCustomerId = "";
    @observable assignToTagId = "";
    @observable allTags: Array<dtos.Tag> = [];
    @observable tags: Array<dtos.Tag> = [];
    @observable showExportPackage = false;
    @observable exportPackageJson = "";
    @observable showImportPackage = false;
    @observable importPackageJson = "";
    @observable nodeTypes: Array<dtos.FlowNodeSpec> = [];

    constructor(props) {
        super(props);
        makeObservable(this);
        this.filter.name = localStorage.getItem("FlowIndex.filter.name");
        this.filter.customerId = localStorage.getItem("FlowIndex.filter.customerId");
        this.filter.nodeTypeFilter = localStorage.getItem("FlowIndex.filter.nodeType");
        this.filter.type = localStorage.getItem("FlowIndex.filter.type");

        this.pageSize = parseInt(localStorage.getItem("FlowIndex.pagination.pageSize") || "20");
        var tagJson = localStorage.getItem("FlowIndex.filter.tags");
        this.tags = tagJson ? JSON.parse(tagJson) : [];
    }

    async componentDidMount() {
        let systemSettings = await cache.getSystemSettings();
        this.allTags = systemSettings.tags;
        this.nodeTypes = await (serviceClient.get(new dtos.ListAvailableNodes()));
        this.refresh();
    }

    async refresh(page = this.pagination.page || 1, sort = this.sort) {
        this.loading = true;
        let request = new dtos.ListFlows({
            accountIds: [shared.accountId],
            customerIds: this.filter.customerId ? [this.filter.customerId] : null,
            countPerPage: this.pageSize,
            nameFilter: this.filter.name,
            nodeTypeFilter: this.filter.nodeTypeFilter,
            page: page - 1,
            sortField: sort.field,
            sortOrder: sort.order,
            role: this.filter.role,
            tagIds: this.tags.length > 0 ? this.tags.map(t => t.id) : null,
            simplifiedPaging: true
        });

        let response = await serviceClient.get(request);
        let currentlySelected = this.flows.filter(ep => this.selectedFlowIds.indexOf(ep.id) >= 0);
        this.flows = [...currentlySelected, ...response.items.filter(f => this.selectedFlowIds.indexOf(f.id) < 0)];

        let pager = { ...this.pagination };
        pager.current = page;
        pager.total = page * this.pageSize + (response.hasMorePages ? 1 : 0);
        pager.pageSize = this.pageSize;
        pager.showSizeChanger = true;
        pager.pageSizeOptions = ["10", "20", "50", "100", "250"];
        pager.onShowSizeChange = (c, v) => this.pageSize = v;

        this.sort = sort;
        this.pagination = pager;
        this.loading = false;

        if (this.filter.customerId) {
            this.newFlowCustomerId = this.filter.customerId;
        }

        localStorage.setItem("FlowIndex.filter.name", this.filter.name || "");
        localStorage.setItem("FlowIndex.filter.customerId", this.filter.customerId || "");
        localStorage.setItem("FlowIndex.pagination.pageSize", this.pageSize.toString());
        localStorage.setItem("FlowIndex.filter.tags", JSON.stringify(this.tags));
        localStorage.setItem("FlowIndex.filter.nodeType", this.filter.nodeTypeFilter || "");
        localStorage.setItem("FlowIndex.filter.type", this.filter.type || "");
    }

    async newFlow(ev: React.FormEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        let newFlow = await serviceClient.post(new dtos.NewFlow({
            accountId: shared.accountId,
            name: this.newFlowName,
            roles: this.newFlowRoles,
            customerId: this.newFlowCustomerId
        }));
        ui.message.success("Flow created");
        window.location.hash = "/flows/" + newFlow.id;
    }

    async deleteFlows() {
        this.loading = true;
        for (let flowId of this.selectedFlowIds) {
            await serviceClient.delete(new dtos.DeleteFlow({ flowId: flowId }));
        }
        this.selectedFlowIds = [];
        this.refresh();
        this.loading = false;
    }

    async copyFlows() {
        this.loading = true;
        for (let flowId of this.selectedFlowIds) {
            await serviceClient.post(new dtos.CopyFlow({ flowId: flowId }));
        }
        this.selectedFlowIds = [];
        this.refresh();
        this.loading = false;
    }

    async assignToCustomer() {
        this.loading = true;
        for (let id of this.selectedFlowIds) {
            let updated = await serviceClient.patch(new dtos.PatchFlow({
                flowId: id,
                customerId: this.assignToCustomerId
            }));
            Object.assign(_.find(this.flows, ep => ep.id == id), updated);
        }
        this.selectedFlowIds = [];
        this.refresh();
    }

    async assignToTag() {
        this.loading = true;
        for (let id of this.selectedFlowIds) {
            let flow = _.find(this.flows, e => e.id == id);
            let tagIds = flow.tags.map(t => t.id);
            if (tagIds.indexOf(this.assignToTagId) < 0) {
                tagIds.push(this.assignToTagId);
            }
            let updated = await serviceClient.patch(new dtos.PatchFlow({
                flowId: id,
                tagIds: tagIds
            }));
            Object.assign(_.find(this.flows, ep => ep.id == id), updated);

        }
        this.selectedFlowIds = [];
        this.refresh();
    }

    async removeFromTag() {
        this.loading = true;
        for (let id of this.selectedFlowIds) {
            let flow = _.find(this.flows, e => e.id == id);
            let tagIds = flow.tags.map(t => t.id);
            let idx = tagIds.indexOf(this.assignToTagId);
            if (idx >= 0) {
                tagIds.splice(idx, 1);
                let updated = await serviceClient.patch(new dtos.PatchFlow({
                    flowId: id,
                    tagIds: tagIds
                }));
                Object.assign(_.find(this.flows, ep => ep.id == id), updated);

            }
        }
        this.selectedFlowIds = [];
        this.refresh();
    }

    checkFilterSubmit(ev: React.KeyboardEvent) {
        if (ev.which == 13) {
            this.refresh();
        }
    }

    onCustomerCreated(cust: dtos.CustomerInfo) {
        this.newFlowCustomerId = cust.id;
        this.showNewCustomer = false;
    }

    async setDirty(flow: dtos.FlowInfo) {
        await serviceClient.patch(new dtos.PatchFlow({
            flowId: flow.id,
            parameters: flow.parameters
        }));
        ui.message.success("Updated successfully");
    }

    async importPackage() {
        try {
            let pkg = JSON.parse(this.importPackageJson) as dtos.PackageInfo;
            await serviceClient.post(new dtos.ImportPackage({ accountId: shared.accountId, package: pkg }));
            ui.message.success("Import successful");
            this.refresh();
        } catch (err) {
            if (err.responseStatus) {
                // ui.message.error(err.responseStatus.message);
            } else {
                ui.message.error(err);
            }
        }
    }

    async exportPackage() {
        let pkg = await serviceClient.get(new dtos.ExportPackage({ accountId: shared.accountId, flowIds: this.selectedFlowIds }));
        this.exportPackageJson = JSON.stringify(pkg, null, "\t");
        this.showExportPackage = true;
    }

    copyExportText() {
        var copyText = document.getElementById("package-export") as HTMLTextAreaElement;
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        document.execCommand("copy");
        ui.message.success("Copied to clipboard");
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.selectedFlowIds,
            onChange: keys => this.selectedFlowIds = keys
        };

        return (
            <div>
                <PageTitle title="Flows" />
                <AccountHeader title="Flows" icon="fa fa-water" />
                <ui.Button.Group style={{ marginBottom: 16 }}>
                    <ui.Button type="primary" onClick={() => this.showNewFlow = true}>New Flow</ui.Button>
                </ui.Button.Group>
                {this.selectedFlowIds.length > 0 && <ui.Button.Group style={{ marginLeft: 16 }}>
                    <ui.Popconfirm title="Are you sure you want to copy the selected flows?" onConfirm={() => this.copyFlows()}><ui.Button type="default">Copy Flows</ui.Button></ui.Popconfirm>
                    <ui.Popconfirm title="Are you sure you want to delete the selected flows?" onConfirm={() => this.deleteFlows()}><ui.Button danger>Delete Flows</ui.Button></ui.Popconfirm>
                </ui.Button.Group>}
                {!shared.isCustomer() && <ui.Button.Group style={{ marginLeft: 16 }}>
                    <ui.Button onClick={() => { this.showImportPackage = true; this.importPackageJson = "" }}>Import Package</ui.Button>
                    {this.selectedFlowIds.length > 0 && <ui.Button onClick={() => this.exportPackage()}>Export Package</ui.Button>}
                </ui.Button.Group>}
                <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    <ui.Form.Item>
                        <ui.Input onKeyPress={ev => this.checkFilterSubmit(ev)} placeholder="Filter by name" value={this.filter.name || ""} onChange={ev => this.filter.name = ev.target.value} />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <AutoComplete type={dtos.ValueTypes.Customer} includeNotAssigned={!shared.isCustomer()} value={this.filter.customerId} onSearch={() => this.refresh()} onChanged={v => this.filter.customerId = v} placeholder="Filter by customer" />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Select onInputKeyDown={ev => this.checkFilterSubmit(ev)} showSearch filterOption={selectFilter} style={{ width: 175 }} dropdownMatchSelectWidth={false} placeholder="Filter by role" value={this.filter.role || undefined} onChange={v => this.filter.role = v}>
                            {getFlowRoles().map(t => <ui.Select.Option key={t} value={t}>{splitIntoWords(t)}</ui.Select.Option>)}
                        </ui.Select>
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <TagsEditor placeholder="Filter by tag" tags={this.tags} />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Select style={{ width: 200 }} placeholder="Filter by node" showSearch filterOption={selectFilter} value={this.filter.nodeTypeFilter || undefined} onChange={v => { this.filter.nodeTypeFilter = v; this.forceUpdate() }}>
                            {_.sortBy(this.nodeTypes, nt => nt.name).map(nt => <ui.Select.Option value={nt.name} key={nt.url}><i className={nt.iconClass} /> {nt.name}</ui.Select.Option>)}
                        </ui.Select>
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="primary" onClick={() => this.refresh()}>Search</ui.Button>
                            <ui.Button type="default" onClick={() => { this.filter = {}; this.selectedFlowIds = []; this.tags = []; this.refresh(); }}>Clear</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>
                </ui.Form>
                <ui.Form layout="inline">
                    {this.selectedFlowIds.length > 0 &&
                        <React.Fragment>
                            <ui.Form.Item>
                                <ui.Input.Group compact>
                                    <AutoComplete type={dtos.ValueTypes.Customer} value={this.assignToCustomerId} onChanged={v => this.assignToCustomerId = v} placeholder="Assign to customer" />
                                    <ui.Popconfirm title="Assign this customer to all of the selected endpoints?" onConfirm={() => this.assignToCustomer()}><ui.Button type="default" disabled={!this.assignToCustomerId}><i className="fa fa-save" /></ui.Button></ui.Popconfirm>
                                </ui.Input.Group>
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Input.Group compact>
                                    <ui.Select showSearch filterOption={selectFilter} style={{ width: 175 }} dropdownMatchSelectWidth={false} placeholder="Assign/Remove Tag" value={this.assignToTagId || undefined} onChange={v => this.assignToTagId = v}>
                                        {this.allTags.map(c => <ui.Select.Option key={c.id} value={c.id}>{c.name}</ui.Select.Option>)}
                                    </ui.Select>
                                    <ui.Button.Group>
                                        <ui.Popconfirm title="Assign this tag to all of the selected flows?" onConfirm={() => this.assignToTag()}><ui.Button title="Assign to tag" type="default" disabled={!this.assignToTagId}><i className="fa fa-save" /></ui.Button></ui.Popconfirm>
                                        <ui.Popconfirm title="Remove this tag from all of the selected flows?" onConfirm={() => this.removeFromTag()}><ui.Button title="Remove from tag" type="default" disabled={!this.assignToTagId}><i className="fa fa-times" /></ui.Button></ui.Popconfirm>
                                    </ui.Button.Group>
                                </ui.Input.Group>
                            </ui.Form.Item>
                        </React.Fragment>}
                </ui.Form>
                <ui.Table className="hide-pagination-buttons" loading={this.loading} rowSelection={rowSelection} dataSource={this.flows} rowKey="id" pagination={this.pagination} onChange={(p, f, s) => this.refresh(p.current, s)}>
                    <ui.Table.Column title="Name" dataIndex="name" render={(text, rec: dtos.FlowInfo) => <>
                        <Link to={`/flows/${rec.id}`}>{rec.name}</Link>
                        {rec.roles.length > 0 && <small style={{ marginLeft: 4 }}>({rec.roles.map(splitIntoWords).join(", ")})</small>}
                    </>} />

                    <ui.Table.Column dataIndex="customerBreadcrumb" title="Customer" render={(text, rec: dtos.FlowInfo) =>
                        <ui.Breadcrumb>
                            {rec.customerBreadcrumb.map((bc, i) => <ui.Breadcrumb.Item key={bc.id}><Link to={`/customers/${bc.id}`}>{bc.name}</Link></ui.Breadcrumb.Item>)}
                        </ui.Breadcrumb>
                    } />
                    <ui.Table.Column dataIndex="parameters" title="Settings" render={(text, rec: dtos.FlowInfo) =>
                        <ui.Form layout="inline" className="knobs">
                            {rec.parameters.filter(p => p.isKnob).map(p =>
                                <ui.Form.Item label={splitIntoWords(p.name)} key={p.id}>
                                    <ValueEditor accountId={shared.accountId} field={p} valueType={p.type} value={p.defaultValue} onFieldChanged={() => this.setDirty(rec)} />
                                </ui.Form.Item>)}
                        </ui.Form>} />
                    <ui.Table.Column title="Tags" render={(text, rec: dtos.FlowInfo) =>
                        <div>
                            {rec.tags.map(t => <ui.Tag key={t.id} color={t.color.toLowerCase()}>{t.name}</ui.Tag>)}
                        </div>} />
                    <ui.Table.Column title="Notes" dataIndex="notes" />
                    <ui.Table.Column title="Last Modified" render={(text, rec: dtos.FlowInfo) => <span>{moment(rec.dateLastModified).format("LLLL")} by {rec.lastModifiedBy}</span>} />
                </ui.Table>
                <ui.Modal title="New Flow" visible={this.showNewFlow} onOk={(ev) => this.newFlow(ev)} onCancel={() => this.showNewFlow = false} okText="Save">
                    <ui.Form layout="vertical" onSubmitCapture={(ev) => this.newFlow(ev)}>
                        <button type="submit" style={{ position: "absolute", top: -9999, left: -9999 }}>Submit</button>
                        <ui.Form.Item label="Name">
                            <AutoFocus visible={this.showNewFlow}>
                                <ui.Input value={this.newFlowName} onChange={v => this.newFlowName = v.target.value} />
                            </AutoFocus>
                        </ui.Form.Item>
                        <ui.Form.Item label="Roles">
                            <ui.Select mode="tags" value={this.newFlowRoles} onChange={ev => this.newFlowRoles = ev}>
                                {getFlowRoles().map(t => <ui.Select.Option key={t} value={t}>{splitIntoWords(t)}</ui.Select.Option>)}
                            </ui.Select>
                        </ui.Form.Item>
                        <ui.Form.Item label="Customer">
                            <ui.Input.Group compact>
                                <AutoComplete type={dtos.ValueTypes.Customer} value={this.newFlowCustomerId} onChanged={v => this.newFlowCustomerId = v} placeholder="Select a customer" />
                            </ui.Input.Group>
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>
                <ui.Modal title="Export Package" visible={this.showExportPackage} cancelButtonProps={{ hidden: true }} okText="Done" onCancel={() => this.showExportPackage = false} onOk={() => this.showExportPackage = false}>
                    <p>Copy the following text and paste it into the Import window on another account</p>
                    <ui.Input.TextArea id="package-export" value={this.exportPackageJson} readOnly rows={15} />
                    <p><a onClick={() => this.copyExportText()}>Copy to Clipboard</a></p>
                </ui.Modal>

                <ui.Modal title="Import Package" visible={this.showImportPackage} okButtonProps={{ disabled: !this.importPackageJson }} okText="Import" onCancel={() => this.showImportPackage = false} onOk={() => this.importPackage()}>
                    <p>Paste the export text below and then click Import</p>
                    <ui.Input.TextArea spellCheck={false} rows={15} value={this.importPackageJson} onChange={ev => this.importPackageJson = ev.target.value} />
                </ui.Modal>
                <NewCustomer visible={this.showNewCustomer} onClosed={() => this.showNewCustomer = false} onCreated={cust => this.onCustomerCreated(cust)} />

            </div>
        );
    }
}