import { useEffect, useState } from "react";
import { React, serviceClient, dtos, observer, shared, observable, ui, Link, selectFilter, _, splitIntoWords, uuid, moveArray, removeArray } from "../common";
import CustomerList from "../customers/list";
import { CrmContactInfo, CrmCustomerInfo, CustomerInfo, EntityIntegrationData, IntegrationData, IntegrationInfo, ListCrmContacts, ListCrmCustomers, MapCrmCustomer } from "../dtos";
import EndpointList from "../endpoints/list";

interface Props {
    customerId: string;
    integrationId: string;
}

function CrmCustomerMapper(props: { integrationId: string, customer: CustomerInfo, crmCustomers: CrmCustomerInfo[] }) {
    const { customer, crmCustomers, integrationId } = props;
    const [oldId, setOldId] = useState(customer.integrationData[integrationId]?.thirdPartyId || "");
    const [id, setId] = useState(customer.integrationData[integrationId]?.thirdPartyId || "");

    function undo() {
        setId(oldId);
    }

    function save() {
        serviceClient.post(new MapCrmCustomer({
            integrationId,
            customerId: customer.id,
            mapsToId: id
        })).then(function(r) {
            setOldId(id);
            ui.message.success("Saved");
        });
    }

    return <ui.Space>
        <ui.Select showSearch filterOption={selectFilter} value={id} onChange={setId} dropdownMatchSelectWidth style={{width:300}}>
            <ui.Select.Option value="">(None)</ui.Select.Option>
            {crmCustomers.map(c => <ui.Select.Option value={c.id} key={c.id}>{c.displayName}</ui.Select.Option>)}
        </ui.Select>
        {oldId != id && <ui.Button onClick={save} type="primary">Save</ui.Button>}
        {oldId != id && <ui.Button onClick={undo}>Undo</ui.Button>}
    </ui.Space>;
}

export function CrmCustomerMapping(props: Props) {
    const [crmCustomers, setCrmCustomers] = useState<CrmCustomerInfo[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(function loadClients() {
        setLoading(true);
        serviceClient.get(new ListCrmCustomers({ integrationId: props.integrationId })).then(function (r) {
            setCrmCustomers(r.customers);
        }).finally(function() {
            setLoading(false);
        });
    }, []);

    return <>
        <CustomerList>
            <ui.Table.Column title="Maps to" render={(text, rec: dtos.CustomerInfo) => <>
                {loading && <ui.Spin />} <CrmCustomerMapper integrationId={props.integrationId} crmCustomers={crmCustomers} customer={rec} />
            </>} />
        </CustomerList>
    </>
}
