import { React, ui, dtos, serviceClient, observer, observable, uuid, _, shared, makeObservable, selectFilter } from "../common";
import FlowCanvas from "./canvas";
import FlowCanvasContainer from "./canvas-container";
import FlowToolbox from "./toolbox";
import FlowProperties from "./properties";
import FlowCanvasNode from "./node";
import FlowUtil from "./util";
import FlowExit from "./exit";

interface Props {
    flow: dtos.FlowInfo,
}

@observer
export default class FlowDesigner extends React.Component<Props> {
    canvas = React.createRef<FlowCanvas>();
    canvasNodes: Array<FlowCanvasNode> = [];
    keyboardEvent: (ev: KeyboardEvent) => void;
    insertRef = React.createRef<any>();

    @observable exits: Array<dtos.FlowExit> = [];
    @observable nodes: Array<dtos.FlowNode> = [];
    @observable selectedNode: dtos.FlowNode;
    @observable selectedExit: dtos.FlowExit;
    @observable activeTab = "toolbox";
    @observable ui: dtos.FlowUI;
    @observable nodeSpecs: Array<dtos.FlowNodeSpec> = [];
    @observable showInsertNode = false;
    @observable insertNodeId = "";
    @observable loading = true;
    @observable saving = false;

    mouseWheelListener = null;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        let specs = await serviceClient.get(new dtos.ListAvailableNodes({ flowId: this.props.flow.id }));
        (this.nodeSpecs as any).replace(specs);
        for (let node of this.props.flow.nodes) {
            let spec = _.find(this.nodeSpecs, s => s.url == node.spec.url);
            if (spec != null) {
                node.spec.dataType = spec.dataType;
                FlowUtil.initStruct(node.parameters, spec.dataType.fields, node.spec.dataType);
            }
        }

        this.ui = this.props.flow.ui;
        this.nodes = this.props.flow.nodes;
        this.exits = this.props.flow.exits;
        let selectedNode = _.find(this.nodes, n => n.id == this.ui.selectedNode);
        if (selectedNode != null) {
            this.onNodeClick(selectedNode.id);
        }
        this.keyboardEvent = ev => this.onKeyUp(ev);
        if (this.nodes.length == 0) {
            this.loading = false;
        }
        document.addEventListener("keydown", this.keyboardEvent);
        this.mouseWheelListener = ev => this.onMouseWheel(ev);
        document.addEventListener("wheel", this.mouseWheelListener);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyboardEvent);
        document.removeEventListener("mousewheel", this.mouseWheelListener);
    }

    onMouseWheel(ev: WheelEvent) {
        if (!(ev.target as HTMLElement).classList.contains("pan-container")) return;
        var amount = ev.deltaY * -.001;
        var newZoom = _.clamp(this.ui.canvasZoom + amount, .1, 1);
        this.ui.canvasZoom = newZoom;
    }

    onKeyUp(ev: KeyboardEvent) {
        if (ev.which == 0x20 && ev.ctrlKey && !this.showInsertNode) {
            this.insertNodeId = "";
            this.showInsertNode = true;
            setTimeout(() => {
                this.insertRef.current.focus(); //.rcSelect.setOpenState(true, true);
            }, 100);
        } else if (ev.which == 0x53 && ev.ctrlKey) {
            this.save();
            ev.preventDefault();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.flow != prevProps.flow) {
            this.nodes = this.props.flow.nodes;
        }
    }

    addExit(name: string): boolean {
        if (!name) {
            ui.message.error("Must specify a name for this exit");
            return false;
        }

        if (_.find(this.props.flow.parameters, p => p.name == name)) {
            ui.message.error("A parameter with this name already exists");
            return false;
        }

        if (_.find(this.exits, p => p.name == name)) {
            ui.message.error("An exit with this name already exists");
            return false;
        }

        let newExit = new dtos.FlowExit({
            id: uuid(),
            name: name,
            ui: new dtos.FlowNodeUI({ x: 100, y: 100 })
        });
        this.exits.push(newExit);
        this.onExitClick(newExit);
        return true;
    }

    removeExit(exit: dtos.FlowExit) {
        this.canvas.current.deleteExit(exit.name);
        let idx = _.findIndex(this.exits, ex => ex.name == exit.name);
        if (idx < 0) return;
        this.exits.splice(idx, 1);
    }

    addNode(n: dtos.FlowNodeSpec) {
        let newNode = new dtos.FlowNode();
        newNode.id = uuid();
        newNode.parameters = new dtos.NodeParameterMap();
        newNode.isStartNode = false;
        newNode.spec = n;

        let container = document.getElementsByClassName("pan-container")[0].firstChild as HTMLDivElement;
        let transform = container.style.transform;
        let x = 100;
        let y = 100;
        if (transform.startsWith("matrix(")) {
            let parsed = transform.substring("matrix(".length, transform.length - 1);
            let tokens = parsed.split(",");
            let xOffset = parseInt(tokens[4]);
            let yOffset = parseInt(tokens[5]);
            x -= xOffset;
            y -= yOffset;
        }
        newNode.ui = new dtos.FlowNodeUI({ x: x, y: y, notes: "" });
        FlowUtil.initStruct(newNode.parameters, n.dataType.fields, n.dataType);
        this.nodes.push(newNode);
        this.selectedNode = newNode;
        this.onNodeClick(newNode.id);
    }

    copyNode(n: dtos.FlowNode) {
        var newNode = JSON.parse(JSON.stringify(n));
        newNode.id = uuid();
        newNode.isStartNode = false;

        let container = document.getElementsByClassName("pan-container")[0].firstChild as HTMLDivElement;
        let transform = container.style.transform;
        let x = 100;
        let y = 100;
        if (transform.startsWith("matrix(")) {
            let parsed = transform.substring("matrix(".length, transform.length - 1);
            let tokens = parsed.split(",");
            let xOffset = parseInt(tokens[4]);
            let yOffset = parseInt(tokens[5]);
            x -= xOffset;
            y -= yOffset;
        }
        newNode.ui = new dtos.FlowNodeUI({ x: x, y: y, notes: "" });
        function recurseClearTransitions(params: dtos.NodeParameterMap, fields: dtos.DataField[]) {
            for (let field of fields) {
                var val = params[field.name] as dtos.NodeParameter;
                if (field.type == dtos.ValueTypes.Transition) {
                    console.log("clearning", field.name);
                    val.id = uuid();
                    val.value.stringValue = "";
                } else if (field.type == dtos.ValueTypes.Struct && val.structParameters != null) {
                    recurseClearTransitions(val.structParameters, field.structType.fields);
                } else if (field.type == dtos.ValueTypes.List && val.listParameters != null && val.listParameters.length > 0) {
                    for (let listParams of val.listParameters) {
                        recurseClearTransitions(listParams, field.listType.fields);
                    }
                }
            }
        }
        recurseClearTransitions(newNode.parameters, newNode.spec.dataType.fields);

        console.log("copied from", n, newNode);
        this.nodes.push(observable.object(newNode));
        this.selectedNode = newNode;
        this.onNodeClick(newNode.id);
    }

    insertNode(url: string) {
        const spec = _.find(this.nodeSpecs, s => s.url == url);
        if (spec == null) return;
        this.insertNodeId = url;
        this.addNode(spec);
        this.showInsertNode = false;
    }

    getNode(nodeId): dtos.FlowNode {
        return _.find(this.nodes, n => n.id == nodeId);
    }

    onNodeClick(nodeId: string) {
        if (!nodeId) {
            this.selectedNode = null;
            if (this.activeTab == "props") {
                this.activeTab = "toolbox";
            }
        } else {
            this.selectedNode = this.getNode(nodeId);
            this.selectedExit = null;
            this.activeTab = "props";
        }
    }

    onExitClick(exit: dtos.FlowExit) {
        this.selectedNode = null;
        this.selectedExit = exit;
    }

    onStartDrag(nodeId) {
        if (nodeId.startsWith("exit-")) {
            let exit = _.find(this.exits, ex => "exit-" + ex.name == nodeId);
            this.onExitClick(exit);
        } else {
            this.onNodeClick(nodeId);
        }
    }
    onNodeDrag(nodeId: string, x: number, y: number) {
        if (nodeId.startsWith("exit-")) {
            let exit = _.find(this.exits, ex => "exit-" + ex.name == nodeId);
            exit.ui.x = x;
            exit.ui.y = y;
        } else {
            let node = this.getNode(nodeId);
            node.ui.x = x;
            node.ui.y = y;
        }
    }

    async save() {
        this.saving = true;
        try {
            this.ui.selectedNode = this.selectedNode == null ? "" : this.selectedNode.id;
            let request = new dtos.PatchFlow();
            request.flowId = this.props.flow.id;
            request.nodes = this.nodes;
            request.parameters = this.props.flow.parameters;
            request.exits = this.exits;
            request.ui = this.ui;
            let updated = await serviceClient.patch(request);
            ui.message.success("Flow saved successfully");
            this.props.flow.parameters = updated.parameters;

        } finally {
            this.saving = false;
        }
    }

    findTransition(id): { node: dtos.FlowNode, value: dtos.Value } {
        for (let node of this.nodes) {
            let transitions = FlowUtil.getTransitions(node.parameters, node.spec.dataType);
            for (let trans of transitions) {
                if (trans.param.id == id) {
                    return { node: node, value: trans.param.value }
                }
            }
        }
        return null;
    }

    onConnect(sourceId: string, targetId: string, userEvent: boolean) {
        if (targetId.startsWith("exit-")) {
            let exitName = targetId.substr("exit-".length);
            let exit = _.find(this.exits, ex => ex.name == exitName);
            let trans = this.findTransition(sourceId);
            trans.value.stringValue = exit.name;
            return;
        }

        var target = this.getNode(targetId);
        if (!target) {
            alert("Could not find target node");
            return;
        }

        if (sourceId == "start") {
            target.isStartNode = true;
        } else {
            let trans = this.findTransition(sourceId);
            trans.value.stringValue = targetId;
        }

        if (userEvent) {
            setTimeout(() => this.onNodeClick(targetId), 25);
        }
    }

    onDisconnect(sourceId, targetId) {
        if (targetId.startsWith("exit-")) {
            let trans = this.findTransition(sourceId);
            trans.value.stringValue = "";
            return;
        }

        var target = this.getNode(targetId);
        if (!target) {
            alert("Could not find target node");
            return;
        }

        if (sourceId == "start") {
            target.isStartNode = false;
        } else {
            let trans = this.findTransition(sourceId);
            trans.value.stringValue = "";
        }
    }

    deleteNode(node: dtos.FlowNode) {
        let canvasNodeIndex = _.findIndex(this.canvasNodes, n => n.props.node.id == node.id);
        if (canvasNodeIndex >= 0) {
            this.canvasNodes.splice(canvasNodeIndex, 1);
        }

        let idx = _.findIndex(this.nodes, n => n.id == node.id);
        if (idx < 0) return;
        this.nodes.splice(idx, 1);
        this.selectedNode = null;
    }

    addCanvasNode(n: FlowCanvasNode) {
        if (n == null) return;
        this.canvasNodes.push(n);
        if (this.canvasNodes.length == this.nodes.length) {
            this.loading = false;
        }
    }

    onNodeChanged(node: dtos.FlowNode) {
        let canvasNode = _.find(this.canvasNodes, n => n.props.node.id == node.id);
        if (!canvasNode) return;
        canvasNode.refreshTransitions();
    }

    setNotes(n: string) {
        this.selectedNode.ui.notes = n;
        this.forceUpdate();
    }

    selectTab(t: string) {
        this.activeTab = t;
    }

    render() {
        var min = !shared.collapsed;

        return (
            <ui.Row gutter={16}>
                <ui.Col span={15}>
                    {this.loading && <div style={{ textAlign: "center", paddingTop: 100 }}><ui.Spin size="default" /></div>}

                    <div style={{ visibility: this.loading ? "hidden" : "visible" }}>
                        <FlowCanvasContainer ui={this.props.flow.ui}>
                            <FlowCanvas zoom={this.props.flow.ui.canvasZoom} ref={this.canvas} hasStartNode={_.find(this.nodes, n => n.isStartNode) != null} onConnect={(s, t, ue) => this.onConnect(s, t, ue)} onDisconnect={(s, t) => this.onDisconnect(s, t)} onStartDrag={nodeId => this.onStartDrag(nodeId)} onDrag={(nodeId, x, y) => this.onNodeDrag(nodeId, x, y)} flow={this.props.flow} onClick={() => this.onNodeClick(null)}>
                                {this.nodes.map(n => <FlowCanvasNode ref={n => this.addCanvasNode(n)} onCopy={() => this.copyNode(n)} onDelete={() => this.deleteNode(n)} selected={n == this.selectedNode} onClick={() => this.onNodeClick(n.id)} key={n.id} node={n} canvas={this.canvas.current} />)}
                                {this.exits.map(ex => <FlowExit exit={ex} canvas={this.canvas.current} onDelete={() => this.removeExit(ex)} selected={ex == this.selectedExit} onClick={() => this.onExitClick(ex)} key={ex.id} />)}
                            </FlowCanvas>
                        </FlowCanvasContainer>
                    </div>
                    <ui.Button disabled={this.saving} type="primary" onClick={ev => this.save()} shape="round" size="large" style={{ position: "absolute", right: 50, bottom: 50 }}><span>{!this.saving && <i className="fa fa-cloud-upload" />}{this.saving && <i className="fa fa-spin fa-hourglass" />} Save</span></ui.Button>
                    <ui.Slider style={{ position: "absolute", left: 50, bottom: 50, width: 150 }} min={.1} max={1} step={.1} tipFormatter={n => Math.round(n * 100).toString() + "%"} value={this.props.flow.ui.canvasZoom || 1} onChange={ev => this.props.flow.ui.canvasZoom = ev as number} />
                    <p style={{ position: "absolute", left: 50, bottom: 10 }}><small><strong>Ctrl+Space</strong> show insert node box &nbsp;&nbsp;<strong>Ctrl+S</strong> Save flow</small></p>
                </ui.Col>
                <ui.Col span={9}>
                    <ui.Tabs activeKey={this.activeTab} animated={false} type="card" onTabClick={t => this.selectTab(t)} className="flow-tabs">
                        <ui.Tabs.TabPane key="toolbox" tab="Toolbox">
                            <FlowToolbox showExits={true} nodeSpecs={this.nodeSpecs} flowId={this.props.flow.id} onAddNode={n => this.addNode(n)} onAddExit={name => this.addExit(name)} />
                        </ui.Tabs.TabPane>
                        <ui.Tabs.TabPane key="props" tab="Properties">
                            {this.selectedNode && <FlowProperties customerId={this.props.flow.customerId} nodeType={this.selectedNode.spec.name} restrictToChannels={this.selectedNode.spec.restrictToChannels} documentationUrl={this.selectedNode.spec.documentationUrl} notes={this.selectedNode.ui.notes} onNotesChanged={v => this.setNotes(v)} exits={this.exits} flowId={this.props.flow.id} accountId={this.props.flow.accountId} canvas={this.canvas.current} flowParameters={this.props.flow.parameters} parameters={this.selectedNode.parameters} dataType={this.selectedNode.spec.dataType} onFieldsChanged={() => this.onNodeChanged(this.selectedNode)} />}
                        </ui.Tabs.TabPane>
                    </ui.Tabs>
                </ui.Col>
                <ui.Modal centered visible={this.showInsertNode} onCancel={() => this.showInsertNode = false} footer={null} title={null}>
                    <ui.Form layout="vertical">
                        <ui.Form.Item>
                            <ui.Select ref={this.insertRef} placeholder="Search for a node" showSearch optionFilterProp="children" value={this.insertNodeId || null} onChange={url => this.insertNode(url)}>
                                {this.nodeSpecs.map(s => <ui.Select.Option key={s.url} value={s.url}><i className={s.iconClass} /> {s.name}</ui.Select.Option>)}
                            </ui.Select>
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>
            </ui.Row>
        );
    }
}