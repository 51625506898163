import { React, serviceClient, dtos, observer, shared, observable, ui, Link, selectFilter, _, splitIntoWords, uuid, moment, makeObservable } from "../common";

interface Props {
    schedule: dtos.Schedule,
    customerId: string,
    endpointId: string,
    parentCustomerId: string
    allStatesCallback?: (s: string[]) => void;
}

@observer
export default class ScheduleEditor extends React.Component<Props>{
    @observable customerId = "";
    @observable parentCustomerId = "";
    @observable endpointId = "";
    @observable timeZones: dtos.SystemTimeZoneInfo[] = [];
    @observable timeZoneId = "";
    @observable rules: Array<dtos.SchedulingRule> = [];
    @observable editDefaultState = "";
    @observable inherit = false;
    @observable inheritedSchedule: dtos.Schedule = null;
    @observable hideInherited = false;
    @observable inheritedTimeZone = "";
    @observable inheritedRules: dtos.SchedulingRule[] = [];
    @observable testDate = moment();
    @observable forceClosed = false;
    @observable showInheritedHolidays = false;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        this.timeZones = await serviceClient.get(new dtos.ListSystemTimeZones());
        this.load(this.props);
        this.hideInherited = localStorage.getItem("Schedule.hideInherited") == "true";
        this.showInheritedHolidays = localStorage.getItem("Schedule.hideInheritedHolidays") == "false";
        let testDate = localStorage.getItem("Schedule.testDate");
        if (testDate) {
            this.testDate = moment(testDate);
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.customerId != prevProps.customerId || this.props.parentCustomerId != prevProps.parentCustomerId || this.props.endpointId != prevProps.endpointId) {
            this.load(this.props);
        }
    }

    notifyAllStates() {
        if (!this.props.allStatesCallback) return;
        var allStates = [];
        for (let rule of this.inheritedRules) {
            if (rule.state && allStates.indexOf(rule.state) < 0) {
                allStates.push(rule.state);
            }
        }

        for (let rule of this.rules) {
            if (rule.state && allStates.indexOf(rule.state) < 0) {
                allStates.push(rule.state);
            }
        }

        this.props.allStatesCallback(allStates);
    }

    async load(props: Props) {
        this.customerId = props.customerId;
        this.parentCustomerId = props.parentCustomerId;
        this.endpointId = props.endpointId;
        this.timeZoneId = props.schedule.timeZoneId;
        this.inherit = props.schedule.inherit;
        this.forceClosed = props.schedule.forceClosed;
        this.rules = props.schedule.rules;
        this.inheritedSchedule = await serviceClient.get(new dtos.GetInheritedSchedule({ customerId: this.customerId, endpointId: this.endpointId }));
        this.inheritedTimeZone = this.inheritedSchedule == null ? undefined : this.inheritedSchedule.timeZoneId;
        this.inheritedRules = this.inheritedSchedule == null ? [] : this.inheritedSchedule.rules;
        this.editDefaultState = props.schedule.defaultState;
        this.notifyAllStates();
    }

    async save() {
        if (this.endpointId) {
            await serviceClient.patch(new dtos.PatchEndpoint({
                endpointId: this.props.endpointId,
                schedule: new dtos.Schedule({
                    timeZoneId: this.timeZoneId,
                    inherit: this.inherit,
                    forceClosed: this.forceClosed,
                    rules: this.rules,
                    defaultState: this.editDefaultState
                })
            }));
        } else {
            await serviceClient.patch(new dtos.PatchCustomer({
                customerId: this.props.customerId,
                schedule: new dtos.Schedule({
                    timeZoneId: this.timeZoneId,
                    inherit: this.inherit,
                    forceClosed: this.forceClosed,
                    rules: this.rules,
                    defaultState: this.editDefaultState
                })
            }));
        }
        ui.message.success("Schedule saved");
    }

    addNewRule() {
        this.rules.push(new dtos.SchedulingRule({
            id: uuid(),
            priority: 1,
            byDay: [],
            byWeekNo: [],
            byHour: [],
            byMinute: [],
            byMonth: [],
            byMonthDay: [],
            bySetPosition: [],
            byYearDay: [],
            count: 0,
            endTime: "8:00PM",
            frequency: dtos.SchedulingRuleFrequency.Daily,
            interval: 1,
            isAllDay: false,
            name: "",
            startDate: moment().toISOString(),
            startTime: "8:00AM",
            state: "",
            untilDate: "1/1/9999"
        }));
        this.forceUpdate();
    }

    removeRule(item: dtos.SchedulingRule) {
        var idx = this.rules.indexOf(item);
        if (idx >= 0) {
            this.rules.splice(idx, 1);
            this.forceUpdate();
        }
    }

    getAllRules(): dtos.SchedulingRule[] {
        let inherited = this.inheritedRules.filter(r => this.hideInherited || (!this.showInheritedHolidays && r.state == "Holiday") ? false : true);
        return this.inherit && !this.hideInherited ? [...this.rules, ...inherited] : this.rules;
    }

    isMyRule(r: dtos.SchedulingRule) {
        return this.rules.indexOf(r) >= 0;
    }

    async testSchedule() {
        let response = await serviceClient.post(new dtos.TestSchedule({
            customerId: this.customerId,
            endpointId: this.endpointId,
            dateTime: this.testDate.toISOString(),
            schedule: new dtos.Schedule({
                timeZoneId: this.timeZoneId,
                inherit: this.inherit,
                rules: this.rules,
                forceClosed: this.forceClosed,
                defaultState: this.editDefaultState
            })
        }));
        let content = <span>
            <strong>Time:</strong> {this.testDate.format("LLLL Z")}
            <strong style={{ marginLeft: 8 }}>Time Zone:</strong> {response.timeZoneId || "(NOT DETERMINED)"}
            <strong style={{ marginLeft: 8 }}>State:</strong> {response.stateName || "(NONE)"}
        </span>;
        ui.message.info(content, 5);
        localStorage.setItem("Schedule.testDate", this.testDate.toISOString());
    }

    getRuleEditor(r: dtos.SchedulingRule) {
        var self = this;
        var disabled = !this.isMyRule(r);

        function text(str) {
            return <span style={{ marginRight: 4, marginLeft: 4 }}>{str}</span>;
        }

        function interval() {
            return <ui.InputNumber disabled={disabled} style={{ width: 50 }} value={r.interval} onChange={v => { r.interval = v; self.forceUpdate(); }} />
        }

        function startDate() {
            return <ui.DatePicker disabled={disabled} style={{ width: 140 }} value={r.startDate ? moment(r.startDate) : moment()} onChange={v => { r.startDate = v.toISOString(); self.forceUpdate() }} />
        }

        function daysOfWeek() {
            function setDaysOfWeek(v) {
                r.byDay = v.map(dow => new dtos.ScheduleDay({ dayOfWeek: dow }));
                self.forceUpdate();
            }

            return <ui.Select disabled={disabled} style={{ width: 200, marginLeft: 4, marginRight: 4 }} mode="tags" placeholder="Days of week" value={r.byDay.map(bd => bd.dayOfWeek)} onChange={v => setDaysOfWeek(v)} dropdownMatchSelectWidth={false}>
                {Object.keys(dtos.DayOfWeek).map(dow => <ui.Select.Option value={dow} key={dow}>{dow}</ui.Select.Option>)}
            </ui.Select>;
        }

        function nthDayOfMonth() {
            function setNthDay(ev: string) {
                if (ev[0] == "_") {
                    if (r.byMonthDay.length > 0) {
                        r.byMonthDay = [];
                        r.byDay = [new dtos.ScheduleDay({ dayOfWeek: dtos.DayOfWeek.Monday })];
                    }
                    r.byDay[0].offset = parseInt(ev.substr(1));
                } else {
                    if (r.byDay.length > 0) {
                        r.byDay = [];
                        r.byMonthDay = [1];
                    }
                    r.byMonthDay = [parseInt(ev)];
                }
                self.forceUpdate();
            }

            return (
                <span>
                    <ui.Select style={{ marginLeft: 4, marginRight: 4, width: 175 }} disabled={disabled} value={r.byMonthDay.length > 0 ? r.byMonthDay[0].toString() : "_" + r.byDay[0].offset.toString()} onChange={ev => setNthDay(ev)} dropdownMatchSelectWidth={false}>
                        <ui.Select.Option value="_1">The first</ui.Select.Option>
                        <ui.Select.Option value="_2">The second</ui.Select.Option>
                        <ui.Select.Option value="_3">The third</ui.Select.Option>
                        <ui.Select.Option value="_4">The fourth</ui.Select.Option>
                        <ui.Select.Option value="_-1">The last</ui.Select.Option>
                        <ui.Select.Option value="_-2">The 2nd to last</ui.Select.Option>
                        <ui.Select.Option value="_-3">The 3rd to last</ui.Select.Option>
                        <ui.Select.Option value="_-4">The 4th to last</ui.Select.Option>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].map(n => <ui.Select.Option key={n} value={n.toString()}>Day {n}</ui.Select.Option>)}
                    </ui.Select>
                    {r.byDay.length > 0 && <ui.Select style={{ marginRight: 4, width: 150 }} value={r.byDay[0].dayOfWeek} onChange={ev => { r.byDay[0].dayOfWeek = ev; self.forceUpdate() }}>
                        {Object.keys(dtos.DayOfWeek).map(dow => <ui.Select.Option value={dow} key={dow}>{dow}</ui.Select.Option>)}
                    </ui.Select>}
                </span>
            );
        }

        function month() {
            return <ui.Select disabled={disabled} style={{ marginLeft: 4, marginRight: 4, width: 150 }} value={r.byMonth[0]} onChange={ev => { r.byMonth[0] = ev; self.forceUpdate() }} dropdownMatchSelectWidth={false}>
                <ui.Select.Option value={1}>January</ui.Select.Option>
                <ui.Select.Option value={2}>February</ui.Select.Option>
                <ui.Select.Option value={3}>March</ui.Select.Option>
                <ui.Select.Option value={4}>April</ui.Select.Option>
                <ui.Select.Option value={5}>May</ui.Select.Option>
                <ui.Select.Option value={6}>June</ui.Select.Option>
                <ui.Select.Option value={7}>July</ui.Select.Option>
                <ui.Select.Option value={8}>August</ui.Select.Option>
                <ui.Select.Option value={9}>September</ui.Select.Option>
                <ui.Select.Option value={10}>October</ui.Select.Option>
                <ui.Select.Option value={11}>November</ui.Select.Option>
                <ui.Select.Option value={12}>December</ui.Select.Option>
            </ui.Select>;
        }

        function endChoice() {
            function setEndChoice(v) {
                if (v == "count") {
                    r.count = 1;
                    r.untilDate = "";
                } else if (v == "forever") {
                    r.count = 0;
                    r.untilDate = "1/1/9999";
                } else {
                    r.count = 0;
                    r.untilDate = moment().toISOString();
                }
                self.forceUpdate();
            }

            return (
                <span>
                    <ui.Select disabled={disabled} value={!r.untilDate ? "count" : (moment(r.untilDate || "1/1/9999").year() == 9999 ? "forever" : "until")} onChange={ev => setEndChoice(ev)} dropdownMatchSelectWidth={false}>
                        <ui.Select.Option value="count">for</ui.Select.Option>
                        <ui.Select.Option value="until">until</ui.Select.Option>
                        <ui.Select.Option value="forever">forever</ui.Select.Option>
                    </ui.Select>
                    {!r.untilDate && <ui.InputNumber disabled={disabled} style={{ marginLeft: 4, width: 50 }} value={r.count} onChange={ev => { r.count = ev; self.forceUpdate() }} />}
                    {r.untilDate && moment(r.untilDate).year() != 9999 && <ui.DatePicker disabled={disabled} style={{ marginLeft: 4, width: 140 }} value={r.untilDate ? moment(r.untilDate) : moment()} onChange={m => { r.untilDate = m.toISOString(); self.forceUpdate(); }} />}
                    {!r.untilDate && text("time(s)")}
                </span>);
        }

        switch (r.frequency) {
            case dtos.SchedulingRuleFrequency.None:
                return [text("On"), startDate()];
            case dtos.SchedulingRuleFrequency.Daily:
                return [text("Starts"), startDate(), text("and repeats every"), interval(), text("day(s)"), endChoice()];
            case dtos.SchedulingRuleFrequency.Weekly:
                return [text("Every"), interval(), daysOfWeek(), endChoice()];
            case dtos.SchedulingRuleFrequency.Monthly:
                return [nthDayOfMonth(), text("every month repeat"), endChoice()]
            case dtos.SchedulingRuleFrequency.Yearly:
                return [nthDayOfMonth(), text("of"), month(), endChoice()]
            default:
                return [text("Unknown rule type")];
        }
    }

    setFrequency(r: dtos.SchedulingRule, f: dtos.SchedulingRuleFrequency) {
        r.frequency = f;
        r.byDay = r.byHour = r.byMinute = r.byMonth = r.byMonthDay = r.bySetPosition = r.byWeekNo = r.byYearDay = [];
        r.interval = 1;

        switch (r.frequency) {
            case dtos.SchedulingRuleFrequency.Daily:
                break;
            case dtos.SchedulingRuleFrequency.Weekly:
                r.byDay = [new dtos.ScheduleDay({ dayOfWeek: dtos.DayOfWeek.Monday })];
                break;
            case dtos.SchedulingRuleFrequency.Monthly:
                r.byMonthDay = [1];
                break;
            case dtos.SchedulingRuleFrequency.Yearly:
                r.byMonthDay = [1];
                r.byMonth = [1];
                break;
            default:
                break;
        }
        this.forceUpdate();
    }

    setRuleState(rule: dtos.SchedulingRule, state: string) {
        rule.state = state;
        this.notifyAllStates();
        this.forceUpdate()
    }

    setHideInherited(hide) {
        this.hideInherited = hide;
        localStorage.setItem("Schedule.hideInherited", hide ? "true" : "false");
    }

    setShowInheritedHolidaeys(show) {
        this.showInheritedHolidays = show;
        localStorage.setItem("Schedule.hideInheritedHolidays", show ? "false" : "true");
    }

    render() {
        return (
            <div>
                <ui.Form layout="inline">
                    <ui.Form.Item>
                        <ui.Switch checked={this.forceClosed} onChange={ev => this.forceClosed = ev} unCheckedChildren={<span>Force Closed</span>} checkedChildren={<span>Force Closed</span>} />
                    </ui.Form.Item>
                    {this.parentCustomerId && <ui.Form.Item>
                        <ui.Switch checked={this.inherit} onChange={ev => this.inherit = ev} unCheckedChildren={<span>Inherit</span>} checkedChildren={<span>Inherit</span>} />
                    </ui.Form.Item>}
                    <ui.Form.Item label="Time Zone">
                        <ui.Select showSearch filterOption={selectFilter} placeholder={this.inherit && this.inheritedTimeZone ? this.inheritedTimeZone : "Select a time zone"} value={this.timeZoneId} onChange={ev => this.timeZoneId = ev} style={{ width: 300 }} dropdownMatchSelectWidth={false}>
                            <ui.Select.Option value="">({this.inheritedTimeZone} - Inherited)</ui.Select.Option>
                            {this.timeZones.map(tz => <ui.Select.Option key={tz.id} value={tz.id}>{tz.displayName} - {tz.id}</ui.Select.Option>)}
                        </ui.Select>
                    </ui.Form.Item>
                    <ui.Form.Item label="Default State">
                        <ui.AutoComplete dataSource={["Business Hours", "After Hours", "Holiday"]} placeholder={this.inheritedSchedule != null && this.inherit ? this.inheritedSchedule.defaultState || "None" : "None"} onChange={v => this.editDefaultState = v as string} value={this.editDefaultState}>
                            <ui.Input.Search />
                        </ui.AutoComplete>
                    </ui.Form.Item>
                    <ui.Form.Item style={{ marginLeft: 24 }} label="Test Schedule">
                        <ui.DatePicker showTime={{ format: "h:mm a", use12Hours: true }} format="dddd | MMMM Do, YYYY | h:mm a | [UTC]ZZ" style={{ width: 350 }} value={moment(this.testDate)} onChange={v => this.testDate = v} />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Button onClick={() => this.testSchedule()} type="primary">Check Schedule</ui.Button>
                    </ui.Form.Item>
                </ui.Form>
                <ui.Table style={{ marginTop: 16 }} dataSource={_.sortBy(this.getAllRules(), r => -r.priority)} rowKey="id" pagination={false}>
                    <ui.Table.Column width={150} title="Name" dataIndex="name" render={(text, item: dtos.SchedulingRule) =>
                        <div style={{ position: "relative" }}>
                            <ui.Input disabled={!this.isMyRule(item)} value={item.name} onChange={ev => { item.name = ev.target.value; this.forceUpdate() }} placeholder="Rule Name" />
                            {item.source && <small style={{ position: "absolute", bottom: -16, left: 10 }}>from {item.source}</small>}
                        </div>} />
                    <ui.Table.Column width={100} title="Type" render={(text, item: dtos.SchedulingRule) =>
                        <div>
                            <ui.Select disabled={!this.isMyRule(item)} style={{ width: 100 }} value={item.frequency} onChange={ev => this.setFrequency(item, ev)}>
                                <ui.Select.Option value={dtos.SchedulingRuleFrequency.None}>Once</ui.Select.Option>
                                <ui.Select.Option value={dtos.SchedulingRuleFrequency.Daily}>Daily</ui.Select.Option>
                                <ui.Select.Option value={dtos.SchedulingRuleFrequency.Weekly}>Weekly</ui.Select.Option>
                                <ui.Select.Option value={dtos.SchedulingRuleFrequency.Monthly}>Monthly</ui.Select.Option>
                                <ui.Select.Option value={dtos.SchedulingRuleFrequency.Yearly}>Yearly</ui.Select.Option>
                            </ui.Select>
                        </div>
                    } />
                    <ui.Table.Column title="When" render={(text, item: dtos.SchedulingRule) =>
                        <div>
                            {this.getRuleEditor(item)}
                            <div style={{ marginTop: 8 }}>
                                <ui.Input placeholder="(Advanced) Specify an additional condition" value={item.condition} onChange={ev => { item.condition = ev.target.value; this.forceUpdate(); }} />
                            </div>
                        </div>} />
                    <ui.Table.Column width={400} title="Time" render={(text, item: dtos.SchedulingRule) =>
                        <div>
                            <ui.Switch disabled={!this.isMyRule(item)} style={{ marginRight: 16 }} checked={item.isAllDay} onChange={ev => { item.isAllDay = ev; this.forceUpdate(); }} checkedChildren={<span>All Day</span>} unCheckedChildren={<span>All Day</span>} />
                            {!item.isAllDay && <ui.TimePicker disabled={!this.isMyRule(item)} value={moment(item.startTime || "8:00AM", "h:mm a")} onChange={(m, str) => { item.startTime = str; this.forceUpdate() }} style={{ marginRight: 8 }} format="h:mm a" use12Hours minuteStep={1} placeholder="Start Time" />}
                            {!item.isAllDay && <ui.TimePicker disabled={!this.isMyRule(item)} value={moment(item.endTime || "8:00PM", "h:mm a")} onChange={(m, str) => { item.endTime = str; this.forceUpdate() }} placeholder="End Time" format="h:mm a" use12Hours minuteStep={1} />}
                        </div>} />
                    <ui.Table.Column width={175} title="State" dataIndex="state" render={(text, item: dtos.SchedulingRule) =>
                        <div>
                            <ui.AutoComplete dataSource={["Business Hours", "After Hours", "Holiday"]} disabled={!this.isMyRule(item)} onChange={v => this.setRuleState(item, v.toString())} value={item.state}>
                                <ui.Input.Search />
                            </ui.AutoComplete>
                        </div>} />
                    <ui.Table.Column width={75} title="Priority" dataIndex="priority" render={(text, item: dtos.SchedulingRule) =>
                        <div>
                            <ui.InputNumber disabled={!this.isMyRule(item)} value={item.priority} onChange={ev => { item.priority = ev; this.forceUpdate() }} placeholder="Priority" />
                        </div>} />
                    <ui.Table.Column width={75} title="" render={(text, item: dtos.SchedulingRule) =>
                        <div>
                            <ui.Button.Group>
                                {this.isMyRule(item) && <ui.Popconfirm onConfirm={() => this.removeRule(item)} title="Remove rule?"><ui.Button><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>}
                            </ui.Button.Group>
                        </div>} />
                </ui.Table>
                <p>
                    <small>{this.inherit && <ui.Checkbox checked={!this.hideInherited} onChange={ev => this.setHideInherited(!ev.target.checked)}><small>Show Inherited Rules</small></ui.Checkbox>}</small>
                    {this.inherit && !this.hideInherited && < small > {this.inherit && <ui.Checkbox checked={this.showInheritedHolidays} onChange={ev => this.setShowInheritedHolidaeys(ev.target.checked)}><small>Show Inherited Holidays</small></ui.Checkbox>}</small>}
                </p>
                <p><small>Rules are evaluated in order of priority with highest priority rules evaluated first</small></p>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <ui.Button style={{ marginRight: 8 }} type="primary" onClick={() => this.save()}>Save</ui.Button>
                    <ui.Button onClick={() => this.addNewRule()}>New Rule</ui.Button>
                </div>

            </div>);
    }
}