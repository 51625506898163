import { React, dtos, ui, serviceClient, observer, shared, observable, uuid, _, splitIntoWords, moment, selectFilter, makeObservable, cache } from "../common";
import PageTitle from "../page-title";
import AccountHeader from "../account-header";
import ReactQuill from 'react-quill';

@observer
export default class DocumentationIndex extends React.Component {
    @observable documentation = "";
    @observable readOnly = true;
    @observable originalDocumentation = "";
    
    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    async componentDidMount() {
        let ss = await cache.getSystemSettings();
        this.documentation = ss.documentation || "";
        this.originalDocumentation = this.documentation;
    }

    async saveChanges() {
        await serviceClient.patch(new dtos.PatchSystemSettings({
            accountId: shared.accountId,
            documentation: this.documentation
        }));
        this.originalDocumentation = this.documentation;
        this.readOnly = true;
    }

    async cancelChanges() {
        this.documentation = this.originalDocumentation;
        this.readOnly = true;
    }

    render() {
        return (
            <div>
                <PageTitle title="Documentation" />
                <AccountHeader title="Documentation" icon="fa fa-book" />
                <ReactQuill className={this.readOnly ? "read-only" : ""} readOnly={this.readOnly} value={this.documentation} onChange={v => this.documentation = v} />
                <ui.Button.Group style={{ marginTop: 16 }}>
                    {this.readOnly && <ui.Button type="primary" onClick={() => this.readOnly = false}>Edit</ui.Button>}
                    {!this.readOnly && <ui.Button type="primary" onClick={() => this.saveChanges()}>Save</ui.Button>}
                    {!this.readOnly && <ui.Button type="default" onClick={() => this.cancelChanges()}>Cancel</ui.Button>}
                </ui.Button.Group>
            </div>
        );
    }
}