import { React, dtos, ui } from "../common";
import FlowCanvasNode from "./node";

declare const jsPlumb: any;
declare const jsPlumbUtil: any;

interface Props {
    flow: dtos.FlowInfo;
    hasStartNode: boolean;
    onClick: () => void;
    onStartDrag: (nodeId: string) => void;
    onDrag: (nodeId: string, x: number, y: number) => void;
    onConnect: (sourceId, targetId, userEvent:boolean) => void;
    onDisconnect: (sourceId, targetId) => void;
    zoom: number;
    children: any;
}

export default class FlowCanvas extends React.Component<Props> {
    instance: any = null;
    container = React.createRef<HTMLDivElement>();
    startNode = React.createRef<HTMLDivElement>();

    deleteNode(n: FlowCanvasNode) {
        let node = n.props.node;
        this.instance.unmakeTarget(node.id);
        let connections = this.instance.getConnections();
        for (let cn of connections) {
            if (cn.sourceId.indexOf(node.id) >= 0 || cn.targetId.indexOf(node.id) >= 0) {
                this.instance.deleteConnection(cn);
            }
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.zoom != prevProps.zoom) {
            this.instance.setZoom(this.props.zoom);
        }
    }

    deleteExit(name: string) {
        this.instance.unmakeTarget("exit-" + name);
        let connections = this.instance.getConnections();
        for (let cn of connections) {
            if (cn.targetId.indexOf("exit-" + name) >= 0) {
                this.instance.deleteConnection(cn);
            }
        }
    }

    redraw() {
        setTimeout(() => this.instance.repaintEverything(), 15);
    }

    removeTransition(id) {
        this.instance.deleteConnectionsForElement(id);
        this.instance.unmakeSource(id);
    }

    addTransition(id) {
        this.instance.makeSource(id, {
            maxConnections: 1,
            anchor: [1, .5, 0, 0, -15, 0],
        });
    }

    addExit(id:string) {
        this.instance.addGroup({
            el: document.getElementById(id),
            id: id,
            draggable: true,
            droppable: false,
            dragOptions: {
                start: (ev) => {
                    this.props.onStartDrag(id);
                },
                stop: (ev) => {
                    this.props.onDrag(id, ev.pos[0], ev.pos[1]);
                }
            }
        });

        this.instance.makeTarget(id, {
            dropOptions: {
                hoverClass: "dragHover"
            },
            anchor: [0, 0, 0, 0, 5, 25],
            allowLoopback: false
        });
    }

    connect(transId, nodeId) {
        if (document.getElementById(nodeId) == null && document.getElementById("exit-" + nodeId) != null) {
            nodeId ="exit-" + nodeId;
        }

        this.instance.connect({
            source: transId,
            target: nodeId,
            anchors: [[1, .5, 0, 0, -15, 0], [0, 0, 0, 0, 5, 25]]
        });
    }

    reconnect(transId, nodeId) {
        this.instance.deleteConnectionsForElement(transId);
        this.connect(transId, nodeId);
    }

    addNode(nodeId: string, isStartNode: boolean) {
        this.instance.addGroup({
            el: document.getElementById(nodeId),
            id: nodeId,
            draggable: true,
            droppable: false,
            dragOptions: {
                filter: "th,th span",
                filterExclude:false,
                start: (ev) => {
                    this.props.onStartDrag(nodeId);
                },
                stop: (ev) => {
                    this.props.onDrag(nodeId, ev.pos[0], ev.pos[1]);
                }
            }
        });

        this.instance.makeTarget(nodeId, {
            dropOptions: {
                hoverClass: "dragHover"
            },
            anchor: [0, 0, 0, 0, 5, 25],
            allowLoopback: false
        });

        if (isStartNode) {
            this.instance.connect({
                source: "start",
                target: nodeId
            });
        }
    }

    componentDidMount() {
        jsPlumb.ready(() => this.init());
    }

    init() {
        // setup some defaults for jsPlumb.
        this.instance = jsPlumb.getInstance({
            Container: this.container.current,
            Endpoint: ["Dot", { radius: .1 }],
            Connector: ["StateMachine", { curviness: 15, margin: 0 }],
            HoverPaintStyle: { stroke: "#f5222d", strokeWidth: 2 },
            PaintStyle: { stroke: "#1890ff", strokeWidth: 2, outlineStroke: "transparent", outlineWidth: 4 },
            ConnectionOverlays: [
                ["Arrow", {
                    location: 1,
                    id: "arrow",
                    length: 14,
                    foldback: 0.8
                }]
            ]
        });

        this.instance.makeSource(this.startNode.current, {
            filter: ".ep",
            maxConnections: 1,
            anchor: [1, .5, 0, 0, -15, 0],
        });

        // this.instance.registerConnectionType("basic", { anchor: "Continuous", connector: "StateMachine" });
        this.instance.bind("click", c => this.instance.deleteConnection(c));
        this.instance.bind("connection", (info, oe) => {
            if (info.sourceId.indexOf(info.targetId) >= 0) {
                this.instance.deleteConnection(info.connection);
            } else {
                this.props.onConnect(info.sourceId, info.targetId, oe != undefined);
            }
        });
        this.instance.bind("connectionDetached", info => {
            this.props.onDisconnect(info.sourceId, info.targetId);
        });
    }

    render() {
        let { onClick, hasStartNode } = this.props;

        return (
            <div ref={this.container} onClick={onClick} className="jtk-surface jtk-surface-nopan" id="canvas">
                <div ref={this.startNode} className="flow-node start-node" id="start">
                    <ui.Table dataSource={[{ id: "1", name: "Start" }]} pagination={false} size="small" rowKey="id" showHeader={false}>
                        <ui.Table.Column dataIndex="name" />
                        <ui.Table.Column width={30} align="center" render={(text, rec) => <div className={"ep " + (hasStartNode ? "jtk-connected" : "")} />} />
                    </ui.Table>
                </div>
                {this.props.children}
            </div>
        );
    }
}