import { React, serviceClient, dtos, observer, shared, observable, ui, Link, selectFilter, _, splitIntoWords, uuid, moveArray, removeArray, makeObservable, withRouter } from "../common";

interface Props {
    appSettings: dtos.AppSettings;
    onChanged: (settings: dtos.AppSettings) => void;
}

export default function AppSettingsEditor(props: Props) {
    function onChanged(update: Partial<dtos.AppSettings>) {
        props.onChanged(new dtos.AppSettings({ ...props.appSettings, ...update }));
    }

    return <ui.Form layout="vertical" style={{ marginTop: 8, border: "1px solid #f1f1f1", padding: 8 }}>
        <ui.Space direction="vertical">
            <ui.Checkbox checked={props.appSettings.enablePhoneNumberManagement} onChange={ev => onChanged({ enablePhoneNumberManagement: ev.target.checked })}>Enable Phone Number Management</ui.Checkbox>
            <ui.Checkbox checked={props.appSettings.enableDeviceManagement} onChange={ev => onChanged({ enableDeviceManagement: ev.target.checked })}>Enable Device Management</ui.Checkbox>
            <ui.Checkbox checked={props.appSettings.enableDialer} onChange={ev => onChanged({ enableDialer: ev.target.checked })}>Enable Dialer</ui.Checkbox>
            <ui.Checkbox checked={props.appSettings.enableAssistants} onChange={ev => onChanged({ enableAssistants: ev.target.checked })}>Enable AI Assistants</ui.Checkbox>
            <ui.Checkbox checked={props.appSettings.enableCallHistory} onChange={ev => onChanged({ enableCallHistory: ev.target.checked })}>Enable Call History</ui.Checkbox>
            <ui.Checkbox checked={props.appSettings.showFileNameInMessageCenter} onChange={ev => onChanged({ showFileNameInMessageCenter: ev.target.checked })}>Show File Name in Message Center</ui.Checkbox>
            <ui.Form.Item label="Page Title">
                <ui.Input value={props.appSettings.pageTitle} onChange={ev => onChanged({ pageTitle: ev.target.value })} />
            </ui.Form.Item>
            <ui.Form.Item label="Logout URL">
                <ui.Input value={props.appSettings.logoutUrl} onChange={ev => onChanged({ logoutUrl: ev.target.value })} />
            </ui.Form.Item>
            <ui.Form.Item label="Port My Number URL">
                <ui.Input value={props.appSettings.portMyNumberUrl} onChange={ev => onChanged({ portMyNumberUrl: ev.target.value })} />
            </ui.Form.Item>
            <ui.Form.Item label="Chakra Theme (advanced)">
                <ui.Input.TextArea style={{ width: 600 }} rows={8} value={props.appSettings.chakraTheme} onChange={ev => onChanged({ chakraTheme: ev.target.value })} />
            </ui.Form.Item>
            <ui.Form.Item label="Custom CSS">
                <ui.Input.TextArea style={{ width: 600 }} rows={8} value={props.appSettings.customCss} onChange={ev => onChanged({ customCss: ev.target.value })} />
            </ui.Form.Item>
            <ui.Form.Item label="String Mappings">
                <ui.Input.TextArea style={{ width: 600 }} rows={8} value={props.appSettings.stringMappings} onChange={ev => onChanged({ stringMappings: ev.target.value })} />
            </ui.Form.Item>
        </ui.Space>
    </ui.Form>
}