import { React, dtos, ui, observer, observable, serviceClient, Observer, splitIntoWords, moveArray, removeArray, uuid, makeObservable } from "../common";

interface Props {
    dataType: dtos.DataType
}

@observer
export default class DataTypeEditor extends React.Component<Props> {
    
    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    newField() {
        this.props.dataType.fields.push(new dtos.DataField({
            id: uuid(),
            name: "New Field",
            type: dtos.ValueTypes.String 
        }));
        this.forceUpdate();
    }

    render() {
        const dt = this.props.dataType;
        const forbiddenTypes = [
            dtos.ValueTypes.List,
            dtos.ValueTypes.Struct,
            dtos.ValueTypes.Custom,
            dtos.ValueTypes.NotSpecified,
            dtos.ValueTypes.Transition
        ];

        return <>
            <ui.Table dataSource={dt.fields} rowKey="id" pagination={false}>
                <ui.Table.Column title="Name" dataIndex="name" render={(text, rec: dtos.DataField) => <div>
                    <ui.Input size="small" value={rec.name} onChange={ev => { rec.name = ev.target.value; this.forceUpdate(); }} />
                </div>} />
                <ui.Table.Column title="Type" dataIndex="type" render={(tex, rec: dtos.DataField) => <div>
                    <ui.Select size="small" value={rec.type} onChange={v => { rec.type = v; this.forceUpdate(); }}>
                        {Object.keys(dtos.ValueTypes).filter(t => forbiddenTypes.indexOf(t as any) < 0).map(t => <ui.Select.Option key={t} value={t}>{splitIntoWords(t)}</ui.Select.Option>)}
                    </ui.Select>
                </div>} />
                <ui.Table.Column title="" render={(text, rec: dtos.DataField, idx:number) => <div>
                    <ui.Button.Group size="small">
                        <ui.Popconfirm title="Remove this field?" onConfirm={() => removeArray(this, dt.fields, rec)}><ui.Button><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>
                    </ui.Button.Group>

                    <ui.Button.Group size="small" style={{ marginLeft: 6 }}>
                        <ui.Button disabled={idx == 0} onClick={() => moveArray(this, dt.fields, rec, -1)}><i className="fa fa-arrow-alt-up" /></ui.Button>
                        <ui.Button disabled={idx == dt.fields.length - 1} onClick={() => moveArray(this, dt.fields, rec, 1)}><i className="fa fa-arrow-alt-down" /></ui.Button>
                    </ui.Button.Group>
                </div>} />
            </ui.Table>
            <ui.Button.Group style={{marginTop:6}}>
                <ui.Button type="primary" ghost onClick={() => this.newField()}>New Field</ui.Button>
            </ui.Button.Group>
        </>
    }
}