import { React, serviceClient, dtos, observer, observable, ui, _, Link, Observer, moment, splitIntoWords, selectFilter, makeObservable } from "../common";

interface Props {
    accountId: string;
}

@observer
export default class TrustIndex extends React.Component<Props> {
    @observable customerProfileSid: string;
    @observable cnamProductSid: string;
    @observable shakenStirProductSid: string;
    @observable busy = false;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        const response = await serviceClient.get(new dtos.GetTrustProfile({ accountId: this.props.accountId }));
        this.customerProfileSid = response.customerProfileSid;
        this.cnamProductSid = response.cnamProductSid;
        this.shakenStirProductSid = response.shakenStirProductSid;
        this.forceUpdate();
    }

    async save(submit: boolean) {
        try {
            this.busy = true;
            await serviceClient.post(new dtos.UpdateTrustProfile({
                accountId: this.props.accountId,
                customerProfileSid: this.customerProfileSid,
                cnamProductSid: this.cnamProductSid,
                shakenStirProductSid: this.shakenStirProductSid
            }));
            ui.message.success(submit ? "The profile has been saved and submitted to Twilio for review. Check this page to see the current status." : "Profile saved successfully but NOT submitted to Twilio")
        } finally {
            this.busy = false;
        }

    }

    render() {
        return <div>
            <ui.Alert message="This section should only be filled out by an Evo Technologies support member" showIcon />
            <ui.Form layout="vertical" style={{ marginTop: 8 }}>
                <ui.Form.Item label="Customer Profile SID">
                    <ui.Input value={this.customerProfileSid} onChange={ev => this.customerProfileSid = ev.target.value} />
                </ui.Form.Item>
                <ui.Form.Item label="CNAM Product SID">
                    <ui.Input value={this.cnamProductSid} onChange={ev => this.cnamProductSid = ev.target.value} />
                </ui.Form.Item>
                <ui.Form.Item label="SHAKEN/STIR Product SID">
                    <ui.Input value={this.shakenStirProductSid} onChange={ev => this.shakenStirProductSid = ev.target.value} />
                </ui.Form.Item>
                <ui.Button.Group style={{ marginTop: 8 }}>
                    <ui.Button onClick={() => this.save(false)} type="primary">Save</ui.Button>
                </ui.Button.Group>
            </ui.Form>
        </div>;
    }
}