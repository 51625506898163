import { React, serviceClient, dtos, observer, observable, shared, ui, _, Link, Observer, splitIntoWords, moment, selectFilter, makeObservable } from "../common";
declare const Twilio;

interface Props {
    accountId: string;
    visible: boolean;
    onCancel: () => void;
    numbersToTest: dtos.EndpointInfo[];
}

@observer
export default class PhoneNumberTester extends React.Component<Props> {
    token = "";
    logDiv = React.createRef<HTMLDivElement>();
    @observable testing = false;
    @observable log: string[] = [];
    device: any = null;
    currentNumber = "";
    @observable numbersLeft: dtos.EndpointInfo[] = [];
    @observable failedNumbers: string[] = [];

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        let response = await serviceClient.get(new dtos.GetTwilioToken({ accountId: this.props.accountId }));
        this.token = response.token;
    }

    componentWillUnmount() {
        if (this.testing) {
            this.stopTest();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.visible && !prevProps.visible) {
            this.log = ["Press Start to begin the test"];
        }
    }

    addToLog(message: string) {
        this.log.push(message);
        this.forceUpdate();
        setTimeout(() => {
            this.logDiv.current.scrollTop = this.logDiv.current.scrollHeight;
        }, 10);
    }

    startTest() {
        this.testing = true;
        this.failedNumbers = [];
        this.log = [];
        this.device = new Twilio.Device();
        //this.device.on("ready", dev => this.onReady(dev));
        //this.device.on("offline", dev => this.onOffline(dev));
        //this.device.on("incoming", cn => this.onIncoming(cn));
        //this.device.on("cancel", cn => this.onCancel(cn));
        //this.device.on("disconnect", cn => this.onDisconnect(cn));
        //this.device.on("connect", cn => this.onConnect(cn));
        //this.device.on("error", err => this.onError(err));
        //this.device.on("warning", warning => this.onMessage("WARNING:" + warning));

        let numbersToTest = this.props.numbersToTest;
        if (numbersToTest.length == 0) {
            this.addToLog("No numbers were selected to test");
            this.testing = false;
            return;
        }

        this.testing = true;
        this.addToLog("Testing " + numbersToTest.length + " numbers");
        this.addToLog("Initializing device...");
        this.device.setup(this.token, {
            codecPreferences: ["opus", "pcmu"],
            allowIncomingWhileBusy: true,
            fakeLocalDTMF: true
        });

        this.device.on("ready", () => {
            this.addToLog("Device is ready to begin test");
            this.numbersLeft = numbersToTest;
            this.dialNextNumber();
        });
    }

    dialNextNumber() {
        if (this.numbersLeft.length == 0) {
            this.addToLog("All done testing");
            this.stopTest();
            return;
        }

        let nextNumber = this.numbersLeft[0];
        this.currentNumber = nextNumber.phoneNumber;
        this.numbersLeft.splice(0, 1);
        this.addToLog("Calling number " + nextNumber.phoneNumber + " in flow " + nextNumber.flowName);
        this.addToLog("Press Success or Failed to move on");
        let cn = this.device.connect({
            Destination: nextNumber.phoneNumber
        });
    }

    stopTest() {
        this.addToLog("STOPPING TEST");
        console.log("destroying device");
        this.device.destroy();
        this.device = null;
        this.testing = false;
        for (let fn of this.failedNumbers) {
            this.addToLog("FAILED: " + fn);
        }
    }

    toggleTest() {
        if (this.testing) {
            this.stopTest();
        } else {
            this.startTest();
        }
    }

    cancel() {
        if (this.testing) {
            this.stopTest();
        }
        this.props.onCancel();
    }

    dtmf(digits:string) {
        let cn = this.device.activeConnection();
        if (cn) {
            this.addToLog("DTMF:" + digits);
            cn.sendDigits(digits);
        }
    }

    hangup(success:boolean) {
        if (!success) {
            this.failedNumbers.push(this.currentNumber);
        }

        let cn = this.device.activeConnection();
        if (cn) {
            this.addToLog("Hanging up on connection");
            cn.on("disconnect", () => this.dialNextNumber());
            cn.disconnect();
        } else {
            this.dialNextNumber();
        }
    }

    render() {
        return (
            <ui.Modal visible={this.props.visible} onOk={() => this.toggleTest()} onCancel={() => this.cancel()} cancelText="Close" okButtonProps={{  danger: this.testing, type: "primary" }} title="Phone Number Tester" okText={this.testing ? "Stop" : "Start"}>
                <ui.Row gutter={16}>
                    <ui.Col span={15}>
                        <div ref={this.logDiv} style={{ maxHeight: 200, height: 200, overflowY: "auto" }}>
                            {this.log.map((l, i) => <p style={{ padding: 0, margin: 0 }} key={"log" + i}><small>{l}</small></p>)}
                        </div>
                    </ui.Col>
                    <ui.Col span={9}>
                        <ui.Row style={{ marginBottom: 8 }}>
                            <ui.Col span={8}>
                                <ui.Button disabled={!this.testing} onClick={() => this.dtmf("1")}>1</ui.Button>
                            </ui.Col>
                            <ui.Col span={8}>
                                <ui.Button disabled={!this.testing} onClick={() => this.dtmf("2")}>2</ui.Button>
                            </ui.Col>
                            <ui.Col span={8}>
                                <ui.Button disabled={!this.testing} onClick={() => this.dtmf("3")}>3</ui.Button>
                            </ui.Col>
                        </ui.Row>
                        <ui.Row style={{ marginBottom: 8 }}>
                            <ui.Col span={8}>
                                <ui.Button disabled={!this.testing} onClick={() => this.dtmf("4")}>4</ui.Button>
                            </ui.Col>
                            <ui.Col span={8}>
                                <ui.Button disabled={!this.testing} onClick={() => this.dtmf("5")}>5</ui.Button>
                            </ui.Col>
                            <ui.Col span={8}>
                                <ui.Button disabled={!this.testing} onClick={() => this.dtmf("6")}>6</ui.Button>
                            </ui.Col>
                        </ui.Row>
                        <ui.Row style={{ marginBottom: 8 }}>
                            <ui.Col span={8}>
                                <ui.Button disabled={!this.testing} onClick={() => this.dtmf("7")}>7</ui.Button>
                            </ui.Col>
                            <ui.Col span={8}>
                                <ui.Button disabled={!this.testing} onClick={() => this.dtmf("8")}>8</ui.Button>
                            </ui.Col>
                            <ui.Col span={8}>
                                <ui.Button disabled={!this.testing} onClick={() => this.dtmf("9")}>9</ui.Button>
                            </ui.Col>
                        </ui.Row>
                        <ui.Row style={{ marginBottom: 8 }}>
                            <ui.Col span={8}>
                                <ui.Button disabled={!this.testing} onClick={() => this.dtmf("*")}>*</ui.Button>
                            </ui.Col>
                            <ui.Col span={8}>
                                <ui.Button disabled={!this.testing} onClick={() => this.dtmf("0")}>0</ui.Button>
                            </ui.Col>
                            <ui.Col span={8}>
                                <ui.Button disabled={!this.testing} onClick={() => this.dtmf("#")}>#</ui.Button>
                            </ui.Col>
                        </ui.Row>
                        <ui.Row style={{ marginBottom: 8 }}>
                            <ui.Col span={12}>
                                <ui.Button type="primary" disabled={!this.testing} onClick={() => this.hangup(true)}>Success</ui.Button>
                            </ui.Col>
                            <ui.Col span={12}>
                                <ui.Button danger disabled={!this.testing} onClick={() => this.hangup(false)}>Failed</ui.Button>
                            </ui.Col>
                        </ui.Row>
                    </ui.Col>
                </ui.Row>
            </ui.Modal>
        );
    }
}