import { React, ui, dtos, serviceClient, observer, observable, shared, _, selectFilter, uuid, makeObservable } from "./common";

interface Props {
    type: dtos.ValueTypes,
    value: string;
    typeSpecificFilter?: string;
    placeholder: string;
    onChanged: (newValue: string) => void;
    onSearch?: () => void;
    customerId?: string;
    static?: boolean;
    includeNotAssigned?:boolean;
    preloadCustomerId?:string;
}

@observer
export default class AutoComplete extends React.Component<Props> {
    @observable value = "";
    @observable inputId = uuid();
    @observable values: dtos.AutoCompleteValue[] = [];
    @observable emptyValues: dtos.AutoCompleteValue[] = [];
    @observable loading = false;
    @observable searchText = "";
    searchFn = _.debounce(this.doSearch, 100);
    @observable isOpen = false;
    
    constructor(props) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        if (this.props.static) {
            await this.preload();
        } 
        
        if (this.props.preloadCustomerId) {
            await this.preloadCustomer();
        }

        this.value = this.props.value || "";
        this.loadValue();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.value != prevProps.value) {
            this.value = this.props.value;
            this.loadValue();
        }
    }

    async preload() {
        var response = await serviceClient.get(new dtos.ListAutoCompleteValues({
            accountId: shared.accountId,
            customerId: this.props.customerId,
            type: this.props.type,
            typeSpecificFilter: this.props.typeSpecificFilter,
            all: true
        }));

        this.values = response.values;
    }

    async preloadCustomer() {
        var response = await serviceClient.get(new dtos.ListAutoCompleteValues({
            accountId: shared.accountId,
            customerId: this.props.preloadCustomerId,
            type: this.props.type,
            typeSpecificFilter: this.props.typeSpecificFilter,
            all: true,
            query: "+"
        }));
        this.emptyValues = response.values;
    }

    async loadValue() {
        if (!this.value) {
            this.value = "";
            if (!this.props.static) {
                this.values = this.emptyValues;
            }
            return;
        }

        if (this.value == "_") return;
        if (this.props.static) return;

        var response = await serviceClient.get(new dtos.ListAutoCompleteValues({
            accountId: shared.accountId,
            customerId: this.props.customerId,
            type: this.props.type,
            value: this.value
        }));

        this.values = response.values;

        if (this.props.preloadCustomerId) {
            this.values = [...this.values, ...this.emptyValues]
        }
    }

    async setValue(value) {
        this.value = value;
        this.props.onChanged(this.value);
        this.props.onSearch && this.props.onSearch();
        if (!this.value && !this.props.static) {
            this.values = this.emptyValues;
        }
    }

    async doSearch() {
        if (this.props.static) return;
        this.loading = true;
        let response = await serviceClient.get(new dtos.ListAutoCompleteValues({
            accountId: shared.accountId,
            customerId: this.props.customerId,
            query: this.searchText,
            type: this.props.type,
            typeSpecificFilter: this.props.typeSpecificFilter
        }));
        this.values = response.values;
        this.loading = false;
        this.forceUpdate();
    }

    async search(query : string) {
        if (this.props.static) {
            this.searchText = query;
        }
        else if (!query) {
            this.searchText = "";
            this.values = this.emptyValues;
            this.value = "";
            this.props.onChanged(this.value);
            this.props.onSearch && this.props.onSearch();
        } else if (query.length < 2) {
            this.searchText = query;
        } else {
            this.searchText = query;
            this.searchFn();
        }
    }

    onFocus() {
        var el = document.getElementById(this.inputId).getElementsByClassName("ant-input") as any;
        el[0].select();
    }

    render() {
        var filterOption = (value, option) => {
            if (!this.props.static) return true;
            var val = _.find(this.values, v => v.value == option.key);
            return val && val.displayName.toLowerCase().indexOf(value.toLowerCase()) >= 0;
        }

        return (
            <div>
                <ui.Select notFoundContent="Start typing to see results" loading={this.loading} onDropdownVisibleChange={v => this.isOpen = v} dropdownMatchSelectWidth={false} style={{ minWidth: 200 }} filterOption={filterOption} onSearch={q => this.search(q)} showSearch value={this.value || undefined} placeholder={this.props.placeholder} onChange={v => this.setValue(v)}>
                    {this.value && <ui.Select.Option key="undefined" value="">(None)</ui.Select.Option>}
                    {(!this.value || this.value == "_") && this.props.includeNotAssigned && <ui.Select.Option value="_">(Not Assigned)</ui.Select.Option>}
                    {this.values.map((v, i) => <ui.Select.Option key={v.value} value={v.value}><div dangerouslySetInnerHTML={{ __html: v.displayName }} /></ui.Select.Option>)}
                </ui.Select>
            </div>
        );
    }
}