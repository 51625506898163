import { React, ui, dtos, serviceClient, observable, observer, splitIntoWords, makeObservable } from "../common";
import OverflowPanel from "./overflow-panel";

interface Props {
    flowId: string;
    nodeSpecs: Array<dtos.FlowNodeSpec>;
    showExits: boolean;
    onAddNode: (node: dtos.FlowNodeSpec) => void;
    onAddExit: (exitName: string) => boolean;
}

@observer
export default class FlowToolbox extends React.Component<Props> {
    @observable showAddExit: boolean = false;
    @observable newExitName: string = "";
    @observable searchQuery = "";

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {

    }

    addToFlow(ns: dtos.FlowNodeSpec) {
        this.props.onAddNode(ns);
    }

    addExit() {
        var ok = this.props.onAddExit(this.newExitName);
        if (ok) {
            this.showAddExit = false;
            this.newExitName = "";
        }
    }

    getCategory(k) {
        let nodes = this.props.nodeSpecs.filter(n => n.category == k);
        if (nodes.length == 0) return [];

        return <ui.Collapse.Panel header={<span><i className="fa fa-folder" /> {splitIntoWords(k)}</span>} key={k}>
            <ui.List itemLayout="horizontal" dataSource={nodes.filter(n => n.category == k)} renderItem={n =>
                <ui.List.Item actions={[<a onClick={() => this.addToFlow(n)}>Add to Flow</a>]}>
                    <ui.List.Item.Meta title={n.name} description={n.description} avatar={<ui.Avatar size="small"><i className={n.iconClass || "fa fa-tags"} /></ui.Avatar>} />
                </ui.List.Item>} />
        </ui.Collapse.Panel>;
    }
    render() {
        return (
            <OverflowPanel>
                <div>
                    <ui.Form.Item style={{ padding: 0, margin: 0 }}>
                        <ui.Input size="small" placeholder="Search for nodes" value={this.searchQuery} onChange={ev => this.searchQuery = ev.target.value} />
                    </ui.Form.Item>
                    {!this.searchQuery && <ui.Collapse bordered={false} defaultActiveKey={[]} accordion>
                        {Object.keys(dtos.FlowNodeCategories).sort((s1, s2) => s1.localeCompare(s2)).map(k => this.getCategory(k))}
                        {this.props.showExits && <ui.Collapse.Panel header={<span><i className="fa fa-folder" /> Exits</span>} key="exits">
                            <ui.List itemLayout="horizontal" dataSource={[{ name: "Exit", iconClass: "fa fa-door-open", description: "A named exit from this flow that can be used in other flows" }]} renderItem={n =>
                                <ui.List.Item actions={[<a onClick={() => this.showAddExit = true}>Add to Flow</a>]}>
                                    <ui.List.Item.Meta title={n.name} description={n.description} avatar={<ui.Avatar size="small"><i className={n.iconClass || "fa fa-tags"} /></ui.Avatar>} />
                                </ui.List.Item>} />
                        </ui.Collapse.Panel>}
                    </ui.Collapse>}
                    {this.searchQuery && <ui.List itemLayout="horizontal" dataSource={this.props.nodeSpecs.filter(n => n.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >= 0 || (n.description || "").toLowerCase().indexOf(this.searchQuery.toLowerCase()) >= 0)} renderItem={n =>
                        <ui.List.Item actions={[<a onClick={() => this.addToFlow(n)}>Add to Flow</a>]}>
                            <ui.List.Item.Meta title={n.name} description={n.description} avatar={<ui.Avatar size="small"><i className={n.iconClass || "fa fa-tags"} /></ui.Avatar>} />
                        </ui.List.Item>} />}

                    <ui.Modal visible={this.showAddExit} title="Add Exit" onCancel={() => this.showAddExit = false} onOk={() => this.addExit()}>
                        <ui.Form layout="vertical">
                            <ui.Form.Item label="Exit Name">
                                <ui.Input value={this.newExitName} onChange={ev => this.newExitName = ev.target.value} />
                            </ui.Form.Item>
                        </ui.Form>
                    </ui.Modal>
                </div>
            </OverflowPanel>
        );
    }
}
