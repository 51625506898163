import { React, dtos, serviceClient, observable, shared, observer, ui, makeObservable } from "../common";
import DashboardCalls from "./calls";
import DashboardUsage from "./usage";
import DashboardAlerts from "./alerts";
import DashboardActiveCalls from "./active";
import DashboardCallCenter from "./call-center";
import Reports from "./reports";
import AccountHeader from "../account-header";
import PageTitle from "../page-title";

@observer
export default class DashboardIndex extends React.Component {
    @observable activeTab = shared.getDefaultDashboardTab(); 

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        var tab = localStorage.getItem("Dashboard.activeTab");
        if (tab) {
            this.activeTab = tab;
        }
    }

    setTab(tab: string) {
        this.activeTab = tab;
        localStorage.setItem("Dashboard.activeTab", this.activeTab);
    }
    
    render() {
        var isCustomer = shared.isCustomer();
        return (
            <div>
                <PageTitle title="Dashboard" />
                <AccountHeader title="Dashboard" icon="fa fa-file-chart-line"  />

                <ui.Tabs activeKey={this.activeTab} onChange={t => this.setTab(t)}>
                    {!isCustomer && <ui.Tabs.TabPane key="callcenter" tab="Call Center">
                        <DashboardCallCenter accountId={shared.accountId} />
                    </ui.Tabs.TabPane>}
                    <ui.Tabs.TabPane key="reports" tab="Reports">
                        <Reports accountId={shared.accountId} />
                    </ui.Tabs.TabPane>
                    {!isCustomer && <ui.Tabs.TabPane key="usage" tab="Usage">
                        <DashboardUsage accountId={shared.accountId} />
                    </ui.Tabs.TabPane>}
                    {!isCustomer && <ui.Tabs.TabPane key="alerts" tab="Alerts">
                        <DashboardAlerts accountId={shared.accountId} />
                    </ui.Tabs.TabPane>}
                </ui.Tabs>
            </div>
        );
    }
}