import { React, serviceClient, dtos, observer, observable, ui, Link, selectFilter, shared, makeObservable, withRouter, splitIntoWords, getFlowRoles } from "../common";
import FlowDesigner from "./designer";
import PageTitle from "../page-title";
import NewCustomer from "../customers/new";
import TagsEditor from "../tags-editor";
import AutoComplete from "../autocomplete";
import FlowParams from "./params";


@observer
class FlowDetails extends React.Component {
    @observable flow: dtos.FlowInfo;
    @observable flowId = "";
    @observable name = "";
    @observable editName = "";
    @observable editNotes = "";
    @observable editDescription = "";
    @observable editRoles: dtos.FlowRoles[] = [];
    @observable customerId = "";
    @observable tags: Array<dtos.Tag> = [];

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        this.refresh((this.props as any).params.id);
    }

    async refresh(id) {
        this.flow = (await serviceClient.get(new dtos.ListFlows({ specificIds: [id], includeNodes: true }))).items[0];
        this.flowId = this.flow.id;
        this.name = this.editName = this.flow.name;
        this.editNotes = this.flow.notes;
        this.customerId = this.flow.customerId;
        this.editRoles = this.flow.roles;
        this.editDescription = this.flow.description;
        this.tags = this.flow.tags;
    }

    async patch() {
        let updated = await serviceClient.patch(new dtos.PatchFlow({
            flowId: this.flowId,
            name: this.editName,
            notes: this.editNotes,
            roles: this.editRoles,
            customerId: this.customerId,
            tagIds: this.tags.map(t => t.id),
            description: this.editDescription
        }));
        this.name = this.editName;
        ui.message.success("Flow updated successfully");
    }

    async deleteFlow() {
        await serviceClient.delete(new dtos.DeleteFlow({ flowId: this.flowId }));
        ui.message.success("Flow deleted");
        window.location.hash = "/flows";
    }

    render() {
        if (this.flow == null) return <div />;

        const startUrl = (shared.isDev ? "https://dev.evovoice.io" : "https://evovoice.io") + "/flows/start/ui?flowId=" + this.flowId;

        return (
            <div>
                <PageTitle title={this.name} />
                <ui.Breadcrumb>
                    <ui.Breadcrumb.Item><Link to="/flows">Flows</Link></ui.Breadcrumb.Item>
                    <ui.Breadcrumb.Item>{this.name}</ui.Breadcrumb.Item>
                </ui.Breadcrumb>
                {!shared.collapsed && <ui.Typography.Title><i className="fa fa-water" /> {this.name}</ui.Typography.Title>}
                <ui.Tabs defaultActiveKey="designer" animated={false}>
                    <ui.Tabs.TabPane key="designer" tab="Designer" forceRender={true}>
                        {this.flow && <FlowDesigner flow={this.flow} />}
                    </ui.Tabs.TabPane>
                    <ui.Tabs.TabPane key="params" tab="Parameters">
                        {this.flow && <FlowParams onChanged={() => this.flow.parameters = [...this.flow.parameters]} accountId={this.flow.accountId} parameters={this.flow.parameters} exits={this.flow.exits} />}
                    </ui.Tabs.TabPane>
                    <ui.Tabs.TabPane key="settings" tab="Settings">
                        <ui.Form layout="vertical">
                            <ui.Form.Item label="Name">
                                <ui.Input value={this.editName} onChange={ev => this.editName = ev.target.value} />
                            </ui.Form.Item>
                            <ui.Form.Item label="Description">
                                <ui.Input value={this.editDescription} onChange={ev => this.editDescription = ev.target.value} />
                            </ui.Form.Item>
                            <ui.Form.Item label="Roles">
                                <ui.Select mode="tags" value={this.editRoles} onChange={ev => this.editRoles = ev}>
                                    {getFlowRoles().map(t => <ui.Select.Option key={t} value={t}>{splitIntoWords(t)}</ui.Select.Option>)}
                                </ui.Select>
                            </ui.Form.Item>

                            {this.editRoles.indexOf(dtos.FlowRoles.UI) >= 0&& <ui.Form.Item label="Start URL">
                                <ui.Input value={startUrl} readOnly disabled />
                            </ui.Form.Item>}
                            <ui.Form.Item label="Customer">
                                <ui.Input.Group compact>
                                    <AutoComplete type={dtos.ValueTypes.Customer} value={this.customerId} onChanged={v => this.customerId = v} placeholder="Select a customer" />
                                </ui.Input.Group>
                            </ui.Form.Item>
                            <ui.Form.Item label="Tags">
                                <TagsEditor tags={this.tags} />
                            </ui.Form.Item>
                            <ui.Form.Item label="Notes">
                                <ui.Input.TextArea value={this.editNotes} onChange={ev => this.editNotes = ev.target.value} />
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Button type="primary" onClick={() => this.patch()}>Save</ui.Button>
                                <ui.Popconfirm title="Are you sure you want to delete this flow?" onConfirm={() => this.deleteFlow()}><a style={{ marginLeft: 30 }}>Delete Flow</a></ui.Popconfirm>
                            </ui.Form.Item>
                        </ui.Form>
                    </ui.Tabs.TabPane>
                </ui.Tabs>
            </div>
        );
    }
}

export default withRouter(FlowDetails);