import { React, serviceClient, dtos, observer, shared, observable, ui, _, Link, Observer, moment, selectFilter, splitIntoWords, makeObservable } from "../common";
import ValueEditor from "../flows/value-editor";
import AccountHeader from "../account-header";
import AutoFocus from "../autofocus";
import { KeyboardEvent } from "react";
import PageTitle from "../page-title";
import NewIntegration from "./new";
import AutoComplete from "../autocomplete";
import { GetIntegrationLog } from "../dtos";


@observer
export default class IntegrationIndex extends React.Component {
    @observable integrations: Array<dtos.IntegrationInfo> = [];
    @observable pagination: any = { position: "both" };
    @observable filter: any = {};
    @observable sort: any = {};
    @observable loading = true;
    @observable pageSize = 20;
    @observable selectedIntegrationIds: Array<string> = [];
    @observable showNewIntegration = false;

    @observable log = "";

    constructor(props) {
        super(props);
        makeObservable(this);
        this.filter.name = localStorage.getItem("IntegrationIndex.filter.name");
        this.filter.customerId = localStorage.getItem("IntegrationIndex.filter.customerId");
        this.filter.shallowParent = localStorage.getItem("IntegrationIndex.filter.shallowParent") == "true";
        this.pageSize = parseInt(localStorage.getItem("IntegrationIndex.pagination.pageSize") || "20");
    }

    async componentDidMount() {
        this.refresh(1);
    }

    async refresh(page = this.pagination.page || 1, sort = this.sort) {
        this.loading = true;
        let request = new dtos.ListIntegrations({
            accountIds: [shared.accountId],
            countPerPage: this.pageSize,
            page: page - 1,
            sortField: sort.field,
            sortOrder: sort.order,
            shallowParent: this.filter.shallowParent ? true : false,
            nameFilter: this.filter.name || null,
            customerIds: this.filter.customerId ? [this.filter.customerId] : null,
            simplifiedPaging: true,
        });
        let response = await serviceClient.get(request);
        let currentlySelected = this.integrations.filter(ep => this.selectedIntegrationIds.indexOf(ep.id) >= 0);
        this.integrations = [...currentlySelected, ...response.items.filter(c => this.selectedIntegrationIds.indexOf(c.id) < 0)];
        let pager = { ...this.pagination };
        pager.current = page;
        pager.total = page * this.pageSize + (response.hasMorePages ? 1 : 0);
        pager.pageSize = this.pageSize;
        pager.showSizeChanger = true;
        pager.pageSizeOptions = ["10", "20", "50", "100", "250"];
        pager.onShowSizeChange = (c, v) => this.pageSize = v;

        this.pagination = pager;
        this.loading = false;

        localStorage.setItem("IntegrationIndex.filter.name", this.filter.name || "");
        localStorage.setItem("IntegrationIndex.filter.shallowParent", this.filter.shallowParent ? "true" : "false");
        localStorage.setItem("IntegrationIndex.filter.customerId", this.filter.customerId || "");
        localStorage.setItem("IntegrationIndex.pagination.pageSize", this.pageSize.toString());
    }

    checkFilterSubmit(ev: KeyboardEvent) {
        if (ev.which == 13) {
            this.refresh();
        }
    }

    async deleteIntegration(it: dtos.IntegrationInfo) {
        await serviceClient.delete(new dtos.DeleteIntegration({
            integrationId: it.id
        }));
        let idx = this.integrations.indexOf(it);
        this.integrations.splice(idx, 1);
        this.forceUpdate();
        ui.message.info(it.name + " deleted");
    }

    async deleteIntegrations() {
        this.loading = true;
        for (let custId of this.selectedIntegrationIds) {
            await serviceClient.delete(new dtos.DeleteIntegration({ integrationId: custId }));
        }
        this.selectedIntegrationIds = [];
        this.refresh();
        this.loading = false;
    }

    viewLog(id: string) {
        serviceClient.get(new GetIntegrationLog({ integrationId: id })).then(r => {
            this.log = r.log;
        });
    }

    onIntegrationCreated(cust: dtos.IntegrationInfo) {
        window.location.hash = `/integrations/${cust.id}`;
        this.showNewIntegration = false;
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.selectedIntegrationIds,
            onChange: keys => this.selectedIntegrationIds = keys
        };

        return (
            <div>

                <PageTitle title="Integrations" />
                <AccountHeader title="Integrations" icon="fa fa-puzzle-piece" />
                <ui.Button.Group style={{ marginBottom: 16 }}>
                    <ui.Button type="primary" onClick={() => this.showNewIntegration = true}>New Integration</ui.Button>
                    {this.selectedIntegrationIds.length > 0 && <ui.Popconfirm title="Are you sure you want to delete the selected integrations?" onConfirm={() => this.deleteIntegrations()}><ui.Button danger>Delete Integrations</ui.Button></ui.Popconfirm>}
                </ui.Button.Group>

                <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    <ui.Form.Item>
                        <ui.Input onKeyPress={ev => this.checkFilterSubmit(ev)} placeholder="Filter by name" value={this.filter.name || ""} onChange={ev => this.filter.name = ev.target.value} />
                    </ui.Form.Item>

                    <ui.Form.Item>
                        <AutoComplete includeNotAssigned type={dtos.ValueTypes.Customer} value={this.filter.customerId} onChanged={v => { this.filter.customerId = v; this.refresh(); }} placeholder="Filter by parent customer" />
                    </ui.Form.Item>
                    {this.filter.customerId && this.filter.customerId != "_" && <ui.Form.Item style={{ marginLeft: -8 }}>
                        <ui.Tooltip title="Select this option to show only direct children of the specified customer"><ui.Button type={this.filter.shallowParent ? "primary" : "default"} onClick={() => { this.filter.shallowParent = !this.filter.shallowParent; this.refresh() }}><i className="fa fa-sitemap" /></ui.Button></ui.Tooltip>
                    </ui.Form.Item>}

                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="primary" onClick={() => this.refresh()}>Search</ui.Button>
                            <ui.Button type="default" onClick={() => { this.filter = {}; this.selectedIntegrationIds = []; this.refresh(); }}>Clear</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>
                </ui.Form>
                <ui.Table className="hide-pagination-buttons" rowSelection={rowSelection} loading={this.loading} dataSource={this.integrations} rowKey="id" pagination={this.pagination} onChange={(p, f, s) => this.refresh(p.current, s)}>
                    <ui.Table.Column dataIndex="name" title="Name" render={(text, rec: dtos.IntegrationInfo) => <>
                        <Link to={`/integrations/${rec.id}`}>{rec.name}</Link>
                    </>} />
                    <ui.Table.Column dataIndex="type" title="Type" />

                    <ui.Table.Column dataIndex="customerBreadcrumb" title="Customer" render={(text, rec: dtos.IntegrationInfo) =>
                        <ui.Breadcrumb>
                            {rec.customerBreadcrumb.map((bc, i) => <ui.Breadcrumb.Item key={bc.id}><Link to={`/customers/${bc.id}`}>{bc.name}</Link></ui.Breadcrumb.Item>)}
                        </ui.Breadcrumb>
                    } />

                    <ui.Table.Column dataIndex="status" title="Status" render={(text, rec: dtos.IntegrationInfo) => <>
                        {rec.status === dtos.IntegrationStatuses.Error && <ui.Tag color="red">Error: {rec.statusMessage}</ui.Tag>}
                        {rec.status === dtos.IntegrationStatuses.NotConfigured && <ui.Tag color="orange">Not Configured</ui.Tag>}
                        {rec.status === dtos.IntegrationStatuses.OK && <span><ui.Tag color="blue">{rec.statusMessage || "OK"}</ui.Tag></span>}
                        <ui.Button size="small" onClick={() => this.viewLog(rec.id)}>View Log...</ui.Button>
                    </>} />

                    <ui.Table.Column title="Last Modified" render={(text, rec: dtos.IntegrationInfo) => <span>{moment(rec.dateLastModified).format("LLLL")} by {rec.lastModifiedBy}</span>} />
                    <ui.Table.Column title="Last Run" render={(text, rec: dtos.IntegrationInfo) => <span>{rec.dateLastSync && moment(rec.dateLastSync).format("LLLL")}</span>} />

                </ui.Table>

                <NewIntegration defaultCustomerId={this.filter.customerId} visible={this.showNewIntegration} onClosed={() => this.showNewIntegration = false} onCreated={cust => this.onIntegrationCreated(cust)} />

                <ui.Modal title="Integration Log" okButtonProps={{ hidden: true }} cancelText="Close" open={this.log.length > 0} onCancel={() => this.log = ""}>
                    <ui.Input.TextArea rows={15} style={{ background: "#f0f0f0" }} value={this.log} readOnly />
                </ui.Modal>
            </div>

        );
    }
}