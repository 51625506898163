import { React, ui, serviceClient, dtos, observable, observer, makeObservable } from "./common";

@observer
class RecoverAccountBase extends React.Component {
    @observable email = "";
    @observable newPassword = "";
    @observable confirmNewPassword = "";
    @observable token = "";

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    getQueryVariable(variable) {
        var query = (this.props as any).window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    }

    componentDidMount() {
        this.email = this.getQueryVariable("email");
        this.token = this.getQueryVariable("token");
    }

    async recoverPassword() {
        if (!this.newPassword) {
            ui.message.error("Enter a new password");
            return;
        }

        if (this.newPassword != this.confirmNewPassword) {
            ui.message.error("Passwords do not match");
            return;
        }

        let request = new dtos.RecoverPassword();
        request.emailAddress = this.email;
        request.token = this.token;
        request.newPassword = this.newPassword;
        await serviceClient.post(request);
        await serviceClient.post(new dtos.Authenticate({ provider: "credentials", userName: this.email, password: this.newPassword }));
        ui.message.success("Password reset and you are now signed in");
        window.location.hash = "/";
    }

    render() {
        const footer =
            <span>
                <a style={{ marginRight: 15 }} onClick={() => window.location.hash = "/"}>Sign In</a>
                <ui.Button key="submit" type="primary" onClick={() => this.recoverPassword()}>Reset Password</ui.Button>
            </span>;
        return (
            <div>
                <ui.Modal title="Reset Password" visible={true} onOk={() => this.recoverPassword()} cancelButtonProps={{ disabled: true }} footer={footer}>
                    <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.recoverPassword()}>
                        <ui.Form.Item label="Email Address">
                            <ui.Input type="text" value={this.email} prefix={<i className="fa fa-envelope" />} readOnly />
                        </ui.Form.Item>
                        <ui.Form.Item label="New Password">
                            <ui.Input type="password" value={this.newPassword} prefix={<i className="fa fa-lock" />} placeholder="Enter new password" onChange={ev => this.newPassword = ev.target.value} onPressEnter={() => this.recoverPassword()} />
                        </ui.Form.Item>
                        <ui.Form.Item label="Confirm Password">
                            <ui.Input type="password" value={this.confirmNewPassword} prefix={<i className="fa fa-lock" />} placeholder="Confirm new password" onChange={ev => this.confirmNewPassword = ev.target.value} onPressEnter={() => this.recoverPassword()} />
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>
            </div>
        );
    }
}

const RecoverAccount = RecoverAccountBase;
export default RecoverAccount;