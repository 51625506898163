import * as React from "react";
import { Route, Routes, Link, useMatch, useParams, useLocation } from "react-router-dom";
import { JsonServiceClient } from "@servicestack/client";
import * as moment from "moment";
import * as dtos from "./dtos";
import * as ui from "antd";
import * as _ from "lodash";
import { observer, Observer, } from "mobx-react";
import { observable, action, runInAction, observe, makeObservable, configure } from "mobx";
import Cache from "./cache";

export function withRouter(InnerComponent) {
    return function Component(props) {
        const ps = useParams();
        const location = useLocation();
        return <InnerComponent {...props} params={ps} location={location} />;
    }
}

configure({ enforceActions: "never" });

let serviceClient: JsonServiceClient = null;

function initServiceClient(apiUrl: string) {
    serviceClient = new JsonServiceClient(apiUrl);
    serviceClient.exceptionFilter = (res, error) => {
        if (error.responseStatus.errorCode != "401" && error.responseStatus.errorCode != "403") {
            ui.message.error(error.responseStatus.message, 5);
        }

        if (error.responseStatus.errorCode == "403" && serviceClient.onAuthenticationRequired != null) {
            serviceClient.onAuthenticationRequired();
        }
    };
}

initServiceClient("");

function clone<T>(v: any) {
    return JSON.parse(JSON.stringify(v));
}

function moveArray(cmp: React.Component, array: Array<any>, obj: any, dir: number) {
    let idx = array.indexOf(obj);
    if (idx < 0) return;
    if (dir == -1 && idx == 0) return;
    if (dir == +1 && idx == array.length - 1) return;
    var newIdx = idx + dir;
    array.splice(idx, 1);
    array.splice(newIdx, 0, obj);
    cmp.forceUpdate();
}

function removeArray(cmp: React.Component, array: Array<any>, obj: any) {
    let idx = array.indexOf(obj);
    if (idx < 0) return;
    array.splice(idx, 1);
    cmp.forceUpdate();
}

function splitIntoWords(text: string) {
    if (!text) return "";
    if (text.length <= 2) return text;

    const separator = " ";
    var words = "";
    var lastChar = ' ';
    var isLetter = (c: string) => c[0].toLowerCase() >= 'a' && c[0].toLowerCase() <= 'z';
    var isDigit = (c: string) => c[0] >= '0' && c[0] <= '9';
    var isUpper = (c: string) => c[0] == c[0].toUpperCase();

    for (var c of text) {
        if (c == '_') continue;

        if (words.length > 0 && ((isDigit(c) && isLetter(lastChar)) || (isLetter(c) && isUpper(c) && !isUpper(lastChar)))) {
            words += separator;
        }

        words += c;
        lastChar = c;
    }
    return words;
}

function validateParamName(name: string): boolean {
    if (name.indexOf(" ") >= 0) return false;
    if (name.indexOf(":") >= 0) return false;
    return true;
}

function uuid() {
    return Math.random().toString(36).substr(2, 9);
}

function humanFileSize(bytes, si) {
    if (bytes == 0) return "";
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    var units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}

class Shared {
    accountId = "";
    accountName = "";
    hasMultipleAccounts = false;
    roles: string[] = [];

    isDev = false;
    dashboardPermissions: Array<dtos.DashboardPermissions> = [];
    @observable collapsed = false;

    getHomePage() {
        if (this.canViewEndpoints()) return "/endpoints";
        return "/system-settings";
        // // shared.isCustomer() ? "/endpoints" : "/dashboard";
    }

    getDefaultDashboardTab() {
        return this.isCustomer() ? "reports" : "reports";
    }

    getDefaultSettingsTab() {
        if (this.canViewSettingsTab()) return "settings";
        if (this.canViewFilesTab()) return "files";
        if (this.canViewNotificationsTab()) return "notifications";
        if (this.canViewReportsTab()) return "reports";
        if (this.canViewSessionsTab()) return "sessions";
        return "";
    }

    isSystemAdmin() {
        return this.roles.indexOf("SystemAdministrator") >= 0;
    }

    isManager() {
        return this.isSystemAdmin() || this.roles.indexOf("Manager") >= 0 || this.roles.indexOf("Customer") >= 0;
    }

    isUser() {
        return this.roles.indexOf("User") >= 0;
    }

    isCustomer() {
        return this.roles.indexOf("Customer") >= 0;
    }

    isSimplified() {
        return this.roles.indexOf("CustomerSimplified") >= 0;
    }

    isCustomUser() {
        return this.isCustomer() && this.roles.indexOf("CustomerCustom") >= 0;
    }

    canViewDashboard() {
        return !this.isCustomer();
    }

    canViewEndpoints() {
        if (this.isCustomUser()) return this.dashboardPermissions.indexOf(dtos.DashboardPermissions.ViewEndpoints) >= 0;
        return !this.isSimplified();
    }

    canViewCustomers() {
        if (this.isCustomUser()) return this.dashboardPermissions.indexOf(dtos.DashboardPermissions.ViewCustomers) >= 0;
        return !this.isSimplified();
    }

    canViewIntegrations() {
        return this.isManager();
    }

    canViewFlows() {
        if (this.isCustomUser()) return this.dashboardPermissions.indexOf(dtos.DashboardPermissions.ViewFlows) >= 0;
        return !this.isSimplified();
    }

    canViewDocumentation() {
        return !this.isCustomer();
    }

    canViewAccounts() {
        return this.isSystemAdmin();
    }

    canViewSettings() {
        return true;
    }

    canViewSettingsTab() {
        return !this.isCustomer();
    }

    canViewFilesTab() {
        if (this.isCustomUser()) return this.dashboardPermissions.indexOf(dtos.DashboardPermissions.ViewFiles) >= 0;
        const noFileAccess = this.roles.indexOf("NoVoicemailFileAccess") >= 0;
        if (noFileAccess) return false;
        return true;
    }

    canViewReportsTab() {
        if (this.isCustomUser()) return this.dashboardPermissions.indexOf(dtos.DashboardPermissions.ViewReports) >= 0;
        return !this.isSimplified();
    }

    canViewNotificationsTab() {
        if (this.isCustomUser()) return this.dashboardPermissions.indexOf(dtos.DashboardPermissions.ViewNotifications) >= 0;
        return true;
    }

    canViewSessionsTab() {
        if (this.isCustomUser()) return this.dashboardPermissions.indexOf(dtos.DashboardPermissions.ViewSessions) >= 0;
        return true;

    }
}

let shared = new Shared();
let selectFilter = (input, option) => {
    var text = option.props.title || (option.props.children as any).toString();
    return text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

const getOffsetTop = element => {
    let offsetTop = 0;
    while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }
    return offsetTop;
}

const cache = new Cache();


function getEndpointIcon(ep: dtos.EndpointTypes) {
    if (ep == dtos.EndpointTypes.PhoneNumber) return "fa fa-hashtag";
    if (ep == dtos.EndpointTypes.User) return "fa fa-user";
    if (ep == dtos.EndpointTypes.FaxNumber) return "fa fa-fax";
    if (ep == dtos.EndpointTypes.EmailAddress) return "fa fa-at";
    if (ep == dtos.EndpointTypes.Team) return "fa fa-users";
    if (ep == dtos.EndpointTypes.Assistant) return "fa fa-message-bot";
    return "fa fa-question";
}

function getFlowRoles() : dtos.FlowRoles[] {
    return Object.keys(dtos.FlowRoles).filter(r => [dtos.FlowRoles.UI].indexOf(r as any) < 0) as dtos.FlowRoles[];
}


declare global {         
    interface Array<T> {                     
       with(index: number, value:T): [T];
    }
 }
 
export { React, Route, Link, Routes, moment, getFlowRoles, getEndpointIcon, runInAction, serviceClient, initServiceClient, dtos, ui, _, clone, moveArray, removeArray, humanFileSize, observer, Observer, observable, observe, action, makeObservable, uuid, shared, splitIntoWords, validateParamName, selectFilter, getOffsetTop, cache }