import { React, serviceClient, dtos, observer, observable, shared, ui, _, Link, Observer, splitIntoWords, moment, selectFilter, uuid, makeObservable, cache, getEndpointIcon } from "../common";
import AccountHeader from "../account-header";
import ValueEditor from "../flows/value-editor";
import AutoFocus from "../autofocus";
import PageTitle from "../page-title";
import NewCustomer from "../customers/new";
import TagsEditor from "../tags-editor";
import ImportWindow from "../import-window";
import ScrollPane from "../scroll-pane";
import PhoneNumberTester from "./number-tester";
import AutoComplete from "../autocomplete";
import FlowParamEditor from "../flows/param-editor";

interface BulkUpdateFlowRow {
    id: string;
    flowParam: dtos.FlowParameter,
    update: boolean
    value: dtos.Value
};

interface BulkUpdateEndpointRow {
    id: string;
    field: dtos.EndpointDataField,
    update: boolean
    value: dtos.Value
};


@observer
export default class EndpointIndex extends React.Component {
    @observable endpoints: Array<dtos.EndpointInfo> = [];
    @observable pagination: any = { position: "both", total: 0 };
    @observable sort: any = {};
    @observable filter: any = {};
    @observable searching = false;
    @observable availableCountries: Array<dtos.AvailableCountryInfo> = [];
    @observable endpointFields: Array<dtos.EndpointDataField> = [];
    @observable loading = true;
    @observable pageSize = 20;
    @observable dataFilters = new dtos.Struct();

    @observable showBuy = false;
    @observable isFax = false;
    @observable addresses: Array<dtos.AddressInfo> = [];
    @observable searchAreaCode = "";
    @observable searchPostalCode = "";
    @observable searchCountryCode = "US";
    @observable searchContains = "";
    @observable searchResults: Array<dtos.PhoneNumberInfo> = [];
    @observable newEndpointCustomerId = "";
    @observable bulkPurchaseCustomerId = "";
    @observable showAdvancedFilters = false;
    @observable newPhoneNumberAddressSid = "";
    @observable newPhoneNumberVirtual = false;
    @observable newVirtualPhoneNumber = "";
    @observable flows: Array<dtos.FlowInfo> = [];

    @observable showNewUser = false;
    @observable newUserEmail = "";
    @observable newUserPassword = "";
    @observable newUserFirstName = "";
    @observable newUserLastName = "";
    @observable newUserMode = dtos.UserModes.SoftPhone;

    @observable newTags: Array<dtos.Tag> = [];
    @observable newData = new dtos.Struct();

    @observable newFlowId = "";
    @observable newFlowParams = new dtos.Struct();
    @observable newFlowParameters: Array<dtos.FlowParameter> = [];

    @observable showNewEmail = false;
    @observable newEmailPrefix = "";

    @observable showNewTeam = false;
    @observable newTeamName = "";

    @observable showNewAssistant = false;
    @observable newAssistantName = "";

    @observable selectedEndpointIds: Array<string> = [];
    @observable assignToCustomerId = "";
    @observable assignToFlowId = "";
    @observable assignToTagId = "";

    @observable tags: Array<dtos.Tag> = [];
    @observable allTags: Array<dtos.Tag> = [];
    @observable showImport = false;

    @observable showForward = false;
    @observable forwardDirection = "forward";
    @observable cancelForward = false;
    @observable forwardLog = "";
    @observable forwardTags: Array<dtos.Tag> = [];
    @observable forwardCustomerId = "";
    @observable forwarding = false;
    @observable forwardField = "";
    @observable forwardingDone = false;

    @observable showBulkPurchase = false;
    @observable numToPurchase = 25;
    @observable bulkPurchasing = false;
    @observable cancelBulkPurchase = false;
    @observable bulkPurchaseLog = "";
    @observable bulkPurchaseIsFaxNumber = false;
    @observable currentType = "";

    @observable showNumberTester = false;
    @observable numbersToTest: dtos.EndpointInfo[] = [];

    @observable ss: dtos.SystemSettingsInfo = null;

    @observable showNewCustomer = false;

    @observable superSearchId = "";

    @observable showBulkUpdateFlow = false;
    @observable bulkUpdateRows: Array<BulkUpdateFlowRow> = [];
    @observable bulkUpdateFlow: dtos.FlowInfo = null;

    @observable showBulkUpdate = false;
    @observable bulkUpdateEndpointRows: Array<BulkUpdateEndpointRow> = [];

    @observable showBulkUpdate911 = false;
    @observable bulkUpdateNumberId = "";

    @observable showFlowFilterWindow = false;
    @observable flowFilter: dtos.Struct;
    @observable filterFlow: dtos.FlowInfo;

    constructor(props) {
        super(props);
        makeObservable(this);
        this.filter.type = localStorage.getItem("EndpointIndex.filter.type");
        this.filter.userMode = localStorage.getItem("EndpointIndex.filter.userMode");
        this.filter.customerId = localStorage.getItem("EndpointIndex.filter.customerId");
        this.filter.name = localStorage.getItem("EndpointIndex.filter.name");
        this.filter.flowId = localStorage.getItem("EndpointIndex.filter.flowId");
        this.pageSize = parseInt(localStorage.getItem("EndpointIndex.pagination.pageSize") || "20");
        this.showAdvancedFilters = localStorage.getItem("EndpointIndex.showAdvancedFilters") == "true";
        this.filter.shallowParent = localStorage.getItem("EndpointIndex.filter.shallowParent") == "true";
        this.filter.flowState = localStorage.getItem("EndpointIndex.filter.flowState");
        var tagJson = localStorage.getItem("EndpointIndex.filter.tags");
        this.tags = tagJson ? JSON.parse(tagJson) : [];
        
        var dataFilterJson = localStorage.getItem("EndpointIndex.filter.dataFilters");
        this.dataFilters = dataFilterJson ? JSON.parse(dataFilterJson) : new dtos.Struct();
        var flowFilterJson = localStorage.getItem("EndpointIndex.filter.flowFilter");
        this.flowFilter = flowFilterJson ? JSON.parse(flowFilterJson) : null;
    }

    async componentDidMount() {
        this.availableCountries = await cache.getCountries();
        let systemSettings = await cache.getSystemSettings();
        this.flows = (await cache.getFlows()).items;
        this.ss = systemSettings;
        this.allTags = systemSettings.tags;
        this.endpointFields = systemSettings.endpointFields;
        this.addresses = await cache.getAddresses();
        this.newData = new dtos.Struct();
        for (let field of this.endpointFields) {
            this.newData[field.name] = field.defaultValue || new dtos.Value();
        }
        this.refresh(1, {});
    }

    async refresh(page = this.pagination.page || 1, sort = this.sort) {
        this.loading = true;
        let request = new dtos.ListEndpoints({
            accountIds: [shared.accountId],
            customerIds: this.filter.customerId ? [this.filter.customerId] : null,
            nameFilter: this.filter.name,
            flowIds: this.filter.flowId ? [this.filter.flowId] : null,
            dataFilters: Object.keys(this.dataFilters).map(fn => `${fn}=${this.dataFilters[fn].stringValue}`),
            countPerPage: this.pageSize,
            page: page - 1,
            sortField: sort.field,
            flowState: this.filter.flowState,
            sortOrder: sort.order,
            type: this.filter.type,
            shallowParent: this.filter.shallowParent,
            userMode: this.filter.userMode,
            tagIds: this.tags.length > 0 ? this.tags.map(t => t.id) : null,
            sipUserName: this.filter.sipUserName,
            flowParametersFilter: this.flowFilter ? JSON.stringify(this.flowFilter) : null,
            simplifiedPaging: true
        });
        let response = await serviceClient.get(request);
        let currentlySelected = this.endpoints.filter(ep => this.selectedEndpointIds.indexOf(ep.id) >= 0);
        this.endpoints = [...currentlySelected, ...response.items.filter(ep => this.selectedEndpointIds.indexOf(ep.id) < 0)];
        this.currentType = this.filter.type;
        let pager = { ...this.pagination };
        pager.current = page;
        pager.total = page * this.pageSize + (response.hasMorePages ? 1 : 0)
        pager.pageSize = request.countPerPage;
        pager.showSizeChanger = true;
        pager.pageSizeOptions = ["10", "20", "50", "100", "250"];
        pager.onShowSizeChange = (c, v) => this.pageSize = v;
        this.sort = sort;
        this.pagination = pager;
        this.loading = false;

        if (this.filter.customerId) {
            this.newEndpointCustomerId = this.filter.customerId;
        }

        localStorage.setItem("EndpointIndex.filter.type", this.filter.type || "");
        localStorage.setItem("EndpointIndex.filter.userMode", this.filter.userMode || "");
        localStorage.setItem("EndpointIndex.filter.name", this.filter.name || "");
        localStorage.setItem("EndpointIndex.filter.customerId", this.filter.customerId || "");
        localStorage.setItem("EndpointIndex.filter.flowId", this.filter.flowId || "");
        localStorage.setItem("EndpointIndex.pagination.pageSize", this.pageSize.toString());
        localStorage.setItem("EndpointIndex.filter.tags", JSON.stringify(this.tags));
        localStorage.setItem("EndpointIndex.filter.dataFilters", JSON.stringify(this.dataFilters));
        localStorage.setItem("EndpointIndex.showAdvancedFilters", this.showAdvancedFilters ? "true" : "");
        localStorage.setItem("EndpointIndex.filter.shallowParent", this.filter.shallowParent ? "true" : "false");
        localStorage.setItem("EndpointIndex.filter.flowState", this.filter.flowState || "");
        localStorage.setItem("EndpointIndex.filter.flowFilter", this.flowFilter ? JSON.stringify(this.flowFilter) : "");
    }

    async searchPhoneNumbers() {
        this.searching = true;
        let request = new dtos.SearchPhoneNumbers({
            accountId: shared.accountId,
            countryCode: this.searchCountryCode,
            postalCode: this.searchPostalCode,
            areaCode: this.searchAreaCode,
            isFaxNumber: this.isFax,
            contains: this.searchContains || null
        });
        this.searchResults = await serviceClient.get(request);
        this.searching = false;
    }

    async endpointPost(request) {
        let newEndpoint = await serviceClient.post(request) as any;
        window.location.hash = "/endpoints/" + newEndpoint.id;
    }

    async buyPhoneNumber(pn: dtos.PhoneNumberInfo) {
        let request = new dtos.BuyPhoneNumber({
            accountId: shared.accountId,
            customerId: this.newEndpointCustomerId,
            number: pn.phoneNumber as string,
            isFaxNumber: this.isFax,
            tagIds: this.newTags.map(t => t.id),
            data: this.newData,
            flowId: this.newFlowId,
            addressSid: this.newPhoneNumberAddressSid,
            flowParams: this.newFlowParams
        });
        this.endpointPost(request);
    }

    async saveVirtualNumber() {
        let request = new dtos.BuyPhoneNumber({
            accountId: shared.accountId,
            customerId: this.newEndpointCustomerId,
            number: this.newVirtualPhoneNumber,
            isVirtualPhoneNumber: true,
            tagIds: this.newTags.map(t => t.id),
            data: this.newData,
            flowId: this.newFlowId,
            addressSid: this.newPhoneNumberAddressSid,
            flowParams: this.newFlowParams
        });
        this.endpointPost(request);
    }

    async newUser() {
        let request = new dtos.NewUserEndpoint({
            accountId: shared.accountId,
            emailAddress: this.newUserEmail,
            firstName: this.newUserFirstName,
            lastName: this.newUserLastName,
            password: this.newUserPassword,
            customerId: this.newEndpointCustomerId,
            userMode: this.newUserMode,
            tagIds: this.newTags.map(t => t.id),
            data: this.newData,
            flowId: this.newFlowId,
            flowParams: this.newFlowParams
        });
        this.endpointPost(request);
    }

    async newTeam() {
        let request = new dtos.NewTeamEndpoint({
            accountId: shared.accountId,
            name: this.newTeamName,
            customerId: this.newEndpointCustomerId,
            tagIds: this.newTags.map(t => t.id),
            data: this.newData,
        });

        this.endpointPost(request);
    }

    async newAssistant() {
        let request = new dtos.NewAssistantEndpoint({
            accountId: shared.accountId,
            name: this.newAssistantName,
            customerId: this.newEndpointCustomerId,
            tagIds: this.newTags.map(t => t.id),
            data: this.newData,
        });

        this.endpointPost(request);
    }

    async newEmail() {
        let request = new dtos.NewEmailEndpoint({
            accountId: shared.accountId,
            prefix: this.newEmailPrefix,
            customerId: this.newEndpointCustomerId,
            tagIds: this.newTags.map(t => t.id),
            data: this.newData,
            flowId: this.newFlowId,
            flowParams: this.newFlowParams
        });

        this.endpointPost(request);
    }

    getEndpointDataText(cust: dtos.EndpointInfo, f: dtos.EndpointDataField) {
        var val = cust.data[f.name] as dtos.Value;
        if (!val) return <span />;
        return <ValueEditor readOnly field={f} value={val} accountId={shared.accountId} valueType={f.type} />;
    }

    showBuyNumbers(isFax: boolean) {
        this.showBuy = true;
        this.isFax = isFax;
    }

    async assignToCustomer() {
        this.loading = true;
        for (let id of this.selectedEndpointIds) {
            let updated = await serviceClient.patch(new dtos.PatchEndpoint({
                endpointId: id,
                customerId: this.assignToCustomerId == "_" ? "" : this.assignToCustomerId
            }));
            Object.assign(_.find(this.endpoints, ep => ep.id == id), updated);
        }
        this.refresh();
    }

    async assignToFlow() {
        this.loading = true;
        for (let id of this.selectedEndpointIds) {
            let updated = await serviceClient.patch(new dtos.PatchEndpoint({
                endpointId: id,
                flowId: this.assignToFlowId == "_" ? "" : this.assignToFlowId
            }));
            Object.assign(_.find(this.endpoints, ep => ep.id == id), updated);
        }
        this.refresh();
    }

    async assignToTag() {
        this.loading = true;
        for (let id of this.selectedEndpointIds) {
            let ep = _.find(this.endpoints, e => e.id == id);
            let tagIds = ep.tags.map(t => t.id);
            if (tagIds.indexOf(this.assignToTagId) < 0) {
                tagIds.push(this.assignToTagId);
            }
            let updated = await serviceClient.patch(new dtos.PatchEndpoint({
                endpointId: id,
                tagIds: tagIds
            }));
            Object.assign(_.find(this.endpoints, ep => ep.id == id), updated);
        }
        this.refresh();
    }

    async removeFromTag() {
        this.loading = true;
        for (let id of this.selectedEndpointIds) {
            let ep = _.find(this.endpoints, e => e.id == id);
            let tagIds = ep.tags.map(t => t.id);
            let idx = tagIds.indexOf(this.assignToTagId);
            if (idx >= 0) {
                tagIds.splice(idx, 1);
                let updated = await serviceClient.patch(new dtos.PatchEndpoint({
                    endpointId: id,
                    tagIds: tagIds
                }));
                Object.assign(_.find(this.endpoints, ep => ep.id == id), updated);

            }
        }
        this.refresh();
    }


    async syncPhoneNumbers() {
        this.loading = true;
        var response = await serviceClient.post(new dtos.SyncPhoneNumbers({ accountId: shared.accountId }));
        ui.message.info("Phone numbers synced successfully.\r\n" + response.newPhoneNumbers.length + " new phone numbers\r\n" + response.updatedPhoneNumbers.length + " updated phone numbers\r\n");
        this.refresh();
    }

    async deleteEndpoints() {
        this.loading = true;
        try {
            for (let id of this.selectedEndpointIds) {
                let request = new dtos.DeleteEndpoint({
                    endpointId: id
                });
                await serviceClient.delete(request);
            }
        } finally {
            this.loading = false;
        }
        this.selectedEndpointIds = [];
        this.refresh();
    }

    async clearFlow() {
        this.loading = true;
        try {
            for (let id of this.selectedEndpointIds) {
                let request = new dtos.PatchEndpoint({
                    endpointId: id,
                    flowId: ""
                });
                let updated = await serviceClient.patch(request);
                this.endpoints.find(ep => ep.id == id).flowId = "";
                this.endpoints.find(ep => ep.id == id).flowName = "";
            }
        } finally {
            this.loading = false;
        }
        this.selectedEndpointIds = [];
        this.refresh();
    }

    async forwardForward() {
        const log = (message: string) => {
            this.forwardLog += message + "\r\n";
            document.getElementById("_forward_log").scrollTop = document.getElementById("_forward_log").scrollHeight;
        };

        try {
            this.forwardingDone = false;
            this.loading = true;
            this.forwardLog = "";
            let result = await serviceClient.get(new dtos.ListEndpoints({
                accountIds: [shared.accountId],
                type: dtos.EndpointTypes.PhoneNumber,
                tagIds: this.forwardTags.map(t => t.id),
                customerIds: this.forwardCustomerId ? [this.forwardCustomerId] : ["_"],
                flowIds: ["_"],
                all: true
            }));

            var forwardNumbers = result.items;
            var curr = 0;
            var updatedNumbers = [];
            var originalNumbers = [];
            log("Found " + forwardNumbers.length + " phone numbers that are not assigned to a flow and can be used");
            if (forwardNumbers.length < this.selectedEndpointIds.length) {
                log("ERROR: could not find enough phone numbers that are unassigned. Free up some phone numbers or purchase more");
                ui.message.error("Could not find enough phone numbers that are unassigned. Free up some phone numbers or purchase more");
                return;
            }

            for (let id of this.selectedEndpointIds) {
                let ep = _.find(this.endpoints, e => e.id == id);
                if (!ep) {
                    log("Error. Could not find selected endpoint");
                    break;
                }

                if (ep.type != dtos.EndpointTypes.PhoneNumber) {
                    log("Unsupported endpoint. Only phone numbers can have forwarding setup");
                    continue;
                }

                var forwardTo = forwardNumbers[curr];
                log("Setting up forwarding for " + ep.phoneNumber + ". Forwarding number: " + forwardTo.phoneNumber);
                var data = forwardTo.data;
                if (this.forwardField) {
                    data[this.forwardField] = new dtos.Value({ stringValue: ep.phoneNumber });
                }

                let updatedNumber = await serviceClient.patch(new dtos.PatchEndpoint({
                    endpointId: forwardTo.id,
                    customerId: ep.customerId,
                    data: data,
                    flowId: ep.flowId,
                    flowParams: ep.flowParams,
                }));
                originalNumbers.push(ep);
                updatedNumbers.push(updatedNumber);
                curr++;
            }
            this.endpoints = [...updatedNumbers, ...originalNumbers];
            this.selectedEndpointIds = updatedNumbers.map(n => n.id);
            ui.message.success("Configured unassigned phone numbers the same as the selected numbers. The unassigned numbers are now selected");
        } finally {
            this.loading = false;
            this.forwarding = false;
            this.forwardingDone = true;
        }
    }

    async forwardBackward() {
        const log = (message: string) => {
            this.forwardLog += message + "\r\n";
            document.getElementById("_forward_log").scrollTop = document.getElementById("_forward_log").scrollHeight;
        };

        try {
            this.loading = true;
            this.forwardLog = "";
            this.forwardingDone = false;
            if (!this.forwardField) {
                log("ERROR: Must select a Dialed Number field");
                return;
            }

            var updatedNumbers = [];
            var originalNumbers = [];

            for (let id of this.selectedEndpointIds) {
                let ep = _.find(this.endpoints, e => e.id == id);
                if (!ep) {
                    log("Error. Could not find selected endpoint");
                    break;
                }

                if (ep.type != dtos.EndpointTypes.PhoneNumber) {
                    log("Unsupported endpoint. Only phone numbers can have forwarding setup");
                    break;
                }

                var dialedNumberField = ep.data[this.forwardField];
                if (dialedNumberField == null || !dialedNumberField.stringValue) {
                    log("Error: Could not find a dialed number for " + ep.phoneNumber);
                    continue;
                }

                let results = await serviceClient.get(new dtos.ListEndpoints({
                    accountIds: [shared.accountId],
                    type: dtos.EndpointTypes.PhoneNumber,
                    phoneNumberFilter: dialedNumberField.stringValue
                }));

                if (results.items.length != 1) {
                    log("Error: Could not find a phone number matching dialed number: " + dialedNumberField.stringValue);
                    continue;
                }

                var pn = results.items[0];
                try {
                    let updatedNumber = await serviceClient.patch(new dtos.PatchEndpoint({
                        endpointId: pn.id,
                        customerId: ep.customerId,
                        flowId: ep.flowId,
                        flowParams: ep.flowParams,
                    }));
                } catch (err) {
                    continue;
                }

                log("Configured phone number " + pn.phoneNumber + " to be the same as " + ep.phoneNumber);
                originalNumbers.push(ep);
                updatedNumbers.push(pn);
            }
            this.endpoints = [...updatedNumbers, ...originalNumbers];
            this.selectedEndpointIds = updatedNumbers.map(n => n.id);
            ui.message.success("Configured phone numbers to be the same as the temp numbers. The updated phone numbers are selected");
        } finally {
            this.loading = false;
            this.forwarding = false;
            this.forwardingDone = true;
        }
    }

    async forwardUnassigned() {
        if (this.forwardDirection == "forward") {
            await this.forwardForward();
        } else if (this.forwardDirection == "backward") {
            await this.forwardBackward();
        } else if (this.forwardDirection == "cleanup") {
            await this.forwardCleanup();
        }
    }

    async forwardCleanup() {
        const log = (message: string) => {
            this.forwardLog += message + "\r\n";
            document.getElementById("_forward_log").scrollTop = document.getElementById("_forward_log").scrollHeight;
        };

        try {
            this.loading = true;
            this.forwardLog = "";
            this.forwardingDone = false;
            if (!this.forwardField) {
                log("ERROR: Must select a Dialed Number field");
                return;
            }

            var safeNumbersToDelete = [];
            var originalNumbers = [];

            for (let id of this.selectedEndpointIds) {
                let ep = _.find(this.endpoints, e => e.id == id);
                if (!ep) {
                    log("Error. Could not find selected endpoint");
                    break;
                }

                originalNumbers.push(ep);
                if (ep.type != dtos.EndpointTypes.PhoneNumber) {
                    log("Unsupported endpoint. Only phone numbers can have forwarding setup");
                    break;
                }

                var dialedNumberField = ep.data[this.forwardField];
                if (dialedNumberField == null || !dialedNumberField.stringValue) {
                    log("Error: Could not find a dialed number for " + ep.phoneNumber + " so cannot confirm if this number is in use.");
                    break;
                }

                let results = await serviceClient.get(new dtos.ListEndpoints({
                    accountIds: [shared.accountId],
                    type: dtos.EndpointTypes.PhoneNumber,
                    phoneNumberFilter: dialedNumberField.stringValue
                }));

                if (results.items.length != 1) {
                    log("Error: Could not find a phone number matching dialed number: " + dialedNumberField.stringValue);
                    continue;
                } else {
                    log("Found a corresponding phone number for test number " + ep.phoneNumber + " --> " + results.items[0].phoneNumber);
                }

                safeNumbersToDelete.push(ep);
                originalNumbers.splice(originalNumbers.length - 1, 1);
            }

            this.endpoints = [...safeNumbersToDelete, ...originalNumbers];
            this.selectedEndpointIds = safeNumbersToDelete.map(n => n.id);
            ui.message.success("Phone numbers which are safe to delete have been checked");
        } finally {
            this.loading = false;
            this.forwarding = false;
            this.forwardingDone = true;
        }
    }

    showForwarding() {
        this.showForward = true;
        this.forwardingDone = false;
        this.forwardLog = "";
        this.forwardField = "";
        this.forwardCustomerId = "";
    }

    async bulkPurchase() {
        const log = (message: string) => {
            this.bulkPurchaseLog += message + "\r\n";
            document.getElementById("_bulk_log").scrollTop = document.getElementById("_bulk_log").scrollHeight;
        };

        try {
            var numbersPurchased: dtos.EndpointInfo[] = [];
            this.loading = true;
            this.bulkPurchaseLog = "";
            this.bulkPurchasing = true;
            this.cancelBulkPurchase = false;
            this.forceUpdate();
            log("Purchasing " + this.numToPurchase + " phone numbers");
            for (var i = 0; i < this.numToPurchase; i++) {
                let response = await serviceClient.get(new dtos.SearchPhoneNumbers({
                    accountId: shared.accountId,
                    countryCode: this.searchCountryCode,
                    postalCode: this.searchPostalCode,
                    areaCode: this.searchAreaCode,
                    isFaxNumber: this.bulkPurchaseIsFaxNumber
                }));
                if (response.length == 0) {
                    log("No numbers found to purchase");
                    break;
                }

                log("Purchasing phone number (" + (i + 1) + ") " + response[0].phoneNumber);
                let request = new dtos.BuyPhoneNumber({
                    accountId: shared.accountId,
                    customerId: this.bulkPurchaseCustomerId == "_" ? "" : this.bulkPurchaseCustomerId,
                    number: response[0].phoneNumber as string,
                    isFaxNumber: false,
                    tagIds: this.newTags.map(t => t.id)
                });

                let newPhoneNumber = await serviceClient.post(request);
                numbersPurchased.push(newPhoneNumber);

                if (this.cancelBulkPurchase) {
                    log("Bulk purchase cancelled. Numbers already purchased must be released manually");
                    break;
                }
            }

            this.endpoints = [...numbersPurchased, ...this.endpoints];
            this.selectedEndpointIds = numbersPurchased.map(pn => pn.id);
            ui.message.success("Purchased " + this.numToPurchase + " phone numbers");
            this.showBulkPurchase = false;
        } finally {
            this.bulkPurchasing = false;
            this.loading = false;
        }

    }
    doCancelBulkPurchase() {
        if (this.bulkPurchasing) {
            this.cancelBulkPurchase = true;
        } else {
            this.showBulkPurchase = false;
        }
    }

    checkFilterSubmit(ev: React.KeyboardEvent) {
        if (ev.which == 13) {
            this.refresh();
        }
    }

    getDataFields(type: dtos.EndpointTypes) {
        let fields = this.endpointFields.filter(f => !f.endpointType || f.endpointType == null || f.endpointType == type);
        return fields.map(f =>
            <ui.Form.Item label={splitIntoWords(f.name)} key={f.id}>
                <ValueEditor accountId={shared.accountId} field={f} valueType={f.type} value={this.newData[f.name]} />
            </ui.Form.Item>);
    }

    getFlowEditor() {
        return (
            <div>
                <ui.Form.Item label="Flow">
                    <AutoComplete placeholder="Select a flow" type={dtos.ValueTypes.Flow} value={this.newFlowId} static={true} onChanged={v => this.setFlowId(v)} />
                </ui.Form.Item>
                {this.newFlowParams &&
                    this.newFlowParameters.map(f =>
                        <ui.Form.Item key={f.id} label={splitIntoWords(f.name)}>
                            <ValueEditor accountId={shared.accountId} value={this.newFlowParams[f.name]} field={f} valueType={f.type} />
                        </ui.Form.Item>)}
            </div>
        );
    }

    async setFlowId(flowId: string) {
        this.newFlowId = flowId;
        this.newFlowParameters = [];
        this.newFlowParams = null;
        if (flowId) {
            let response = await serviceClient.get(new dtos.ListFlows({ accountIds: [shared.accountId], specificIds: [flowId] }));
            let flow = response.items[0];
            let params = new dtos.Struct();
            this.newFlowParameters = flow.parameters.filter(p => p.isPublic);
            for (let field of this.newFlowParameters) {
                if (!params[field.name]) {
                    params[field.name] = new dtos.Value();
                }
            }
            this.newFlowParams = params;
        }
    }

    getDataFilter(f: dtos.EndpointDataField) {
        return this.dataFilters[f.name] ? this.dataFilters[f.name].stringValue || "" : "";
    }

    setDataFilter(f: dtos.EndpointDataField, v: string) {
        if (v) {
            if (!this.dataFilters[f.name]) {
                this.dataFilters[f.name] = new dtos.Value();
            }
            this.dataFilters[f.name].stringValue = v;
        } else {
            delete this.dataFilters[f.name];
        }
        this.forceUpdate();
    }

    async deleteEndpoint(ep: dtos.EndpointInfo) {
        await serviceClient.delete(new dtos.DeleteEndpoint({
            endpointId: ep.id
        }));
        let idx = this.endpoints.indexOf(ep);
        this.endpoints.splice(idx, 1);
        this.forceUpdate();
        ui.message.info(ep.displayName + " was deleted");
    }

    async saveEndpoint(ep: dtos.EndpointInfo) {
        await serviceClient.patch(new dtos.PatchEndpoint({
            endpointId: ep.id,
            data: ep.data
        }));
        ui.message.info(ep.displayName + " saved");
    }

    async regenerateSipPasswords() {
        this.loading = true;
        let count = 0;
        for (let id of this.selectedEndpointIds) {
            let ep = _.find(this.endpoints, e => e.id == id);
            if (ep != null && ep.type == dtos.EndpointTypes.User && ep.userMode == dtos.UserModes.Sip) {
                await serviceClient.patch(new dtos.RegenerateSipPassword({
                    endpointId: ep.id
                }));
                count++;
            }
        }
        ui.message.success("Regenerated SIP passwords for " + count + " users");
        this.loading = false;
    }

    async setCallerName(on: boolean) {
        this.loading = true;
        let count = 0;
        for (let id of this.selectedEndpointIds) {
            let ep = _.find(this.endpoints, e => e.id == id);
            if (ep != null && ep.type == dtos.EndpointTypes.PhoneNumber) {
                try {
                    await serviceClient.patch(new dtos.PatchEndpoint({
                        endpointId: ep.id,
                        enableCallerIdLookup: on
                    }));
                } catch (err) {
                    // do nothing
                }
                count++;
            }
        }
        ui.message.success("Caller Name turned " + (on ? "on" : "off") + " for " + count + " phone numbers");
        this.loading = false;
    }

    async setSmsDisabled(disabled: boolean) {
        this.loading = true;
        let count = 0;
        for (let id of this.selectedEndpointIds) {
            let ep = _.find(this.endpoints, e => e.id == id);
            if (ep != null && ep.type == dtos.EndpointTypes.PhoneNumber) {
                try {
                    await serviceClient.patch(new dtos.PatchEndpoint({
                        endpointId: ep.id,
                        disableSms: disabled
                    }));
                    ep.disableSms = disabled;
                } catch (err) {
                    // do nothing
                }
                count++;
            }
        }
        ui.message.success("SMS " + (disabled ? "disabled" : "enabled") + " for " + count + " phone numbers");
        this.loading = false;
    }

    showPhoneNumberTester() {
        var numbersToTest = [];
        for (let id of this.selectedEndpointIds) {
            let ep = _.find(this.endpoints, e => e.id == id);
            if (ep == null || ep.type != dtos.EndpointTypes.PhoneNumber) continue;
            numbersToTest.push(ep);
        }
        this.numbersToTest = numbersToTest;
        this.showNumberTester = true;
    }

    doSuperSearch(id) {
        window.location.hash = "#/endpoints/" + id;
    }

    async tryShowBulkUpdateFlow() {
        var flowIds = [];
        for (let id of this.selectedEndpointIds) {
            var ep = _.find(this.endpoints, ep => ep.id == id);
            if (flowIds.indexOf(ep.flowId) < 0) {
                flowIds.push(ep.flowId);
            }
        }

        if (flowIds.length != 1) {
            ui.message.error("Cannot bulk update because these endpoints have different flows.");
        } else {
            this.bulkUpdateFlow = (await serviceClient.get(new dtos.ListFlows({ accountIds: [shared.accountId], specificIds: [flowIds[0]] }))).items[0];
            this.bulkUpdateRows.splice(0, this.bulkUpdateRows.length);
            for (let param of this.bulkUpdateFlow.parameters) {
                if (!param.isPublic) continue;
                this.bulkUpdateRows.push({
                    id: param.id,
                    flowParam: param,
                    update: false,
                    value: new dtos.Value()
                });
            }

            if (this.bulkUpdateRows.length == 0) {
                ui.message.error("This flow does not have any settings that can be edited.");
                return;
            }

            this.showBulkUpdateFlow = true;
        }
    }

    async bulkUpdateFlowParams() {
        try {
            this.loading = true;
            let flowParams = new dtos.Struct();
            var didUpdate = false;
            for (let row of this.bulkUpdateRows) {
                if (row.update) {
                    flowParams[row.flowParam.name] = row.value;
                    didUpdate = true;
                }
            }

            if (!didUpdate) {
                ui.message.error("Must select at least one field to be updated");
                return;
            }

            for (let id of this.selectedEndpointIds) {
                await serviceClient.patch(new dtos.PatchEndpoint({
                    endpointId: id,
                    flowParams: flowParams,
                    isPartialFlowUpdate: true
                }))
            }

            ui.message.success("Endpoints updated successfully");
            this.showBulkUpdateFlow = false;
        } finally {
            this.loading = false;
        }
    }

    async tryShowBulkUpdate() {
        let ss = await cache.getSystemSettings();
        for (let param of ss.endpointFields) {
            if (this.filter.type && this.filter.type != param.endpointType) continue;
            this.bulkUpdateEndpointRows.push({
                id: param.id,
                field: param,
                update: false,
                value: new dtos.Value()
            });
        }

        if (this.bulkUpdateEndpointRows.length == 0) {
            ui.message.error("This flow does not have any settings that can be edited.");
            return;
        }

        this.showBulkUpdate = true;
    }

    async bulkUpdate() {
        try {
            this.loading = true;
            let data = new dtos.Struct();
            var didUpdate = false;
            for (let row of this.bulkUpdateEndpointRows) {
                if (row.update) {
                    data[row.field.name] = row.value;
                    didUpdate = true;
                }
            }

            // if (!didUpdate) {
            //     ui.message.error("Must select at least one field to be updated");
            //     return;
            // }

            for (let id of this.selectedEndpointIds) {
                await serviceClient.patch(new dtos.PatchEndpoint({
                    endpointId: id,
                    data: data
                }))
            }

            ui.message.success("Endpoints updated successfully");
            this.showBulkUpdate = false;
        } finally {
            this.loading = false;
        }
    }

    async tryShowBulkUpdate911() {
        for (let epId of this.selectedEndpointIds) {
            let ep = this.endpoints.find(e => e.id == epId);
            if (ep.type != dtos.EndpointTypes.User || ep.userMode != dtos.UserModes.Sip) {
                ui.message.error("You can only bulk assign e911 to SIP users. " + ep.displayName + " is not a SIP user");
                return;
            }
        }
        this.showBulkUpdate911 = true;
    }

    async doBulkUpdate911() {
        if (!this.bulkUpdateNumberId) {
            ui.message.error("Must specify an emergency number to assign to these users.");
            return;
        }

        let emergencyEndpoint = (await serviceClient.get(new dtos.ListEndpoints({ specificIds: [this.bulkUpdateNumberId] }))).items[0];
        if (!emergencyEndpoint || !emergencyEndpoint.emergencyAddressSid) {
            ui.message.error("There is no emergency address associated with this number");
            return;
        }

        this.loading = true;
        for (let epId of this.selectedEndpointIds) {
            let ep = this.endpoints.find(e => e.id == epId);
            try {
                await serviceClient.patch(new dtos.PatchEndpoint({
                    endpointId: ep.id,
                    emergencyPhoneNumberId: emergencyEndpoint.id
                }))
            } catch (err) {
                return;
            } finally {
                this.loading = false;
            }
        }
        ui.message.success("Endpoints updated successfully");
    }

    async showFlowFilter() {
        let flowChanged = this.filterFlow != null && this.filterFlow.id != this.filter.flowId;
        if (!this.filterFlow || this.filterFlow.id != this.filter.flowId) {
            let response = await serviceClient.get(new dtos.ListFlows({ specificIds: [this.filter.flowId] }))
            this.filterFlow = response.items[0];
        }

        if (!this.filterFlow) return;
        if (flowChanged || this.flowFilter == null) {
            this.flowFilter = new dtos.Struct();
        }
        this.showFlowFilterWindow = true;
    }

    hasFlowFilter() {
        if (!this.flowFilter) return false;
        for (let name in this.flowFilter) {
            let v = this.flowFilter[name];
            if (v.stringValue || v.boolValue || v.numberValue) {
                return true;
            }
        }
        return false;
    }

    getFlowFilterValue(f: dtos.DataField) {
        if (this.flowFilter[f.name]) return this.flowFilter[f.name];
        this.flowFilter[f.name] = new dtos.Value();
        return this.flowFilter[f.name];
    }

    applyFlowFilterValue() {
        this.showFlowFilterWindow = false;
        this.filter.flowParametersFilter = this.flowFilter;
        this.refresh();
    }

    allLocked() {
        if (this.selectedEndpointIds.length == 0) return false;
        for (let id of this.selectedEndpointIds) {
            let ep = this.endpoints.find(e => e.id == id);
            if (ep.type != dtos.EndpointTypes.PhoneNumber || !ep.doNotTouchPhoneNumber) return false;
        }
        return true;
    }

    async unlockPhoneNumbers() {
        this.loading = true;
        try {
            for (let id of this.selectedEndpointIds) {
                let ep = this.endpoints.find(e => e.id == id);
                await serviceClient.patch(new dtos.PatchEndpoint({
                    endpointId: id,
                    unlockPhoneNumber: true
                }));
                ep.doNotTouchPhoneNumber = false;
            }
            this.refresh();
            ui.message.success("Phone numbers unlocked and configured. Make sure to assign a Flow to these numbers.");
        } finally {
            this.loading = false;
        }
    }

    render() {
        let newEndpointMenu = <ui.Menu>
            <ui.Menu.Item onClick={() => this.showNewUser = true}><span><i className={getEndpointIcon(dtos.EndpointTypes.User)} /> User</span></ui.Menu.Item>
            <ui.Menu.Item onClick={() => this.showBuyNumbers(false)}><span><i className={getEndpointIcon(dtos.EndpointTypes.PhoneNumber)} /> Incoming Phone Number</span></ui.Menu.Item>
            <ui.Menu.Item onClick={() => this.showBuyNumbers(true)}><span><i className={getEndpointIcon(dtos.EndpointTypes.FaxNumber)} /> Incoming Fax Number</span></ui.Menu.Item>
            {!shared.isCustomer() && <ui.Menu.Item onClick={() => this.showNewTeam = true}><span><i className={getEndpointIcon(dtos.EndpointTypes.Team)} /> Team</span></ui.Menu.Item>}
            {!shared.isCustomer() && <ui.Menu.Item onClick={() => this.showNewAssistant = true}><span><i className={getEndpointIcon(dtos.EndpointTypes.Assistant)} /> AI Assistant</span></ui.Menu.Item>}
            <ui.Menu.Item onClick={() => this.showNewEmail = true}><span><i className={getEndpointIcon(dtos.EndpointTypes.EmailAddress)} /> Email Address</span></ui.Menu.Item>
            {!shared.isCustomer() && <ui.Menu.Divider />}
            {!shared.isCustomer() && <ui.Menu.Item onClick={() => this.showBulkPurchase = true}><span><i className="fa fa-hashtag" /> Bulk Purchase Numbers</span></ui.Menu.Item>}
            {!shared.isCustomer() && <ui.Menu.Divider />}
            {!shared.isCustomer() && <ui.Menu.Item onClick={() => this.syncPhoneNumbers()}><span><i className="far fa-sync-alt" /> Sync Phone Numbers</span></ui.Menu.Item>}
        </ui.Menu>;

        const rowSelection = {
            selectedRowKeys: this.selectedEndpointIds,
            onChange: keys => this.selectedEndpointIds = keys
        };

        function getValue(rec: dtos.EndpointInfo, f: dtos.EndpointDataField) {
            rec.data[f.name] = rec.data[f.name] || new dtos.Value();
            return rec.data[f.name];
        }

        return (
            <div>
                <PageTitle title="Endpoints" />
                <AccountHeader title="Endpoints" icon="fa fa-phone-office" />
                <ui.Button.Group style={{ marginBottom: 16 }}>
                    <ui.Dropdown overlay={newEndpointMenu} trigger={["click"]}>
                        <ui.Button type="primary">New Endpoint</ui.Button>
                    </ui.Dropdown>
                </ui.Button.Group>
                {!shared.isCustomer && <ui.Button.Group style={{ marginLeft: 16 }}>
                    <ui.Button type="default" onClick={() => this.showImport = true}><i className="fa fa-upload" />&nbsp; Import</ui.Button>
                </ui.Button.Group>}
                {this.endpointFields.filter(f => f.type == dtos.ValueTypes.String).length > 0 && <ui.Button.Group style={{ marginLeft: 16 }}>
                    <ui.Button type={this.showAdvancedFilters ? "primary" : "default"} onClick={() => this.showAdvancedFilters = !this.showAdvancedFilters}><i className="fa fa-search-plus" />&nbsp; {this.showAdvancedFilters ? "Hide Advanced" : "Show Advanced"}</ui.Button>
                </ui.Button.Group>}
                <ui.Button.Group style={{ paddingLeft: 16 }}>
                    <AutoComplete type={dtos.ValueTypes.Endpoint} placeholder="Search and Edit Direct" value={this.superSearchId} onChanged={id => this.doSuperSearch(id)} />
                </ui.Button.Group>
                <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    <ui.Form.Item>
                        <ui.Input onKeyPress={ev => this.checkFilterSubmit(ev)} placeholder="Filter by name" value={this.filter.name || ""} onChange={ev => this.filter.name = ev.target.value} />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Select onInputKeyDown={ev => this.checkFilterSubmit(ev)} showSearch filterOption={selectFilter} style={{ width: 175 }} dropdownMatchSelectWidth={false} placeholder="Filter by type" value={this.filter.type || undefined} onChange={v => this.filter.type = v}>
                            {Object.keys(dtos.EndpointTypes).filter(t => [dtos.EndpointTypes.Unused_1, dtos.EndpointTypes.Unused_2, dtos.EndpointTypes.Unused_3, dtos.EndpointTypes.Unused_4, dtos.EndpointTypes.Unused_5].indexOf(t as any) < 0).map(t => <ui.Select.Option key={t} value={t}><i className={getEndpointIcon(t as dtos.EndpointTypes)} /> {splitIntoWords(t)}</ui.Select.Option>)}
                        </ui.Select>
                    </ui.Form.Item>
                    {this.filter.type == dtos.EndpointTypes.User && <ui.Form.Item>
                        <ui.Select onInputKeyDown={ev => this.checkFilterSubmit(ev)} showSearch filterOption={selectFilter} style={{ width: 175 }} dropdownMatchSelectWidth={false} placeholder="Filter by user mode" value={this.filter.userMode || undefined} onChange={v => this.filter.userMode = v}>
                            {Object.keys(dtos.UserModes).map(t => <ui.Select.Option key={t} value={t}>{splitIntoWords(t)}</ui.Select.Option>)}
                        </ui.Select>
                    </ui.Form.Item>}
                    {this.filter.type == dtos.EndpointTypes.User && this.filter.userMode == dtos.UserModes.Sip &&
                        <ui.Form.Item><ui.Input placeholder="SIP User Name" value={this.filter.sipUserName} onChange={ev => { this.filter.sipUserName = ev.target.value; this.forceUpdate() }} />
                        </ui.Form.Item>}
                    <ui.Form.Item>
                        <AutoComplete type={dtos.ValueTypes.Customer} includeNotAssigned={!shared.isCustomer()} value={this.filter.customerId} onSearch={() => this.refresh()} onChanged={v => this.filter.customerId = v} placeholder="Filter by customer" />
                    </ui.Form.Item>
                    {this.filter.customerId && this.filter.customerId != "_" && <ui.Form.Item style={{ marginLeft: -8 }}>
                        <ui.Tooltip title="Select this option to show only direct children of the specified customer"><ui.Button type={this.filter.shallowParent ? "primary" : "default"} onClick={() => { this.filter.shallowParent = !this.filter.shallowParent; this.refresh() }}><i className="fa fa-sitemap" /></ui.Button></ui.Tooltip>
                    </ui.Form.Item>}
                    <ui.Form.Item>
                        <AutoComplete type={dtos.ValueTypes.Flow} includeNotAssigned={true} static={true} value={this.filter.flowId} onSearch={() => this.refresh()} onChanged={v => this.filter.flowId = v} placeholder="Filter by flow" />
                    </ui.Form.Item>
                    {this.filter.flowId && <ui.Form.Item style={{ marginLeft: -8 }}><ui.Button danger={this.flowFilter && this.hasFlowFilter()} type="default" title="Filter by flow settings" onClick={() => this.showFlowFilter()}><i className="fa fa-filter" /></ui.Button></ui.Form.Item>}
                    {this.filter.flowId && <ui.Form.Item>
                        <ui.Input value={this.filter.flowState} onChange={ev => { this.filter.flowState = ev.target.value; this.forceUpdate() }} placeholder="Filter by flow state" />
                    </ui.Form.Item>}
                    <ui.Form.Item>
                        <TagsEditor ss={this.ss} placeholder="Filter by tag" tags={this.tags} />
                    </ui.Form.Item>
                    {this.showAdvancedFilters && this.endpointFields.filter(f => f.showInSearch && f.type == dtos.ValueTypes.String).map(f =>
                        <ui.Form.Item key={f.id}>
                            <ui.Input onKeyPress={ev => this.checkFilterSubmit(ev)} placeholder={"Filter by " + splitIntoWords(f.name)} value={this.getDataFilter(f)} onChange={ev => this.setDataFilter(f, ev.target.value)} />
                        </ui.Form.Item>)}
                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="primary" onClick={() => this.refresh()}>Search</ui.Button>
                            <ui.Button type="default" onClick={() => { this.filter = {}; this.flowFilter = null; this.filterFlow = null; this.tags = []; this.selectedEndpointIds = []; this.dataFilters = new dtos.Struct(); this.refresh(); }}>Clear</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>
                </ui.Form>
                <ui.Form layout="inline">
                    {this.selectedEndpointIds.length > 0 &&
                        <React.Fragment>
                            <ui.Form.Item>
                                <ui.Input.Group compact>
                                    <AutoComplete type={dtos.ValueTypes.Customer} value={this.assignToCustomerId} onChanged={v => this.assignToCustomerId = v} placeholder="Assign to customer" />
                                    <ui.Popconfirm title="Assign this customer to all of the selected endpoints?" onConfirm={() => this.assignToCustomer()}><ui.Button type="default" disabled={!this.assignToCustomerId}><i className="fa fa-save" /></ui.Button></ui.Popconfirm>
                                </ui.Input.Group>
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Input.Group compact>
                                    <AutoComplete type={dtos.ValueTypes.Flow} static={true} value={this.assignToFlowId} onChanged={v => this.assignToFlowId = v} placeholder="Assign to flow" />
                                    <ui.Popconfirm title="Assign this flow to all of the selected endpoints?" onConfirm={() => this.assignToFlow()}><ui.Button type="default" disabled={!this.assignToFlowId}><i className="fa fa-save" /></ui.Button></ui.Popconfirm>
                                </ui.Input.Group>
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Input.Group compact>
                                    <ui.Select showSearch filterOption={selectFilter} style={{ width: 175 }} dropdownMatchSelectWidth={false} placeholder="Assign/Remove Tag" value={this.assignToTagId || undefined} onChange={v => this.assignToTagId = v}>
                                        {this.allTags.map(c => <ui.Select.Option key={c.id} value={c.id}>{c.name}</ui.Select.Option>)}
                                    </ui.Select>
                                    <ui.Button.Group>
                                        <ui.Popconfirm title="Assign this tag to all of the selected endpoints?" onConfirm={() => this.assignToTag()}><ui.Button title="Assign to tag" type="default" disabled={!this.assignToTagId}><i className="fa fa-save" /></ui.Button></ui.Popconfirm>
                                        <ui.Popconfirm title="Remove this tag from all of the selected endpoints?" onConfirm={() => this.removeFromTag()}><ui.Button title="Remove from tag" type="default" disabled={!this.assignToTagId}><i className="fa fa-times" /></ui.Button></ui.Popconfirm>
                                    </ui.Button.Group>
                                </ui.Input.Group>
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Popconfirm onConfirm={() => this.deleteEndpoints()} title="Are you sure that you want to delete the selected endpoints? Any phone numbers will be RELEASED and irretrievable"><ui.Button danger>Delete Endpoints</ui.Button></ui.Popconfirm>
                                <ui.Popconfirm onConfirm={() => this.clearFlow()} title="Are you sure that you want to clear the flow associated with the selected endpoints?"><ui.Button danger style={{ marginLeft: 8 }}>Clear Flow</ui.Button></ui.Popconfirm>

                            </ui.Form.Item>
                            {this.allLocked() && <ui.Form.Item>
                                <ui.Popconfirm onConfirm={() => this.unlockPhoneNumbers()} title="Are you sure that you want to unlock these phone numbers? This will configure these phone numbers to work with Evo Voice and their Twilio webhooks will be changed"><ui.Button danger>Unlock</ui.Button></ui.Popconfirm>
                            </ui.Form.Item>}
                            <ui.Form.Item>
                                <ui.Dropdown overlay={<ui.Menu>
                                    <ui.Menu.Item onClick={() => this.showPhoneNumberTester()}><span>Test Phone Numbers...</span></ui.Menu.Item>
                                    <ui.Menu.Item onClick={() => this.showForwarding()}><span>Forwarding...</span></ui.Menu.Item>
                                    <ui.Menu.Divider />
                                    <ui.Menu.Item onClick={() => this.tryShowBulkUpdate()}><span>Bulk Update Endpoint...</span></ui.Menu.Item>
                                    <ui.Menu.Item onClick={() => this.tryShowBulkUpdateFlow()}><span>Bulk Update Flow...</span></ui.Menu.Item>
                                    <ui.Menu.Item onClick={() => this.tryShowBulkUpdate911()}><span>Bulk Update e911...</span></ui.Menu.Item>
                                    <ui.Menu.Divider />
                                    <ui.Menu.SubMenu title="Caller Name">
                                        <ui.Menu.Item onClick={() => this.setCallerName(true)}><span>Turn On</span></ui.Menu.Item>
                                        <ui.Menu.Item onClick={() => this.setCallerName(false)}><span>Turn Off</span></ui.Menu.Item>
                                    </ui.Menu.SubMenu>
                                    <ui.Menu.SubMenu title="SMS">
                                        <ui.Menu.Item onClick={() => this.setSmsDisabled(false)}><span>Enable (default)</span></ui.Menu.Item>
                                        <ui.Menu.Item onClick={() => this.setSmsDisabled(true)}><span>Disable</span></ui.Menu.Item>
                                    </ui.Menu.SubMenu>
                                    <ui.Menu.Divider />
                                    <ui.Menu.Item onClick={() => this.regenerateSipPasswords()}><span>Regenerate SIP Passwords</span></ui.Menu.Item>
                                </ui.Menu>} trigger={["click"]}>
                                    <ui.Button>More <i className="fa fa-angle-down" /> </ui.Button>
                                </ui.Dropdown>
                            </ui.Form.Item>
                        </React.Fragment>}

                </ui.Form>
                <ui.Table className="hide-pagination-buttons" rowSelection={rowSelection} loading={this.loading} dataSource={this.endpoints} rowKey="id" pagination={this.pagination} onChange={(p, f, s) => this.refresh(p.current, s)}>
                    <ui.Table.Column title="Name" dataIndex="displayName" render={(text, rec: dtos.EndpointInfo) =>
                        <span><i style={{ marginRight: 5 }} className={getEndpointIcon(rec.type)} />
                            {!rec.doNotTouchPhoneNumber && <Link to={`/endpoints/${rec.id}`}>{rec.displayName || "(No Name)"}</Link>}
                            {rec.doNotTouchPhoneNumber && <span>{rec.displayName}</span>}
                            {rec.type == dtos.EndpointTypes.User && rec.userMode == dtos.UserModes.SoftPhone && <small>&nbsp;(Soft Phone)</small>}
                            {rec.type == dtos.EndpointTypes.User && rec.userMode == dtos.UserModes.Sip && <small>&nbsp;(SIP)</small>}
                            {rec.type == dtos.EndpointTypes.User && rec.userMode == dtos.UserModes.Flow && <small>&nbsp;(Flow)</small>}
                            {rec.type == dtos.EndpointTypes.User && rec.userMode == dtos.UserModes.DataOnly && <small>&nbsp;(Data)</small>}
                            {(rec.type == dtos.EndpointTypes.PhoneNumber || rec.type == dtos.EndpointTypes.FaxNumber) && rec.name != null && rec.name.length > 0 && <small>&nbsp;({rec.name})</small>}
                            {rec.type == dtos.EndpointTypes.PhoneNumber && rec.disableSms && <small>&nbsp;(SMS disabled)</small>}
                            {(rec.type == dtos.EndpointTypes.PhoneNumber || rec.type == dtos.EndpointTypes.FaxNumber) && rec.doNotTouchPhoneNumber && <small>&nbsp; (LOCKED)</small>}
                            {(rec.type === dtos.EndpointTypes.PhoneNumber && rec.isVirtualPhoneNumber && <small>&nbsp; (Virtual)</small>)}
                            {rec.type == dtos.EndpointTypes.FaxNumber && rec.documoId && <small>&nbsp;(Documo Fax)</small>}
                            {rec.type == dtos.EndpointTypes.FaxNumber && !rec.documoId && rec.phoneNumberSid && <small>&nbsp;(Twilio Fax)</small>}
                            {rec.type == dtos.EndpointTypes.User && rec.avatarUrl && <ui.Avatar style={{ marginLeft: 8 }} src={rec.avatarUrl} size="small" />}
                        </span>} />
                    <ui.Table.Column title="Type" dataIndex="type" render={(text, rec: dtos.EndpointInfo) => splitIntoWords(rec.type)} />
                    <ui.Table.Column dataIndex="customerBreadcrumb" title="Customer" render={(text, rec: dtos.EndpointInfo) =>
                        <div>
                            <ui.Breadcrumb>
                                {rec.customerBreadcrumb.map((bc, i) =>
                                    <ui.Breadcrumb.Item key={bc.id}>
                                        <Link to={`/customers/${bc.id}`}>{bc.name}</Link>
                                        {rec.customerId == bc.id && <a style={{ marginLeft: 5 }} onClick={() => { this.filter = { customerId: rec.customerId }; this.forceUpdate(); this.refresh(); }}><small><i className="fa fa-filter" /></small></a>}

                                    </ui.Breadcrumb.Item>)}
                            </ui.Breadcrumb>
                        </div>
                    } />

                    <ui.Table.Column title="Flow" dataIndex="voiceFlowName" render={(text, rec: dtos.EndpointInfo) => <div>
                        {rec.flowSchedule == dtos.EndpointFlowSchedules.Scheduled && <span>Scheduled</span>}
                        {rec.flowSchedule == dtos.EndpointFlowSchedules.Always && rec.flowId && <Link to={`/flows/${rec.flowId}`}>{rec.flowName}</Link>}
                        {rec.flowSchedule === dtos.EndpointFlowSchedules.Simple && <span><i style={{opacity: .5}} className="fa fa-clock" /> {rec.schedule.rules.filter(r => r.flowId).map((r, i) => <Link style={{paddingRight:8, paddingLeft:8, borderRight : i < rec.schedule.rules.length - 1 ? "2px solid #ccc" : ""}} key={r.flowId} to={`/flows/${r.flowId}`}>{this.flows.find(f => f.id === r.flowId)?.name || "Unknown"}</Link>)}</span>}
                    </div>} />
                    <ui.Table.Column title="Tags" render={(text, rec: dtos.EndpointInfo) =>
                        <div>
                            {rec.tags.map(t => <ui.Tag key={t.id} color={t.color.toLowerCase()}>{t.name}</ui.Tag>)}
                        </div>} />
                    {this.endpointFields.filter(f => f.showInSearch).map(f => <ui.Table.Column key={f.name} dataIndex={f.name} title={splitIntoWords(f.name)} render={(text, rec: dtos.EndpointInfo) =>
                        <span>{this.getEndpointDataText(rec, f)}</span>} />)}
                </ui.Table>

                <ui.Modal width={800} onOk={() => this.doBulkUpdate911()} title="Bulk Update e911" cancelButtonProps={{ disabled: this.loading }} okButtonProps={{ disabled: this.loading }} visible={this.showBulkUpdate911} onCancel={() => this.showBulkUpdate911 = false}>
                    <ui.Form layout="vertical">
                        <AutoComplete placeholder="Specify the emergency number to assign" type={dtos.ValueTypes.PhoneNumber} value={this.bulkUpdateNumberId} onChanged={v => this.bulkUpdateNumberId = v} />
                    </ui.Form>
                </ui.Modal>

                <ui.Modal width={800} title="Bulk Update Flow" cancelButtonProps={{ disabled: this.loading }} okButtonProps={{ disabled: this.loading }} visible={this.showBulkUpdateFlow} onCancel={() => this.showBulkUpdateFlow = false} okText="Update" onOk={() => this.bulkUpdateFlowParams()}>
                    <div style={{ maxHeight: 600, overflowY: "auto" }}>
                        <ui.Table dataSource={this.bulkUpdateRows} rowKey="id" showHeader={false} pagination={false}>
                            <ui.Table.Column dataIndex="id" render={(text, rec: BulkUpdateFlowRow) => <ui.Checkbox checked={rec.update} onChange={ev => { rec.update = ev.target.checked; this.forceUpdate() }} />} />
                            <ui.Table.Column render={(text, rec: BulkUpdateFlowRow) => <span>{splitIntoWords(rec.flowParam.name)}</span>} />
                            <ui.Table.Column render={(text, rec: BulkUpdateFlowRow) => <ValueEditor accountId={shared.accountId} value={rec.value} onFieldChanged={() => this.forceUpdate()} key={rec.id} field={rec.flowParam} valueType={rec.flowParam.type} />} />
                        </ui.Table>
                    </div>
                </ui.Modal>

                <ui.Modal width={800} title="Bulk Update Endpoint" cancelButtonProps={{ disabled: this.loading }} okButtonProps={{ disabled: this.loading }} visible={this.showBulkUpdate} onCancel={() => this.showBulkUpdate = false} okText="Update" onOk={() => this.bulkUpdate()}>
                    <div style={{ maxHeight: 600, overflowY: "auto" }}>
                        <ui.Table dataSource={this.bulkUpdateEndpointRows} rowKey="id" showHeader={false} pagination={false}>
                            <ui.Table.Column dataIndex="id" render={(text, rec: BulkUpdateEndpointRow) => <ui.Checkbox checked={rec.update} onChange={ev => { rec.update = ev.target.checked; this.forceUpdate() }} />} />
                            <ui.Table.Column render={(text, rec: BulkUpdateEndpointRow) => <span>{splitIntoWords(rec.field.name)}</span>} />
                            <ui.Table.Column render={(text, rec: BulkUpdateEndpointRow) => <ValueEditor accountId={shared.accountId} value={rec.value} onFieldChanged={() => this.forceUpdate()} key={rec.id} field={rec.field} valueType={rec.field.type} />} />
                        </ui.Table>
                    </div>
                </ui.Modal>

                <ui.Modal width={800} title="Filter Flow" visible={this.showFlowFilterWindow} onOk={() => this.applyFlowFilterValue()} onCancel={() => this.showFlowFilterWindow = false}>
                    {this.filterFlow && this.showFlowFilterWindow && this.filterFlow.parameters.map(f => <ui.Form.Item key={f.id} label={splitIntoWords(f.name)}>
                        <ValueEditor accountId={shared.accountId} value={this.getFlowFilterValue(f)} field={f} valueType={f.type} />
                    </ui.Form.Item>)}
                </ui.Modal>

                <ui.Modal title="Bulk Purchase" visible={this.showBulkPurchase} onCancel={() => this.doCancelBulkPurchase()} okText="Purchase" cancelText={this.bulkPurchasing ? "Cancel" : "Close"} okButtonProps={{ disabled: this.bulkPurchasing }} onOk={() => this.bulkPurchase()}>
                    <ui.Form layout="vertical">
                        <p>You can purchase multiple phone numbers in bulk. Please note that account restrictions still apply</p>
                        <ui.Row gutter={8}>
                            <ui.Col span={12}>
                                <ui.Form.Item label="Customer">
                                    <AutoComplete type={dtos.ValueTypes.Customer} value={this.bulkPurchaseCustomerId} onChanged={v => this.bulkPurchaseCustomerId = v} placeholder="Select a customer" />
                                </ui.Form.Item>
                            </ui.Col>
                            <ui.Col span={12}>
                                <ui.Form.Item label="Tags">
                                    <TagsEditor ss={this.ss} tags={this.newTags} />
                                </ui.Form.Item>
                            </ui.Col>
                        </ui.Row>
                        <ui.Row gutter={8}>
                            <ui.Col span={10}>
                                <ui.Form.Item label="Country">
                                    <ui.Select showSearch filterOption={selectFilter} value={this.searchCountryCode} onChange={v => this.searchCountryCode = v}>
                                        {this.availableCountries.map(c => <ui.Select.Option key={c.code} value={c.code}>{c.englishName} ({c.code})</ui.Select.Option>)}
                                    </ui.Select>
                                </ui.Form.Item>
                            </ui.Col>
                            <ui.Col span={5}>
                                <ui.Form.Item label="Area Code">
                                    <ui.Input value={this.searchAreaCode} onChange={ev => this.searchAreaCode = ev.target.value} />
                                </ui.Form.Item>
                            </ui.Col>
                            <ui.Col span={9}>
                                <ui.Form.Item label="Postal Code">
                                    <AutoFocus visible={this.showBuy}>
                                        <ui.Input.Search enterButton={<i className="fa fa-search" />} value={this.searchPostalCode} onChange={ev => this.searchPostalCode = ev.target.value} onSearch={() => this.searchPhoneNumbers()} />
                                    </AutoFocus>
                                </ui.Form.Item>
                            </ui.Col>
                        </ui.Row>
                        <ui.Form.Item>
                            <ui.Checkbox checked={this.bulkPurchaseIsFaxNumber} onChange={ev => this.bulkPurchaseIsFaxNumber = ev.target.checked}>Must be Fax Number?</ui.Checkbox>
                        </ui.Form.Item>
                        <ui.Form.Item label="# to Purchase">
                            <ui.Select value={this.numToPurchase} onChange={v => this.numToPurchase = v}>
                                {[1, 2, 5, 10, 25, 50, 100].map(i => <ui.Select.Option key={i} value={i}>{i}</ui.Select.Option>)}
                            </ui.Select>
                        </ui.Form.Item>
                        <div id="_bulk_log" style={{ height: 200, overflowY: "auto" }}>
                            <pre>{this.bulkPurchaseLog}</pre>
                        </div>
                    </ui.Form>
                </ui.Modal>

                <ui.Modal width="800px" title="Forwarding..." visible={this.showForward} okButtonProps={{ disabled: this.forwarding || this.loading || this.forwardingDone }} okText="Start" cancelText="Close" onCancel={() => this.showForward = false} onOk={() => this.forwardUnassigned()}>
                    <ui.Form layout="vertical">
                        <ui.Form.Item label="Direction">
                            <ui.Select value={this.forwardDirection} onChange={ev => this.forwardDirection = ev}>
                                <ui.Select.Option value="forward">Copy from Actual Number to Test Number</ui.Select.Option>
                                <ui.Select.Option value="backward">Copy from Test Number to Actual Number</ui.Select.Option>
                                <ui.Select.Option value="cleanup">Delete Unused Test Numbers</ui.Select.Option>
                            </ui.Select>
                        </ui.Form.Item>
                        {this.forwardDirection == "backward" && <div>
                            <p>This utility mode will copy settings from configured temp numbers to the actual phone numbers that will be used in production</p>
                            <p><strong>Utility Checklist</strong></p>
                            <ul>
                                <li>You have already submitted a porting request and sync'd the new phone numbers</li>
                                <li>You have configured your phone numbers the way you want them to function after the port completes (Flow/Customer assigned etc.)</li>
                                <li>Your temporary numbers have an Endpoint field with the Phone Number of the actual number that will be ported (e.g. Dialed Number)</li>
                                <li>You have selected the <strong>temporary phone numbers</strong></li>
                            </ul>
                            <ui.Form.Item label="Dialed Number Field">
                                <ui.Select showSearch filterOption={selectFilter} value={this.forwardField} onChange={v => this.forwardField = v}>
                                    {this.endpointFields.filter(f => f.type == dtos.ValueTypes.String && (f.endpointType == dtos.EndpointTypes.PhoneNumber || !f.endpointType)).map(f => <ui.Select.Option value={f.name} key={f.id}>{splitIntoWords(f.name)}</ui.Select.Option>)}
                                </ui.Select>
                            </ui.Form.Item>
                        </div>}

                        {this.forwardDirection == "forward" && <div>
                            <p>This utility mode will copy settings from configured phone numbers that haven't ported yet to temporary numbers that you will forward to</p>
                            <p><strong>Utility Checklist</strong></p>
                            <ul>
                                <li>You have already submitted a porting request and sync'd the new phone numbers</li>
                                <li>You have configured your phone numbers the way you want them to function after the port completes (Flow/Customer assigned etc.)</li>
                                <li>You have purchased enough temporary phone numbers to forward to (typically one per phone number to port)</li>
                                <li>You have access to your current carrier in order to forward your phone numbers to the temporary numbers</li>
                                <li>You have selected the phone numbers <strong>that will be ported</strong></li>
                            </ul>
                            <p><strong>Note:</strong> After this utility completes you will need to forward your phone numbers from your carrier to the temporary numbers assigned.</p>
                            <ui.Row gutter={8}>
                                <ui.Col span={8}>
                                    <ui.Form.Item label="Customer (optional)">
                                        <AutoComplete type={dtos.ValueTypes.Customer} value={this.forwardCustomerId} onChanged={v => this.forwardCustomerId = v} placeholder="Select a customer" />
                                    </ui.Form.Item>
                                    <p style={{ margin: 0, marginTop: -25 }}><small>Use test numbers that are assigned to this customer (typically left blank)</small></p>
                                </ui.Col>
                                <ui.Col span={8}>
                                    <ui.Form.Item label="Tags (optional)">
                                        <TagsEditor ss={this.ss} tags={this.forwardTags} />
                                    </ui.Form.Item>
                                    <p style={{ margin: 0, marginTop: -25 }}><small>Use test numbers that have these tags (e.g. Test Numbers)</small></p>
                                </ui.Col>
                                <ui.Col span={8}>
                                    <ui.Form.Item label="Forward Field (optional)">
                                        <ui.Select showSearch filterOption={selectFilter} value={this.forwardField} onChange={v => this.forwardField = v}>
                                            {this.endpointFields.filter(f => f.type == dtos.ValueTypes.String && (f.endpointType == dtos.EndpointTypes.PhoneNumber || !f.endpointType)).map(f => <ui.Select.Option value={f.name} key={f.id}>{splitIntoWords(f.name)}</ui.Select.Option>)}
                                        </ui.Select>
                                    </ui.Form.Item>
                                    <p style={{ margin: 0, marginTop: -25 }}><small>Specify an endpoint field that will store the original number that will be forwarded</small></p>
                                </ui.Col>
                            </ui.Row>
                        </div>}
                        {this.forwardDirection == "cleanup" && <div>
                            <p>This utility will verify which test numbers can be deleted</p>
                            <p><strong>Utility Checklist</strong></p>
                            <ul>
                                <li>You have selected any test numbers that were purchased</li>
                                <li>Your porting request(s) are completed</li>
                            </ul>
                            <ui.Row gutter={8}>
                                <ui.Col>
                                    <ui.Form.Item label="Forward Field (optional)">
                                        <ui.Select showSearch filterOption={selectFilter} value={this.forwardField} onChange={v => this.forwardField = v}>
                                            {this.endpointFields.filter(f => f.type == dtos.ValueTypes.String && (f.endpointType == dtos.EndpointTypes.PhoneNumber || !f.endpointType)).map(f => <ui.Select.Option value={f.name} key={f.id}>{splitIntoWords(f.name)}</ui.Select.Option>)}
                                        </ui.Select>
                                    </ui.Form.Item>
                                    <p style={{ margin: 0, marginTop: -25 }}><small>Specify an endpoint field that has the original number that was forwarded</small></p>
                                </ui.Col>
                            </ui.Row>
                        </div>}

                        <div id="_forward_log" style={{ height: 200, overflowY: "auto" }}>
                            <pre>{this.forwardLog}</pre>
                        </div>
                    </ui.Form>
                </ui.Modal>

                <ui.Modal width={700} title={`Buy ${this.isFax ? "Fax" : "Phone"} Number`} visible={this.showBuy} onCancel={() => this.showBuy = false} footer={<ui.Button onClick={() => this.showBuy = false}>Cancel</ui.Button>}>
                    <ui.Form layout="vertical">
                        <ui.Row gutter={8}>
                            <ui.Col span={8}>
                                <ui.Form.Item label="Customer">
                                    <ui.Input.Group compact>
                                        <AutoComplete type={dtos.ValueTypes.Customer} value={this.newEndpointCustomerId} onChanged={v => this.newEndpointCustomerId = v} placeholder="Filter by customer" />
                                        <ui.Button type="primary" onClick={() => this.showNewCustomer = true}><i className="fa fa-plus" /></ui.Button>
                                    </ui.Input.Group>
                                </ui.Form.Item>
                            </ui.Col>
                            <ui.Col span={8}>
                                <ui.Form.Item label="Address">
                                    <ui.Select placeholder="Required for some countries" value={this.newPhoneNumberAddressSid} onChange={v => this.newPhoneNumberAddressSid = v} showSearch filterOption={selectFilter}>
                                        <ui.Select.Option value="">(Select an address)</ui.Select.Option>
                                        {this.addresses.map(a => <ui.Select.Option value={a.id} key={a.id}>{a.friendlyName}</ui.Select.Option>)}
                                    </ui.Select>
                                </ui.Form.Item>
                            </ui.Col>
                            <ui.Col span={8}>
                                <ui.Form.Item label="Tags">
                                    <TagsEditor ss={this.ss} tags={this.newTags} />
                                </ui.Form.Item>
                            </ui.Col>
                        </ui.Row>
                        <ui.Tabs>
                            <ui.Tabs.TabPane key="search" tab="Number">
                                {!this.isFax && <ui.Row>
                                    <ui.Select value={this.newPhoneNumberVirtual ? "virtual" : "buy"} onChange={v => this.newPhoneNumberVirtual = v == "virtual"}>
                                        <ui.Select.Option value="buy">Buy Phone Number</ui.Select.Option>
                                        <ui.Select.Option value="virtual">Virtual Phone Number</ui.Select.Option>
                                    </ui.Select>
                                </ui.Row>}
                                {this.newPhoneNumberVirtual && <div>
                                    <ui.Form.Item label="Phone Number (cannot be changed after saving)">
                                        <ui.Input value={this.newVirtualPhoneNumber} onChange={ev => this.newVirtualPhoneNumber = ev.target.value} placeholder="Phone number in e164 format" />
                                    </ui.Form.Item>
                                    <ui.Form.Item>
                                        <ui.Button type="primary" onClick={() => this.saveVirtualNumber()}>Save Virtual Number</ui.Button>
                                    </ui.Form.Item>
                                </div>}
                                {!this.newPhoneNumberVirtual && <div>
                                    <ui.Row gutter={8}>
                                        {!this.isFax && <ui.Col span={6}>
                                            <ui.Form.Item label="Country">
                                                <ui.Select showSearch filterOption={selectFilter} value={this.searchCountryCode} onChange={v => this.searchCountryCode = v}>
                                                    {this.availableCountries.map(c => <ui.Select.Option key={c.code} value={c.code}>{c.englishName} ({c.code})</ui.Select.Option>)}
                                                </ui.Select>
                                            </ui.Form.Item>
                                        </ui.Col>}
                                        <ui.Col span={4}>
                                            <ui.Form.Item label="Area Code">
                                                <ui.Input value={this.searchAreaCode} placeholder="e.g. 323" onChange={ev => this.searchAreaCode = ev.target.value} />
                                            </ui.Form.Item>
                                        </ui.Col>
                                        <ui.Col span={4}>
                                            <ui.Form.Item label="Postal Code">
                                                <ui.Input value={this.searchPostalCode} placeholder="e.g. 91401" onChange={ev => this.searchPostalCode = ev.target.value} />
                                            </ui.Form.Item>
                                        </ui.Col>
                                        {!this.isFax && <ui.Col span={6}>
                                            <ui.Form.Item label="Contains">
                                                <ui.Input value={this.searchContains} placeholder="Must contain text" onChange={ev => this.searchContains = ev.target.value} />
                                            </ui.Form.Item>
                                        </ui.Col>}
                                        <ui.Col span={4}>
                                            <ui.Form.Item>
                                                <ui.Button style={{ marginTop: 42 }} type="primary" onClick={() => this.searchPhoneNumbers()}>Search</ui.Button>
                                            </ui.Form.Item>
                                        </ui.Col>
                                    </ui.Row>

                                    <ui.Table loading={this.searching} dataSource={this.searchResults} rowKey="phoneNumber" pagination={{ pageSize: 5 }}>
                                        <ui.Table.Column title="Phone Number" key="phoneNumber" dataIndex="phoneNumber" />
                                        <ui.Table.Column title="Actions" key="actions" render={(text, rec: dtos.PhoneNumberInfo) => <ui.Popconfirm title="Are you sure that you want to buy this phone number?" onConfirm={() => this.buyPhoneNumber(rec)}><ui.Button type="primary" size="small">Buy</ui.Button></ui.Popconfirm>} />
                                    </ui.Table>
                                </div>}
                            </ui.Tabs.TabPane>
                            <ui.Tabs.TabPane key="data" tab="Settings">
                                {this.getDataFields(dtos.EndpointTypes.PhoneNumber)}
                            </ui.Tabs.TabPane>
                            <ui.Tabs.TabPane key="flow" tab="Flow">
                                {this.getFlowEditor()}
                            </ui.Tabs.TabPane>
                        </ui.Tabs>
                    </ui.Form>
                </ui.Modal>

                <ui.Modal title="New User" width="800px" visible={this.showNewUser} onCancel={() => this.showNewUser = false} onOk={() => this.newUser()}>
                    <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.newUser()}>
                        <ui.Tabs>
                            <ui.Tabs.TabPane key="basics" tab="General">
                                <ui.Row gutter={8}>
                                    <ui.Col span={12}>
                                        <ui.Form.Item label="Customer">
                                            <ui.Input.Group compact>
                                                <AutoComplete type={dtos.ValueTypes.Customer} value={this.newEndpointCustomerId} onChanged={v => this.newEndpointCustomerId = v} placeholder="Filter by customer" />
                                                <ui.Button type="primary" onClick={() => this.showNewCustomer = true}><i className="fa fa-plus" /></ui.Button>
                                            </ui.Input.Group>                                        </ui.Form.Item>
                                    </ui.Col>
                                    <ui.Col span={12}>
                                        <ui.Form.Item label="Tags">
                                            <TagsEditor ss={this.ss} tags={this.newTags} />
                                        </ui.Form.Item>
                                    </ui.Col>
                                </ui.Row>
                                <ui.Row gutter={16}>
                                    <ui.Col span={8}>
                                        <ui.Form.Item label="First Name">
                                            <AutoFocus visible={this.showNewUser}>
                                                <ui.Input value={this.newUserFirstName} onChange={ev => this.newUserFirstName = ev.target.value} />
                                            </AutoFocus>
                                        </ui.Form.Item>
                                    </ui.Col>
                                    <ui.Col span={8}>
                                        <ui.Form.Item label="Last Name">
                                            <ui.Input value={this.newUserLastName} onChange={ev => this.newUserLastName = ev.target.value} />
                                        </ui.Form.Item>
                                    </ui.Col>
                                    <ui.Col span={8}>
                                        <ui.Form.Item label="Mode">
                                            <ui.Select showSearch filterOption={selectFilter} value={this.newUserMode} onChange={v => this.newUserMode = v}>
                                                <ui.Select.Option value={dtos.UserModes.SoftPhone}>Soft Phone</ui.Select.Option>
                                                <ui.Select.Option value={dtos.UserModes.Sip}>SIP Phone</ui.Select.Option>
                                                <ui.Select.Option value={dtos.UserModes.Flow}>Flow (Advanced)</ui.Select.Option>
                                                <ui.Select.Option value={dtos.UserModes.DataOnly}>Data Only</ui.Select.Option>
                                            </ui.Select>
                                        </ui.Form.Item>
                                    </ui.Col>
                                </ui.Row>
                                {(this.newUserMode == dtos.UserModes.SoftPhone) && <ui.Row gutter={16}>
                                    <ui.Col span={12}>
                                        <ui.Form.Item label="Email Address">
                                            <ui.Input value={this.newUserEmail} onChange={ev => this.newUserEmail = ev.target.value} />
                                        </ui.Form.Item>
                                    </ui.Col>
                                    <ui.Col span={12}>
                                        <ui.Form.Item label="Password">
                                            <ui.Input.Password autoComplete="new-password" value={this.newUserPassword} onChange={ev => this.newUserPassword = ev.target.value} />
                                        </ui.Form.Item>
                                    </ui.Col>
                                </ui.Row>}
                            </ui.Tabs.TabPane>
                            <ui.Tabs.TabPane key="data" tab="Settings">
                                <ScrollPane height={400}>
                                    {this.getDataFields(dtos.EndpointTypes.User)}
                                </ScrollPane>
                            </ui.Tabs.TabPane>
                            {this.newUserMode != dtos.UserModes.DataOnly && <ui.Tabs.TabPane key="flow" tab="Flow">
                                {this.getFlowEditor()}
                            </ui.Tabs.TabPane>}
                        </ui.Tabs>
                    </ui.Form>
                </ui.Modal>

                <ui.Modal title="New Email Address" visible={this.showNewEmail} onCancel={() => this.showNewEmail = false} onOk={() => this.newEmail()}>
                    <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.newEmail()}>
                        <ui.Row gutter={8}>
                            <ui.Col span={12}>
                                <ui.Form.Item label="Customer">
                                    <ui.Input.Group compact>
                                        <AutoComplete type={dtos.ValueTypes.Customer} value={this.newEndpointCustomerId} onChanged={v => this.newEndpointCustomerId = v} placeholder="Filter by customer" />
                                        <ui.Button type="primary" onClick={() => this.showNewCustomer = true}><i className="fa fa-plus" /></ui.Button>
                                    </ui.Input.Group>                                </ui.Form.Item>
                            </ui.Col>
                            <ui.Col span={12}>
                                <ui.Form.Item label="Tags">
                                    <TagsEditor ss={this.ss} tags={this.newTags} />
                                </ui.Form.Item>
                            </ui.Col>
                        </ui.Row>
                        <ui.Form.Item label="Prefix">
                            <AutoFocus visible={this.showNewEmail}>
                                <ui.Input value={this.newEmailPrefix} onChange={ev => this.newEmailPrefix = ev.target.value} />
                            </AutoFocus>
                        </ui.Form.Item>
                        {this.getDataFields(dtos.EndpointTypes.EmailAddress)}
                        {this.getFlowEditor()}

                    </ui.Form>
                </ui.Modal>

                <ui.Modal title="New Team" visible={this.showNewTeam} onCancel={() => this.showNewTeam = false} onOk={() => this.newTeam()}>
                    <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.newTeam()}>
                        <ui.Row gutter={8}>
                            <ui.Col span={12}>
                                <ui.Form.Item label="Customer">
                                    <ui.Input.Group compact>
                                        <AutoComplete type={dtos.ValueTypes.Customer} value={this.newEndpointCustomerId} onChanged={v => this.newEndpointCustomerId = v} placeholder="Filter by customer" />
                                        <ui.Button type="primary" onClick={() => this.showNewCustomer = true}><i className="fa fa-plus" /></ui.Button>
                                    </ui.Input.Group>                                </ui.Form.Item>
                            </ui.Col>
                            <ui.Col span={12}>
                                <ui.Form.Item label="Tags">
                                    <TagsEditor ss={this.ss} tags={this.newTags} />
                                </ui.Form.Item>
                            </ui.Col>
                        </ui.Row>
                        <ui.Form.Item label="Name">
                            <AutoFocus visible={this.showNewTeam}>
                                <ui.Input value={this.newTeamName} onChange={ev => this.newTeamName = ev.target.value} />
                            </AutoFocus>
                        </ui.Form.Item>
                        {this.getDataFields(dtos.EndpointTypes.Team)}
                    </ui.Form>
                </ui.Modal>

                <ui.Modal title="New Assistant" visible={this.showNewAssistant} onCancel={() => this.showNewAssistant = false} onOk={() => this.newAssistant()}>
                    <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.newAssistant()}>
                        <ui.Row gutter={8}>
                            <ui.Col span={12}>
                                <ui.Form.Item label="Customer">
                                    <ui.Input.Group compact>
                                        <AutoComplete type={dtos.ValueTypes.Customer} value={this.newEndpointCustomerId} onChanged={v => this.newEndpointCustomerId = v} placeholder="Filter by customer" />
                                        <ui.Button type="primary" onClick={() => this.showNewCustomer = true}><i className="fa fa-plus" /></ui.Button>
                                    </ui.Input.Group>                                </ui.Form.Item>
                            </ui.Col>
                            <ui.Col span={12}>
                                <ui.Form.Item label="Tags">
                                    <TagsEditor ss={this.ss} tags={this.newTags} />
                                </ui.Form.Item>
                            </ui.Col>
                        </ui.Row>
                        <ui.Form.Item label="Name">
                            <AutoFocus visible={this.showNewAssistant}>
                                <ui.Input value={this.newAssistantName} onChange={ev => this.newAssistantName = ev.target.value} />
                            </AutoFocus>
                        </ui.Form.Item>
                        {this.getDataFields(dtos.EndpointTypes.Assistant)}
                    </ui.Form>
                </ui.Modal>

                {this.showImport && <ImportWindow onImported={() => this.refresh()} visible={this.showImport} onClosed={() => this.showImport = false} accountId={shared.accountId} />}
                {this.showNumberTester && <PhoneNumberTester visible={this.showNumberTester} onCancel={() => this.showNumberTester = false} numbersToTest={this.numbersToTest} accountId={shared.accountId} />}
                {this.showNewCustomer && <NewCustomer visible={this.showNewCustomer} onClosed={() => this.showNewCustomer = false} onCreated={c => this.newEndpointCustomerId = c.id} defaultParentCustomerId={this.filter.customerId} />}
            </div>
        );
    }
}