import { React, dtos, serviceClient, ui, observer, observable, moment, _, Link, uuid, selectFilter, shared, humanFileSize, makeObservable } from "../common"
import AutoComplete from "../autocomplete";

interface Props {
    noPersistence?: boolean;
    accountId: string;
    endpointId?: string;
    customerId?: string;
    parentSessionId?: string;
}

@observer
export default class Sessions extends React.Component<Props> {
    @observable sessions: Array<dtos.SessionInfo> = [];
    @observable accountId: string = "";
    @observable pagination: any = {};
    @observable filter: any = {};
    @observable loading = false;
    @observable pageSize = 10;
    @observable startDate = moment().startOf("day");
    @observable endDate = moment().endOf("day");
    @observable sort: any = {};
    @observable showSession = false;
    @observable showSessionInfo: dtos.SessionInfo;
    @observable sessionLog: Array<dtos.SessionLogInfo> = [];
    @observable showDeleteSessions = false;
    @observable deleteStartDate = moment();
    @observable deleteEndDate = moment();
    @observable expandLogEntries: Array<dtos.SessionLogInfo> = [];
    @observable sessionFiles: Array<dtos.FileInfo> = [];
    @observable activeSessionLogTab = "log";
    @observable loadingSessionFiles = false;

    @observable showUpdateFile: boolean;
    @observable selectedFileIds: Array<string> = [];
    @observable showAudioFileWindow = false;
    @observable audioFileToShow: dtos.FileInfo;
    @observable loadingAudioFile = false;
    @observable playbackOptimized = false;
    audioPlayer = React.createRef<HTMLAudioElement>();

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        this.accountId = this.props.accountId;
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.accountId != prevProps.accountId) {
            this.accountId = this.props.accountId;
            this.init();
        }
    }

    async init() {
        if (!this.props.noPersistence) {
            this.filter.customerId = this.props.customerId || localStorage.getItem("SessionIndex.filter.customerId") || "";
            this.filter.endpointId = this.props.endpointId || localStorage.getItem("SessionIndex.filter.endpointId") || "";
            this.filter.to = localStorage.getItem("SessionIndex.filter.to") || "";
            this.filter.from = localStorage.getItem("SessionIndex.filter.from") || "";
            this.filter.log = localStorage.getItem("SessionIndex.filter.log") || "";
            this.filter.searchArchive = localStorage.getItem("SessionIndex.filter.searchArchive") == "1";
            this.filter.specificId = localStorage.getItem("SessionIndex.filter.specificId") || "";

            this.pageSize = parseInt(localStorage.getItem("SessionIndex.pagination.pageSize") || "20");

            var startDate = localStorage.getItem("SessionIndex.filter.startDate");
            if (startDate) {
                this.startDate = moment(startDate);
            }

            var endDate = localStorage.getItem("SessionIndex.filter.endDate");
            if (endDate) {
                this.endDate = moment(endDate);
            }
        }

        this.refresh();
    }

    clearSearch() {
        this.startDate = moment().startOf("day");
        this.endDate = moment().endOf("day");
        this.filter = { customerId: this.props.customerId, endpointId: this.props.endpointId };
        this.refresh();
    }

    async refresh(page = this.pagination.page || 1, sort = this.sort) {
        this.loading = true;
        let request = new dtos.ListSessions({
            accountIds: [this.accountId],
            countPerPage: this.pageSize,
            page: page - 1,
            customerIds: this.filter.customerId ? [this.filter.customerId] : null,
            endpointIds: this.filter.endpointId ? [this.filter.endpointId] : null,
            log: this.filter.log,
            from: this.filter.from,
            to: this.filter.to,
            sortField: sort.field,
            sortOrder: sort.order,
            specificIds: this.filter.specificId ? [this.filter.specificId] : null,
            searchArchive: this.filter.searchArchive == true,
            startDate: this.startDate.toISOString(),
            endDate: this.endDate.toISOString(),
            parentSessionId: this.props.parentSessionId,
            simplifiedPaging: true
        });

        let response = await serviceClient.get(request);
        this.sessions = response.items;

        let pager = { ...this.pagination };
        pager.current = page;
        pager.total = page * this.pageSize + (response.hasMorePages ? 1 : 0);
        pager.pageSize = request.countPerPage;
        pager.showSizeChanger = true;
        pager.pageSizeOptions = ["10", "20", "50", "100", "250"];
        pager.onShowSizeChange = (c, v) => this.pageSize = v;
        this.pagination = pager;
        this.loading = false;

        localStorage.setItem("SessionIndex.filter.customerId", this.filter.customerId || "");
        localStorage.setItem("SessionIndex.filter.endpointId", this.filter.endpointId || "");
        localStorage.setItem("SessionIndex.filter.from", this.filter.from || "");
        localStorage.setItem("SessionIndex.filter.to", this.filter.to || "");
        localStorage.setItem("SessionIndex.filter.startDate", this.startDate.toISOString());
        localStorage.setItem("SessionIndex.filter.endDate", this.endDate.toISOString());
        localStorage.setItem("SessionIndex.filter.log", this.filter.log || "");
        localStorage.setItem("SessionIndex.filter.searchArchive", this.filter.searchArchive == true ? "1" : "");
        localStorage.setItem("SessionIndex.filter.specificId", this.filter.specificId || "");
        localStorage.setItem("SessionIndex.pagination.pageSize", this.pageSize.toString());
    }

    setDateRange(range) {
        this.startDate = range[0];
        this.endDate = range[1];
    }

    showSessionLog(s: dtos.SessionInfo) {
        this.showSessionInfo = s;
        this.sessionLog = s.log;
        this.expandLogEntries = [];
        this.activeSessionLogTab = "log";
        this.showSession = true;
        this.sessionFiles = [];
        this.loadingSessionFiles = true;
        serviceClient.get(new dtos.ListFiles({ accountIds: [this.accountId], sessionId: s.id })).then((r) => {
            this.sessionFiles = r.items;
            this.loadingSessionFiles = false;
            this.forceUpdate();
        });
    }

    async deleteSessions() {
        this.loading = true;
        await serviceClient.delete(new dtos.DeleteSessions({
            startDateTime: this.deleteStartDate.toISOString(),
            endDateTime: this.deleteEndDate.toISOString(),
            accountId: this.accountId
        }));
        this.showDeleteSessions = false;
        this.refresh();
    }

    async flushSession() {
        await serviceClient.patch(new dtos.PatchSession({ sessionId: this.showSessionInfo.id, callState: dtos.SessionCallState.Disconnected, queueState: dtos.SessionQueueStates.None }));
        this.showSessionInfo.callState = dtos.SessionCallState.Disconnected;
        ui.message.success("Session flushed");
        this.forceUpdate();
    }

    checkFilterSubmit(ev: React.KeyboardEvent) {
        if (ev.which == 13) {
            this.refresh();
        }
    }

    toggleLogEntry(entry: dtos.SessionLogInfo) {
        const idx = this.expandLogEntries.indexOf(entry);
        if (idx >= 0) {
            this.expandLogEntries.splice(idx, 1);
        } else {
            this.expandLogEntries.push(entry);
        }
        this.forceUpdate();
    }

    async showAudioFile(rec: dtos.FileInfo) {
        this.audioFileToShow = rec;
        this.showAudioFileWindow = true;
        this.audioPlayer.current.src = "";
        this.loadingAudioFile = true;
        this.playbackOptimized = true;

        try {
            let res = await fetch(rec.uri);
            if (!res.ok) throw res.statusText;
            let blob = await res.blob();
            this.audioPlayer.current.src = URL.createObjectURL(blob);
            this.playbackOptimized = true;
        } catch (err) {
            this.audioPlayer.current.src = rec.uri;
            this.playbackOptimized = false;
        } finally {
            this.loadingAudioFile = false;
        }
    }


    render() {
        const hideControls = this.props.parentSessionId;

        return (
            <div>
                {!hideControls && <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    <ui.Form.Item>
                        <ui.DatePicker.RangePicker value={[this.startDate, this.endDate]} onChange={ev => this.setDateRange(ev)} />
                    </ui.Form.Item>

                    {!this.props.customerId && <ui.Form.Item>
                        <AutoComplete type={dtos.ValueTypes.Customer} includeNotAssigned={!shared.isCustomer() && !this.props.customerId} value={this.filter.customerId} onSearch={() => this.refresh()} onChanged={v => this.filter.customerId = v} placeholder="Filter by customer" />
                    </ui.Form.Item>}

                    {!this.props.endpointId && <ui.Form.Item>
                        <AutoComplete type={dtos.ValueTypes.Endpoint} customerId={this.props.customerId} value={this.filter.endpointId} onSearch={() => this.refresh()} onChanged={v => this.filter.endpointId = v} placeholder="Filter by endpoint" />
                    </ui.Form.Item>}

                    <ui.Form.Item>
                        <ui.Input placeholder="Filter by From" onKeyPress={ev => this.checkFilterSubmit(ev)} value={this.filter.from} onChange={ev => this.filter.from = ev.target.value} />
                    </ui.Form.Item>

                    <ui.Form.Item>
                        <ui.Input placeholder="Filter by To" onKeyPress={ev => this.checkFilterSubmit(ev)} value={this.filter.to} onChange={ev => this.filter.to = ev.target.value} />
                    </ui.Form.Item>

                    <ui.Form.Item>
                        <ui.Input placeholder="Filter by Log Text" onKeyPress={ev => this.checkFilterSubmit(ev)} value={this.filter.log} onChange={ev => this.filter.log = ev.target.value} />
                    </ui.Form.Item>

                    <ui.Form.Item>
                        <ui.Input placeholder="Filter by Session ID" onKeyPress={ev => this.checkFilterSubmit(ev)} value={this.filter.specificId} onChange={ev => this.filter.specificId = ev.target.value} />
                    </ui.Form.Item>

                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="primary" onClick={() => this.refresh()}>Search</ui.Button>
                            <ui.Button type="default" onClick={() => this.clearSearch()}>Clear</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>

                    <ui.Form.Item>
                        <ui.Button danger onClick={() => this.showDeleteSessions = true}>Delete...</ui.Button>
                    </ui.Form.Item>
                </ui.Form>}
                {this.filter.searchArchive == true && <p><small>You are searching the <strong>Archive</strong>. To turn this off uncheck the box at the bottom</small></p>}
                <ui.Table className="hide-pagination-buttons" loading={this.loading} dataSource={this.sessions} rowKey="id" pagination={this.pagination} onChange={(p, f, s) => this.refresh(p.current, s)}>
                    <ui.Table.Column dataIndex="dateCreated" title="Date" render={(text, rec: dtos.SessionInfo) => <span><a onClick={() => this.showSessionLog(rec)}>{moment(rec.dateCreated).format("LLLL")}</a></span>} />
                    <ui.Table.Column title="Endpoint" dataIndex="endpointName" render={(text, rec: dtos.SessionInfo) => <Link to={`/endpoints/${rec.endpointId}`}>{rec.endpointName}</Link>} />
                    <ui.Table.Column title="Channel" dataIndex="channel" />
                    {shared.canViewCustomers() && <ui.Table.Column title="Customer" dataIndex="customerName" render={(text, rec: dtos.SessionInfo) => rec.customerId && <ui.Breadcrumb>
                        {rec.customerBreadcrumb.map((bc, i) => <ui.Breadcrumb.Item key={bc.id}><Link to={`/customers/${bc.id}`}>{bc.name}</Link></ui.Breadcrumb.Item>)}
                    </ui.Breadcrumb>} />}
                    <ui.Table.Column title="From" dataIndex="fromAddress" render={(text, rec: dtos.SessionInfo) => rec.fromAddress + (rec.fromName ? " (" + rec.fromName + ")" : "")} />
                    <ui.Table.Column title="To" dataIndex="toAddress" render={(text, rec:dtos.SessionInfo) => rec.toAddress || rec.destination} />
                    <ui.Table.Column title="Ans" dataIndex="answeredByName" />
                </ui.Table>
                <p><small>The session search is for the past 15 days.</small> <ui.Checkbox checked={this.filter.searchArchive == true} onChange={v => { this.filter.searchArchive = v.target.checked; this.refresh(); }}><small>Search the archive to check older records</small></ui.Checkbox></p>
                <ui.Modal width="75%" title="Session Log / Recordings" onCancel={() => this.showSession = false} visible={this.showSession} footer={[<ui.Button key="ok" type="default" onClick={() => this.showSession = false}>Close</ui.Button>]}>
                    <ui.Tabs activeKey={this.activeSessionLogTab} onChange={t => this.activeSessionLogTab = t}>
                        <ui.Tabs.TabPane key="log" tab="Log">
                            <ui.Table dataSource={this.sessionLog} rowKey={rec => uuid()} pagination={{ pageSize: 8 }}>
                                <ui.Table.Column width={240} dataIndex="dateCreated" title="Date" render={(text, rec: dtos.SessionLogInfo) => <span>{moment(rec.date).format("MMMM Do YYYY, h:mm:ss")}</span>} />
                                <ui.Table.Column title="Message" dataIndex="message" render={(text, rec: dtos.SessionLogInfo) => <div title={this.expandLogEntries.indexOf(rec) < 0 ? "dbl-click to expand" : ""} onDoubleClick={() => this.toggleLogEntry(rec)} style={{ cursor: "pointer", width: 600, overflow: "hidden" }}><pre style={{ fontFamily: "inherit", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: this.expandLogEntries.indexOf(rec) < 0 ? "nowrap" : "pre-wrap" }}>{rec.message}</pre></div>} />
                            </ui.Table>
                        </ui.Tabs.TabPane>
                        <ui.Tabs.TabPane key="files" tab="Recordings">
                            <ui.Table loading={this.loadingSessionFiles} dataSource={this.sessionFiles} rowKey="id">
                                <ui.Table.Column title="File Name" dataIndex="fileName" width={150} sorter={true} render={(text, rec: dtos.FileInfo) =>
                                    <div>
                                        {!rec.contentType.startsWith("audio/") && <a target="_blank" href={rec.uri}>{(rec.fileName || "(none)").substring(0, 75)}</a>}

                                        {rec.contentType.startsWith("audio/") && <><a onClick={() => this.showAudioFile(rec)}>{rec.fileName}</a> <span style={{ marginLeft: 5 }}><small>(Click to listen)</small></span></>}
                                    </div>} />
                                <ui.Table.Column title="Type" dataIndex="type" key="type" sorter={true} />
                                <ui.Table.Column title="Date Created" dataIndex="dateCreated" sorter={true} render={(text, rec: dtos.FileInfo) => moment(rec.dateCreated).format("LLLL") + (rec.recordingFrom ? " from " + rec.recordingFrom : "")} />
                                <ui.Table.Column title="File Size" dataIndex="contentLength" render={(text, rec: dtos.FileInfo) => humanFileSize(rec.contentLength, true)} />
                                <ui.Table.Column title="Content Type" dataIndex="contentType" />
                                <ui.Table.Column title="Transcription" render={(text, rec: dtos.FileInfo) => <span>{rec.contentType.startsWith("audio/") ? (rec.transcription ? "Yes" : "No") : ""}</span>} />
                                <ui.Table.Column title="" render={(text, rec: dtos.FileInfo) =>
                                    <div>
                                    </div>} />

                            </ui.Table>
                        </ui.Tabs.TabPane>
                        <ui.Tabs.TabPane key="children" tab="Children">
                            {this.showSessionInfo && <Sessions {...this.props} noPersistence parentSessionId={this.showSessionInfo.id} />}
                        </ui.Tabs.TabPane>

                        <ui.Tabs.TabPane key="advanced" tab="Advanced">
                            <p>Call State: {this.showSessionInfo && this.showSessionInfo.callState}</p>
                            <p>Queue State: {this.showSessionInfo && this.showSessionInfo.queueState}</p>
                            <ui.Popconfirm title="Flush this call?" onConfirm={() => this.flushSession()}><ui.Button danger>Flush Call</ui.Button></ui.Popconfirm>
                        </ui.Tabs.TabPane>
                    </ui.Tabs>

                    <ui.Modal forceRender title={this.audioFileToShow ? this.audioFileToShow.fileName : "Audio Player"} visible={this.showAudioFileWindow} okButtonProps={{ hidden: true }} cancelText="Close" onCancel={() => { this.showAudioFileWindow = false; this.audioPlayer.current.pause(); }}>
                        {this.loadingAudioFile && <ui.Spin size="large" />}
                        {this.audioFileToShow && this.audioFileToShow.transcription && <pre style={{ whiteSpace: "pre-wrap" }}>
                            Transcription: <br />
                            {this.audioFileToShow.transcription}
                        </pre>}
                        <div style={{ display: this.loadingAudioFile ? "none" : "block" }}>
                            <audio ref={this.audioPlayer} preload="auto" controls />
                        </div>
                        {!this.playbackOptimized && <p><small>Playback could not be optimized for this file. It may require buffering and prevent seeking.</small></p>}
                    </ui.Modal>

                </ui.Modal>


                <ui.Modal title="Delete Sessions" okText="Delete" okButtonProps={{ danger: true }} visible={this.showDeleteSessions} onCancel={() => this.showDeleteSessions = false} onOk={() => this.deleteSessions()}>
                    <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.deleteSessions()}>
                        <ui.Form.Item label="Date Range">
                            <ui.DatePicker.RangePicker showTime={{ format: "HH:mm" }} value={[this.deleteStartDate, this.deleteEndDate]} onChange={(v, dts) => { this.deleteStartDate = moment(dts[0]); this.deleteEndDate = moment(dts[1]); }} />
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>
            </div>
        );
    }
}