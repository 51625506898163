import { React, serviceClient, dtos, observer, observable, ui, Link, _, splitIntoWords, uuid, selectFilter, shared, moment, moveArray, removeArray } from "../common";
import ValueEditor from "../flows/value-editor";

interface Props {
    customerId: string;
    accountId: string;
    params: dtos.FlowParameter[];
    values: dtos.Struct;
}

export default class FlowParamsEditor extends React.Component<Props> {
    
    render() {
        let hasTabs = (params: dtos.FlowParameter[]) => _.find(params, p => p.uiTab != null && p.uiTab.length > 0) != null;

        let flowParamEditor = (params: dtos.FlowParameter[], values: dtos.Struct, possibleTabs = true) => {
            if (hasTabs(params) && possibleTabs) {
                let tabNames = [];
                for (let param of params) {
                    if (param.uiTab != null && param.uiTab.length > 0 && tabNames.indexOf(param.uiTab) < 0) {
                        tabNames.push(param.uiTab);
                    }
                }
                return <ui.Tabs tabPosition="left">
                    <ui.Tabs.TabPane key="other" tab="General">
                        {flowParamEditor(params.filter(p => p.uiTab == null || p.uiTab.length == 0), values, false)}
                    </ui.Tabs.TabPane>
                    {tabNames.map((n, i) => <ui.Tabs.TabPane key={"tab-" + i} tab={n}>
                        {flowParamEditor(params.filter(p => p.uiTab == n), values, false)}
                    </ui.Tabs.TabPane>)}
                </ui.Tabs>
            } else {
                return <div>{params.map(f => <ui.Form.Item key={f.id} label={splitIntoWords(f.name)}>
                    <ValueEditor customerId={shared.isCustomer() ? this.props.customerId : null} accountId={this.props.accountId} value={values[f.name]} field={f} valueType={f.type} />
                </ui.Form.Item>)}</div>;
            }
        }

        return flowParamEditor(this.props.params, this.props.values, true);
    }
}