import { React, dtos, serviceClient, observable, observer, ui, moment, splitIntoWords, makeObservable } from "../common";

interface Props {
    accountId: string;
}

@observer
export default class DashboardCallCenter extends React.Component<Props> {
    @observable accountId: string;
    @observable dashboard: dtos.CallCenterDashboard;
    timer: any;

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    componentDidMount() {
        this.timer = setInterval(() => this.refresh(), 5000);
        this.accountId = this.props.accountId;
        this.refresh();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    async refresh() {
        this.dashboard = await serviceClient.get(new dtos.GetCallCenterDashboard({ accountId: this.accountId }));
    }

    async setAgentState(rec: dtos.CallCenterDashboardAgent, state: dtos.AgentStates) {
        await serviceClient.post(new dtos.SetAgentState({
            endpointId: rec.id,
            state: state
        }));
        rec.agentState = splitIntoWords(state);
        await this.refresh();
    }

    render() {
        if (!this.dashboard) return <div />;

        const getAgentMenu = (rec: dtos.CallCenterDashboardAgent) => {
            return <ui.Menu>
                <ui.Menu.Item disabled>Set {rec.name} State</ui.Menu.Item>
                <ui.Menu.Divider />
                <ui.Menu.Item title="Ready" onClick={() => this.setAgentState(rec, dtos.AgentStates.Ready)}>Ready</ui.Menu.Item>
                <ui.Menu.Item title="Not Ready" onClick={() => this.setAgentState(rec, dtos.AgentStates.NotReady)}>Not Ready</ui.Menu.Item>
                <ui.Menu.Item title="Logged Out" onClick={() => this.setAgentState(rec, dtos.AgentStates.LoggedOut)}>Logged Out</ui.Menu.Item>
            </ui.Menu>;
        }

        const sortAgents = function (agent1: dtos.CallCenterDashboardAgent, agent2: dtos.CallCenterDashboardAgent) {
            var a1Idx = agent1.agentState == "Ready" ? 0 : (agent1.agentState == "On Call" ? 1 : 2);
            var a2Idx = agent2.agentState == "Ready" ? 0 : (agent2.agentState == "On Call" ? 1 : 2);
            return a1Idx - a2Idx;
        }

        return (
            <div>
                <ui.Row gutter={16}>
                    <ui.Col span={12} sm={24} xl={12}>
                        <ui.Collapse defaultActiveKey={["agents"]} style={{ marginBottom: 16 }}>
                            <ui.Collapse.Panel header="Agents" key="agents">
                                <ui.Table dataSource={this.dashboard.agents.filter(a => a.agentState != "Logged Out").sort(sortAgents)} pagination={false} rowKey="id">
                                    <ui.Table.Column dataIndex="name" title="Name" />
                                    <ui.Table.Column dataIndex="queues" title="Queues" render={(text, rec: dtos.CallCenterDashboardAgent) => <span>{rec.queues.join(", ")}</span>} />
                                    <ui.Table.Column dataIndex="signInTime" title="Sign in Time" render={(text, rec: dtos.CallCenterDashboardAgent) => <span>{text ? moment(text).format("LT") : "Logged Out"}</span>} />
                                    <ui.Table.Column dataIndex="signInDuration" title="Sign in Duration" />
                                    <ui.Table.Column dataIndex="agentState" title="Agent State" render={(text, rec: dtos.CallCenterDashboardAgent) =>
                                        <ui.Dropdown trigger={["click"]} overlay={getAgentMenu(rec)}><a>{rec.agentState}</a></ui.Dropdown>} />
                                    <ui.Table.Column dataIndex="callState" title="Call State" />
                                    <ui.Table.Column dataIndex="avgWrapUp" title="Avg. Wrap" />
                                    <ui.Table.Column dataIndex="dateOfLastCall" title="Last Call" render={(text, rec: dtos.CallCenterDashboardAgent) => <span>{moment(rec.dateOfLastCall).format("LTS")}</span>} />
                                </ui.Table>
                            </ui.Collapse.Panel>
                        </ui.Collapse>
                        <ui.Collapse defaultActiveKey={["queues"]} style={{ marginBottom: 16 }}>
                            <ui.Collapse.Panel header="Queues" key="queues">
                                <ui.Table dataSource={this.dashboard.queues} pagination={false} rowKey="name">
                                    <ui.Table.Column dataIndex="name" title="Name" />
                                    <ui.Table.Column dataIndex="numAgents" title="# Agents" />
                                    <ui.Table.Column dataIndex="numCallsQueued" title="# Queued" />
                                    <ui.Table.Column dataIndex="longestWaitingCall" title="Longest Waiting Call" />
                                    <ui.Table.Column dataIndex="expectedWaitTime" title="Exp. Wait" />
                                    <ui.Table.Column dataIndex="expectedHoldTime" title="Exp. Hold" />
                                    <ui.Table.Column dataIndex="averageSpeedOfAnswer" title="Avg. Ans" />
                                    <ui.Table.Column dataIndex="idleAgents" title="# Idle" />
                                    <ui.Table.Column dataIndex="unavailableAgents" title="# Unav." />
                                </ui.Table>
                            </ui.Collapse.Panel>
                        </ui.Collapse>
                    </ui.Col>
                    <ui.Col span={12} sm={24} xl={12}>

                        <ui.Collapse defaultActiveKey={["calls"]} style={{ marginBottom: 16 }}>
                            <ui.Collapse.Panel header="Calls" key="calls">
                                <ui.Table dataSource={this.dashboard.calls} pagination={false} rowKey="id">
                                    <ui.Table.Column dataIndex="customer" title="Customer" />
                                    <ui.Table.Column dataIndex="queue" title="Queue" />
                                    <ui.Table.Column dataIndex="agent" title="Agent" />
                                    <ui.Table.Column dataIndex="state" title="State" />
                                    <ui.Table.Column dataIndex="hold" title="Hold" />
                                    <ui.Table.Column dataIndex="duration" title="Duration" />
                                    <ui.Table.Column dataIndex="extraInfo" title="Info" />
                                </ui.Table>
                            </ui.Collapse.Panel>
                        </ui.Collapse>
                    </ui.Col>
                </ui.Row>

                <br />
                
                <p><small>* This data is updated every 5 seconds. Avg/Expected stats based on past 24 hours of data</small></p>
            </div>
        );
    }
}
