import { React, ui, dtos, Observer, observer, observable, serviceClient, uuid, _, validateParamName, makeObservable } from "../common";
import FlowUtil from "./util";
import FlowCanvas from "./canvas";
import FlowParamEditor from "./param-editor";
import OverflowPanel from "./overflow-panel";
import AutoFocus from "../autofocus";

interface Props {
    parameters: dtos.NodeParameterMap;
    dataType: dtos.DataType;
    flowId: string;
    flowParameters: Array<dtos.FlowParameter>;
    exits: Array<dtos.FlowExit>;
    onFieldsChanged: () => void;
    canvas: FlowCanvas;
    accountId: string;
    customerId: string;
    notes: string;
    nodeType: string;
    onNotesChanged: (notes: string) => void;
    documentationUrl: string;
    restrictToChannels: Array<dtos.FlowChannels>;
}

@observer
export default class FlowProperties extends React.Component<Props> {
    @observable valueSourceFields: dtos.GetParameterFieldNamesResponse = null;
    @observable loaded = false;
    @observable showNewParam = false;
    @observable newParamType = dtos.ValueTypes.String;
    @observable newParamName = "";
    @observable newParamPublic = false;
    @observable newParamOutput = false;
    @observable newParamKnob = false;
    @observable newParamDataType: dtos.DataType = null;

    newParamCallback: (string) => void;
    
    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    async componentDidMount() {
        this.valueSourceFields = await serviceClient.get(new dtos.GetParameterFieldNames({ accountId: this.props.accountId }));
        this.loaded = true;
        this.onNewFlowParam = this.onNewFlowParam.bind(this);
    }

    onNewFlowParam(p: dtos.NodeParameter, callback: (string) => void) {
        this.newParamCallback = callback;
        this.newParamType = p.type;
        if (p.type === dtos.ValueTypes.List) {
            this.newParamDataType = p.listType;
        }
        this.showNewParam = true;
    }

    addNewParam() {
        if (_.find(this.props.flowParameters, f => f.name == this.newParamName)) {
            ui.message.error("A field with this name already exists");
            return;
        }

        if (_.find(this.props.exits, ex => ex.name == this.newParamName)) {
            ui.message.error("There is already a flow exit with this name");
            return;
        }

        if (!validateParamName(this.newParamName)) {
            ui.message.error("This is not a valid field name. Names should not contain any spaces, or :");
            return;
        }

        let field = new dtos.FlowParameter({
            id: uuid(),
            name: this.newParamName,
            type: this.newParamType,
            defaultValue: new dtos.Value(),
            isPublic: this.newParamPublic,
            isOutput: this.newParamOutput,
            isKnob: this.newParamKnob,
            listType: this.newParamDataType
        });

        this.props.flowParameters.push(field);
        this.newParamName = "";
        this.showNewParam = false;
        this.newParamCallback(field.name);
    }

    render() {
        if (!this.loaded) return [];

        let { dataType, customerId, parameters, onFieldsChanged, canvas, flowParameters, accountId, flowId, documentationUrl, restrictToChannels } = this.props;
        return (
            <OverflowPanel>
                <div className="node-props" style={{ position: "relative" }}>

                    <div>
                        <ui.Tag>Node Type: <strong>{this.props.nodeType}</strong></ui.Tag>
                        <ui.Tag>Channels: <strong>{restrictToChannels == null || restrictToChannels.length == 0 ? "Any" : restrictToChannels.join(", ")}</strong></ui.Tag>
                        {documentationUrl && <ui.Tag color="blue" onClick={() => window.open(documentationUrl)}><i className="fa fa-info-square" /> docs</ui.Tag>}
                    </div>
                    <ui.Form layout="vertical">
                        {dataType.fields.filter(f => FlowUtil.shouldShowField(f, parameters, dataType.fields)).map(f => <FlowParamEditor depth={0} rootType={dataType} key={f.name} onNewFlowParam={(p, cb) => this.onNewFlowParam(p, cb)} valueSourceFields={this.valueSourceFields} flowId={flowId} accountId={accountId} customerId={customerId} flowParameters={flowParameters} onFieldsChanged={onFieldsChanged} onTransitionRemoved={id => canvas.removeTransition(id)} param={parameters[f.name]} field={f} />)}
                        <ui.Form.Item label="Notes">
                            <ui.Input.TextArea value={this.props.notes || ""} onChange={ev => this.props.onNotesChanged(ev.target.value)} />
                        </ui.Form.Item>
                    </ui.Form>
                </div>
                <ui.Modal title="New Parameter" okText="Add Parameter" onOk={() => this.addNewParam()} visible={this.showNewParam} onCancel={() => this.showNewParam = false}>
                    <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.addNewParam()}>
                        <ui.Form.Item label="Name">
                            <AutoFocus visible={this.showNewParam}>
                                <ui.Input value={this.newParamName} onChange={ev => this.newParamName = ev.target.value} />
                            </AutoFocus>
                        </ui.Form.Item>
                        <ui.Form.Item label="Type">
                            <ui.Select showSearch value={this.newParamType} disabled>
                                {Object.keys(dtos.ValueTypes).filter(t => t != dtos.ValueTypes.Custom && t != dtos.ValueTypes.NotSpecified && t != dtos.ValueTypes.Transition && t != dtos.ValueTypes.Struct && t != dtos.ValueTypes.List).map(t => <ui.Select.Option key={t} value={t}>{t}</ui.Select.Option>)}
                            </ui.Select>
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Checkbox value={this.newParamPublic} onChange={b => { this.newParamPublic = b.target.checked; this.newParamKnob = false; }}>Public</ui.Checkbox>
                            <ui.Checkbox value={this.newParamKnob} onChange={b => { this.newParamKnob = b.target.checked; this.newParamOutput = false; this.newParamPublic = false }}>Knob</ui.Checkbox>
                            <ui.Checkbox value={this.newParamOutput} onChange={b => { this.newParamOutput = b.target.checked; this.newParamKnob = false; }}>Output</ui.Checkbox>
                        </ui.Form.Item>
                        <ui.Alert type="info" message="Note: you cannot change flow parameter names after creation" />
                    </ui.Form>
                </ui.Modal >
            </OverflowPanel >
        );
    }


}