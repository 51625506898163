import { React, ui, observable, observer, makeObservable } from "./common";
import Recorder from 'recorder-js';

@observer
export default class RecorderWindow extends React.Component {
    static audioContext: any = null;
    @observable isRecordingVoice = false;
    @observable recordingUrl = "";

    recorder: Recorder = null;
    recorderInitialized = false;
    recordingBlob: any;
    recordStream: MediaStream;
    audioPlayer = React.createRef<HTMLAudioElement>();

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    componentDidMount() {

    }

    async toggleRecordVoice() {
        try {
            if (!this.isRecordingVoice) {
                if (!this.recorderInitialized) {
                    if (Recorder.audioContext == null) {
                        Recorder.audioContext = new ((window as any).AudioContext || (window as any).webkitAudioContext)();
                    }
                    this.recorder = new Recorder(Recorder.audioContext);
                    let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                    this.recordStream = stream;
                    this.recorder.init(stream);
                    this.recorderInitialized = true;
                    await this.recorder.start();
                    this.isRecordingVoice = true;
                } else {
                    await this.recorder.start();
                    this.isRecordingVoice = false;
                }
            } else {
                let response = await this.recorder.stop();
                console.log("recording stopped", response);
                this.isRecordingVoice = false;
                this.recordingBlob = response.blob;
                this.recordingUrl = URL.createObjectURL(response.blob);
                this.audioPlayer.current.src = this.recordingUrl;
                this.recorderInitialized = false;
                for (let track of this.recordStream.getTracks()) {
                    track.stop();
                }
                this.recordStream = null;
            }
        } catch (err) {
            console.log("error during toggle record voice", err);
            ui.message.error("Error during microphone access");
        }
    }

    save() {

    }

    render() {
        return (
            <ui.Modal visible={true} title="Evo Voice :: Sound Recorder" footer={<ui.Button download="audio.wav" href={this.recordingUrl} disabled={!this.recordingUrl} type="primary" onClick={() => this.save()}>Save Recording...</ui.Button>}>
                <ul>
                    <li><strong>This utility requires permission to use your microphone</strong></li>
                    <li>Use the button at the bottom left to start and stop recording.</li>
                    <li>You can review your recording using the player controls</li>
                    <li>Once you are happy with the recording you can save it to a file and send it to the appropriate contact</li>
                </ul>
                <audio ref={this.audioPlayer} controls />
                <ui.Button style={{ position: "absolute", bottom: 12, left: 16 }} onClick={() => this.toggleRecordVoice()} danger={this.isRecordingVoice} type={"primary"}><i className="fa fa-microphone" /></ui.Button>
            </ui.Modal>
        );
    }
}